import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, message, Typography, Upload, Image } from "antd"
import { MyInput, MySelect, NolabelInputSm } from "../../Forms"
import TimeForm from "../TimeForm";
import { CloseOutlined, PhoneOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;
const { Dragger } = Upload;

const AddEditEmployeeDrawer = ({visible, onClose, edititem, setAddRole, addrole}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

    const selectBefore = (
        <NolabelInputSm 
            name='code'
            placeholder='+996'
            type='number'
            value={form.getFieldValue("code") || ''}
            className='p-0'
            style={{width: 60}}
        />
      );

    const props = {
        name: 'image',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };

      const handleRole = () => {
        setAddRole(true)
        onClose()
      }
      

      const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

      const pilledit = [
        {
            title:'Hair Cut - Short',
            duration:'30 minutes',
        },
        {
            title:'Hair Dye',
            duration:'60 minutes',
        },
        {
            title:'Waxing',
            duration:'15 minutes',
        },
        {
            title:'Shave',
            duration:'20 minutes',
        },
        {
            title:'Blow Dry',
            duration:'10 minutes',
        },
        {
            title:'Hair Cut - Long',
            duration:'30 minutes',
        },
      ]


  return (
    <Drawer
        title={edititem?'Edit employee':'Add employee'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={800}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    onClick={
                        edititem ? ()=> form.submit() :  handleRole
                    }
                >
                    {
                        edititem?'Update' : 'Next'
                    }
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item name='image' >
                        <div className='width-drag'>
                            <Dragger {...props} className="dragcs">
                                <p className="ant-upload-drag-icon mb-0">
                                    <Image src='/assets/icons/plus.png' width={'41.6px'} preview={false} />
                                </p>
                                <Typography.Text className="fs-14 fw-500">Upload Picture</Typography.Text>
                            </Dragger>
                        </div>
                        {/* {
                            editproperty &&
                            <Space direction='vertical' size={10}>
                                {
                                    geteditdata?.medical_report?.map((data,index)=>
                                        <div className='viewDownload space-between-align' key={index}>
                                            <Typography.Text className='gray-text fs-12'>
                                                {
                                                    data?.name
                                                }
                                            </Typography.Text>
                                            <ActionButton
                                                title='Delete'
                                                icon={<DeleteOutlined className='danger-color' />}
                                                onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                                                type='ghost'
                                            />
                                        </div>
                                    )
                                }
                            </Space>
                            
                        } */}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-500">EMPLOYEE DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Employee Name'
                        name='empName'
                        placeholder='e.g Abdullah'
                        required
                        size={'large'}
                        message='Please enter employee name'
                        value={form.getFieldValue("empName") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Employment Type (optional)'
                        name="empType" 
                        size={'large'}
                        value={form.getFieldValue("empType") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Full time'
                            },
                            {
                                key: 2,
                                name: 'Hourly'
                            },
                        ]}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Contact Number'
                        name='phoneNo'
                        placeholder='e.g +966 12345689'
                        size='large'
                        required
                        message='Please enter contact number'
                        addonAfter={<PhoneOutlined/>}
                        value={form.getFieldValue("phoneNo") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Password'
                        name='password'
                        type='password'
                        size={'large'}
                        required
                        message='Please enter password'
                        value={form.getFieldValue("password") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Years of Experience'
                        name='yearsExp'
                        size={'large'}
                        required
                        message='Please enter years of experience'
                        value={form.getFieldValue("yearsExp") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Nationality'
                        name="nationality" 
                        size={'large'}
                        required
                        message='Please choose nationality'
                        value={form.getFieldValue("nationality") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Saudia Arab'
                            },
                            {
                                key: 2,
                                name: 'Pakistan'
                            },
                        ]}
                    />
                </Col>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-500">SERVICES DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Services'
                        mode={'multiple'}
                        name="service" 
                        size={'large'}
                        required
                        message='Please choose services'
                        value={form.getFieldValue("service") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Hair Cut'
                            },
                            {
                                key: 2,
                                name: 'Hair Dye'
                            },
                            {
                                key: 3,
                                name: 'Shave'
                            },
                            {
                                key: 4,
                                name: 'Blow Dry'
                            },
                        ]}
                    />
                </Col>
                <Col span={24}>
                    {
                    edititem &&
                    <Space size={8} className="w-100 mb-3" wrap>
                        {
                            pilledit?.map((data,index)=>
                                <div className="pill-edit bg-secondary-color" key={index}>
                                    <Text className="fs-13">{data?.title}</Text>
                                    <Text className="pill-sm brand-bg text-white fs-12">{data?.duration}</Text>
                                    <Button className="border0 bg-transparent p-0">
                                        <CloseOutlined />
                                    </Button>
                                </div>
                            )
                        }
                    </Space>   
                    }
                </Col>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-500">WORKING HOURS DETAILS</Title>
                </Col>
                {daysOfWeek.map(day => (
                    <Col span={24} key={day}>
                        <TimeForm {...{form}} dayKey={day} title={day} />
                    </Col>
                ))}
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-500">SALARY DETAILS (optional)</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Pay Period'
                        name="payPeriod" 
                        size={'large'}
                        required
                        message='Please choose pay period'
                        value={form.getFieldValue("payPeriod") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Monthly'
                            },
                            {
                                key: 2,
                                name: 'Hourly'
                            },
                        ]}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Basic Salary'
                        name='basicSalary'
                        size={'large'}
                        required
                        message='Please enter basic salary'
                        value={form.getFieldValue("basicSalary") || ''}
                        addonAfter = {<Text className='text-brand fs-13'>SAR</Text>}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Bank Name'
                        name='bankName'
                        size={'large'}
                        required
                        message='Please enter bank name'
                        value={form.getFieldValue("bankName") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Account Number'
                        name='accountNo'
                        type='number'
                        size={'large'}
                        required
                        message='Please enter account number'
                        value={form.getFieldValue("accountNo") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Account Holder Name'
                        name='accountHolderName'
                        size={'large'}
                        required
                        message='Please enter account holder name'
                        value={form.getFieldValue("accountHolderName") || ''}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditEmployeeDrawer}