import React, { useState } from 'react';
import { Card, Dropdown, Form, Flex, Button, Space, Typography, Col, Row } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { DownOutlined } from '@ant-design/icons';
import { ModuleTopHeading } from '../../PageComponents';
import { MyInput, MySelect, MyselectFix } from '../../Forms';

const { Title } = Typography

const StaffSessionChart = () => {
  const [order, setOrder] = useState(2);
  const [form] = Form.useForm();
  const items = [
    { label: <a href="#">Today</a>, key: 0 },
    { label: <a href="#">Last Week</a>, key: 1 },
    { label: <a href="#">Last Month</a>, key: 2 },
    { label: <a href="#">Quarter</a>, key: 3 },
    { label: <a href="#">Last Year</a>, key: 4 },
  ];

  const onClick = ({ key }) => {
    // key = parseInt(key) + 1;
    setOrder(key);
    // filter(key);
  };

  const clientNames = [
    'Ali', 
    'Aslam', 
    'Abdul Salam', 
    'M. Saad', 
    'Sajid Hussain', 
    'Salim Al Tajir', 
    'Ziyad Abdullah', 
    'Zuhair Hafeez', 
    'M.Darwish', 
    'Naeem Abbas',
    'Fayez Ali'
  ];

const chartData = {
  series: [
    {
      name: 'Staff Sessions',
      data: [3, 25, 12, 8, 10, 14, 9,15, 17, 20, 23 ],
    },
  ],
  options: {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '50%',
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    xaxis: {
      categories: clientNames,
      labels: {
        style: {
          colors: '#000',
        },
      },
    },
    yaxis: {
      min: 0,
      max: 25,
      tickAmount: 5,
      labels: {
        style: {
          colors: '#000',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      show: false,
    },
    colors: ['#6C82F5'],
  },
};


  return (
    <div>
      <Card className='shadow-c border0 radius-12'>
        <Flex justify='space-between' align='center' wrap gap={10}>
            <ModuleTopHeading name='Staff Sessions' />
            <Form form={form} layout="vertical">
                <Flex justify='end' gap={10}>
                    <Dropdown
                        menu={{ items, onClick }}
                        trigger={['click']}
                        className='margin-top'
                    >
                        <Button className='btnsave fs-13 border-brand pad-filter text-brand'>
                        <Space>
                            {
                              order == 0 ? 'Today' : 
                              order == 1 ? 'Last Week' :
                              order == 2 ? 'Last Month' :
                              order == 3 ? 'Last Quarter' :
                              order == 4 ? 'Last Year' : ''
                            }  
                            <DownOutlined />
                        </Space>
                        </Button>
                    </Dropdown>
                </Flex>
            </Form>
        </Flex>
        <Form
            form={form}
            layout="vertical"
            className='pt-3'
        >
              <Row gutter={16} align={'middle'}>
                <Col lg={4} md={12} sm={24} xs={24}>
                  <MyInput
                    name='name'
                    label='Employee Name'
                    placeholder='e.g Riyadh'
                    value={form.getFieldValue("name") || ''} 
                    className='w-100 bg-transparent'
                    onChange={(e)=>e.target.value}
                  />
                </Col>
                <Col lg={3} md={12} sm={24} xs={24}>
                  <Button className='brand-bg margin-5 btnsave text-white'>Search</Button>
                </Col>
            </Row>
        </Form>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={200}
        />
      </Card>
    </div>
  );
};

export { StaffSessionChart };
