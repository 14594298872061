import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography } from "antd"
import { MyInput } from "../../Forms"

const { Title } = Typography;

const AddEditProCategoryDrawer = ({visible, onClose, edititem}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

  return (
    <Drawer
        title={edititem?'Edit product category':'Add product category'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {edititem? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-500">PRODUCT CATEGORY  DETAILS</Title>
                </Col>
                <Col span={24}>
                    <MyInput
                        label='Product Category Name'
                        name='proCategoryName'
                        placeholder=''
                        required
                        size={'large'}
                        message='Please enter product category name'
                        value={form.getFieldValue("proCategoryName") || ''}
                    />
                </Col>
                <Col span={24}>
                    <MyInput
                        textArea
                        label='Product Category  Note (optional)'
                        name='note'
                        placeholder='Add note'
                        value={form.getFieldValue("note") || ''}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditProCategoryDrawer}