import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col, Typography, Table, Button } from "antd"
import { MyInput } from "../../Forms"

const { Title, Text } = Typography;

const ViewSupplierDrawer = ({visible, onClose}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible) {
            
        } else {
            form.resetFields();
        }
    }, [visible])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

    const columns = [
        {
            title: 'Product Category',
            dataIndex: 'productscate',
        },
        {
            title: 'Product Name',
            dataIndex: 'proName',
        },
    ];

    const data = [
        {
            key: '1',
            productscate: 'Hair Care',
            proName: 'Shampoo',
        },
        {
            key: '2',
            productscate: 'Hair Care',
            proName: 'Keratin Cream',
        },
        {
            key: '2',
            productscate: 'Hair Care',
            proName: 'Silky Hair Oil',
        },
        {
            key: '2',
            productscate: 'Hair Care',
            proName: 'Hair Shiner',
        },
        {
            key: '2',
            productscate: 'Hair Care',
            proName: 'Hair Fixer Cream',
        },
      ];


  return (
    <Drawer
        title='View supplier'
        onClose={closeDrawer}
        open={visible}
        width={700}
        footer={false}
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3">SUPPLIER DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Supplier Name'
                        name='supplierName'
                        value={form.getFieldValue("supplierName") || ''}
                        disabled
                    />
                </Col> 
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Contact Number'
                        name='contactnumber'
                        value={form.getFieldValue("contactnumber") || ''}
                        disabled
                    />
                </Col>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3">PRODUCTS</Title>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        className='table-dark pagination'
                        rowHoverable={false}
                        showSorterTooltip={false}
                        pagination={false}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {ViewSupplierDrawer}