import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Typography, Table, Flex, Image, Space } from "antd"
import { MyDatepicker } from "../../Forms"
import { CloseOutlined } from "@ant-design/icons";
import { LeaveViewModal } from "./LeaveViewModal";
import { ActionButton, ModuleTopHeading } from "../../PageComponents";
import { AlertModal } from "../../Modals";
import { AddEditLeaveDrawer } from "./AddEditLeaveDrawer";

const { Title, Text } = Typography;

const LeaveManagementDrawer = ({visible, onClose}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
    const [ leaveview, setLeaveView ] = useState(false)
    const [ leavevisible, setLeaveVisible ] = useState(false)
    const [ edititem, setEditItem ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState(false)
  
    useEffect(() => {
        if (visible) {
            
        } else {
            form.resetFields();
        }
    }, [visible])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

    const columns = [
        {
            title: 'Leave Type',
            dataIndex: 'leaveType',
            sorter: (a,b)=> a.leaveType - b.leaveType
        },
        {
            title: 'Start',
            dataIndex: 'start',
            sorter: (a,b)=> a.start.localeCompare(b.start)
        },
        {
            title: 'End',
            dataIndex: 'end',
            sorter: (a,b)=> a.end.localeCompare(b.end)
        },
        {
            title: <Text>Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 170,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='View'
                        shape="circle"
                        icon={<Image src='/assets/icons/lighteye.png' width={18} preview={false} />}
                        onClick={() => { setLeaveView(true)}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Edit'
                        shape="circle"
                        icon={<Image src='/assets/icons/edit.png' width={18} preview={false} />}
                        onClick={() => { setLeaveVisible(true); setEditItem({name: row?.leaveType})}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={18} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.leaveType}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            leaveType: 'Short Leave',
            start: '11:03 11/02/2024',
            end: '11:03 11/02/2024',
        },
        {
            key: '2',
            leaveType: 'Annual Leave',
            start: '12:03 11/02/2024',
            end: '12:03 11/02/2024',
        },
        {
            key: '3',
            leaveType: 'Short Leave',
            start: '12:03 11/02/2024',
            end: '12:03 11/02/2024',
        },
        {
            key: '4',
            leaveType: 'Annual Leave',
            start: '12:03 11/02/2024',
            end: '12:03 11/02/2024',
        },
      ];

    
  return (
    <>
        <Drawer
            title={null}
            onClose={closeDrawer}
            open={visible}
            width={1000}
            footer={false}
            closeIcon={false}
        >
            <Row gutter={[24,24]}>
                <Col span={24}>
                    <Flex justify="space-between" align="center">
                        <ModuleTopHeading name='Leave Management' onClick={()=>setLeaveVisible(true)} />
                        <Button onClick={onClose} className="border0 bg-transparent p-0">
                            <CloseOutlined />
                        </Button>
                    </Flex>
                </Col>
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Row gutter={16} align={'middle'}>
                            <Col lg={5} md={12} sm={24} xs={24}>
                                <MyDatepicker
                                    rangePicker
                                    label="Leave Date"
                                    name='leaveDate'
                                    value={form.getFieldValue('leaveDate') || ''}
                                    className='border-black'
                                />
                            </Col>
                            <Col lg={3} md={12} sm={24} xs={24}>
                                <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 700}}
                        className='table-dark pagination'
                        rowHoverable={false}
                        showSorterTooltip={false}
                        pagination={{
                            hideOnSinglePage: true,
                            position: ['bottomCenter'],
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
        </Drawer>
        <LeaveViewModal 
            visible={leaveview}
            onClose={()=>setLeaveView(false)}
        />
        <AddEditLeaveDrawer 
            visible={leavevisible}
            edititem={edititem}
            onClose={()=>{setLeaveVisible(false);setEditItem(null)}}
        />
        <AlertModal 
            visible={deleteitem}
            deleteitem={deleteitem}
            onClose={()=>setDeleteItem(false)}
        />
    </>
  )
}

export {LeaveManagementDrawer}