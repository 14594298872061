import React, { useState } from "react"
import { Badge, Button, Image } from "antd"
import NotificationsDrawer from "./NotificationsDrawer"

export const Notifications = () => {
    
    const [visible, setVisible]= useState(false)
    return (
        <>
            <Badge count={9} overflowCount={9} color="#F56C89">
                <Button className='p-0 border0 bg-transparent' onClick={()=> setVisible(true)}>
                    <Image 
                        src='/assets/icons/notify.png' 
                        width={'24px'} 
                        preview={false}
                        alt="StoreApp" 
                        className="up"
                    />
                </Button>
            </Badge>
            <NotificationsDrawer
                visible={visible}
                onClose={()=> setVisible(false)}
            />
        </>
    )
}