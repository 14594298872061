import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col , Typography, Image, Flex, Table, Button} from "antd"
import { StockTable } from "./StockTable";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { IncrementModal } from "../IncrementModal";
import { DecrementModal } from "../DecrementModal";
import { ActionButton } from "../../PageComponents";
import { RecordedQuantityModal } from "../RecordedQuantityModal";

const { Title, Text } = Typography;
const ViewProductDrawer = ({visible, onClose, viewonly}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
    const [ incrementmodal, setIncrementModal ] = useState(false)
    const [ decrementmodal, setDecrementModal ] = useState(false)
    const [ recordedstock, setRecordedStock] = useState(false)

    
    useEffect(() => {
        if (visible && viewonly) {
            
        } else {
            form.resetFields();
        }
    }, [visible, viewonly])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Details',
            dataIndex: 'detail',
        },
    ];

    const data = [
        {
            key: '1',
            name: <Text>Barcode</Text>,
            detail: <Text>B000067</Text>,
        },
        {
            key: '2',
            name: <Text>Category</Text>,
            detail: <Text>Hair</Text>,
        },
        {
            key: '3',
            name: <Text>Total Quantity</Text>,
            detail: <Text>100</Text>,
        },
        {
            key: '4',
            name: <Text>Brand</Text>,
            detail: <Text>Kenue</Text>,
        },

        {
            key: '5',
            name: <Text>Supplier</Text>,
            detail: <Text>Kenue</Text>,
        },
        {
            key: '6',
            name: <Text>Supplier Contact Number</Text>,
            detail: <Text>+966 57864334</Text>,
        },
        {
            key: '7',
            name: <Text>Product Description</Text>,
            detail: <Text>-</Text>,
        },
      ];


  return (
    <Drawer
        title='View product'
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={false}
    >
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Text strong>PRODUCT DETAILS</Text>
            </Col>
            <Col span={24}>
                <Flex justify="center" gap={20} vertical align="center">
                  <div className="border-img">
                    <Image src='/assets/images/pro-1.png' preview={false} width={120} height={120} style={{objectFit:'fill'}} />
                  </div>
                  <Title level={5} className="m-0">{viewonly?.name}</Title>
                  <Button className="btnsave bg-secondary-color text-brand fw-500 fs-13">{viewonly?.quantity} instock</Button>
                  <Flex gap={10} className="">
                    <ActionButton
                        title='Add stock'
                        icon={<Image src='/assets/icons/plus-cr-b.png' width={40} preview={false} />}
                        onClick={() => setIncrementModal(true)}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Remove stock'
                        icon={<Image src='/assets/icons/minus-cr-b.png' width={40} preview={false} />}
                        onClick={() => setDecrementModal(true)}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Recorded stock quantity'
                        icon={<Image src='/assets/icons/recorded-cr-b.png' width={40} preview={false} />}
                        onClick={() => setRecordedStock(true)}
                        className='border0 bg-transparent'
                    />
                  </Flex>
                </Flex>
            </Col>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    className='table-dark pagination'
                    rowHoverable={false}
                    pagination={false}
                />
            </Col>
            <Col span={24}>
                <Flex vertical gap={10}>
                    <Text strong>STOCK DETAILS</Text>
                    <StockTable />
                </Flex>
            </Col>
        </Row>
        <IncrementModal 
            visible={incrementmodal}
            viewonly={viewonly}
            onClose={()=> setIncrementModal(false)}
        />
        <DecrementModal 
            visible={decrementmodal}
            viewonly={viewonly}
            onClose={()=> setDecrementModal(false)}
        />
        <RecordedQuantityModal 
            visible={recordedstock}
            onClose={()=>setRecordedStock(false)}
        />
    </Drawer>
  )
}

export {ViewProductDrawer}