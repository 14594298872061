import { PlusOutlined } from "@ant-design/icons"
import {Row, Col, Space, Button, Typography, Image} from "antd"
export const ModuleTopHeading=({name, onClick})=>{
    return (
        <Row>
            <Col span={24}>
                <Space  style={{alignItems:'center'}}>
                    <Typography.Title level={4} className="my-0 mbl-fs">
                        {
                            name
                        }
                    </Typography.Title>
                    {
                        onClick ?
                        <Button 
                            className="my-0"
                            type="primary" 
                            shape="circle" 
                            size='small' 
                            classNames='brand-bg text-white'
                            icon={<PlusOutlined/>}
                            onClick={onClick}
                        />
                        :<></>
                    }
                </Space>
            </Col>
        </Row>
    )
}