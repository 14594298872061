import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Flex, Switch, Divider, Pagination, Button } from 'antd'
import { ActionButton } from '../../PageComponents';

const { useToken } = theme;
const { Text } = Typography
const GridViewServices = ({setEditItem, setDeleteItem, setVisible, setSwitchText}) => {
    const { token } = useToken();

    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        setDeleteItem(true)
        setSwitchText(true)
    };

    const onShowSizeChange = (current, pageSize) => {
        console.log(current, pageSize);
      };
    

    const data = [
        {
            title:'Hair/Beard Colouring',
            per:'Hair Care',
            key: 'active'
        },
        {
            title:'Scalp Treatments',
            per:'Hair Care',
            key: 'active'
        },
        {
            title:'Cut and Hair Care',
            per:'Hair Care',
        },
        {
            title:'Rebonding',
            per:'Hair Care',
            key:'active'
        },
        {
            title:'Clean Ups',
            per:'Hair Care',
            key:'active'
        },
        {
            title:'Manicure',
            per:'Hair Care',
            key:'active'
        },
        {
            title:'Advanced Moisturising',
            per:'Hair Care',
        },
        {
            title:'Perming',
            per:'Hair Care',
            key:'active'
        },


        {
            title:'Beard Styling',
            per:'Hair Care',
            key:'active'
        },
        {
            title:'Smoothening',
            per:'Hair Care',
        },
        {
            title:'Shave',
            per:'Hair Care',
            key:'active'
        },
        {
            title:'Colour Protection',
            per:'Hair Care',
            key:'active'
        },
    ]
  return (
    <div>
        <Row gutter={[16,16]}>
            {
                data?.map((cdata,c)=>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6} key={c}>
                        <Card style={{
                            borderRadius: token.borderCardrad,
                            width:"100%"}}
                            className='card-border'
                            actions={[
                                <Space gap={10}>
                                    <Switch size='small' defaultChecked onChange={onChange} />
                                    <ActionButton
                                        title='Edit item'
                                        shape="circle"
                                        icon={<Image src='/assets/icons/edit.png' width={20} preview={false} />}
                                        onClick={() => {setVisible(true); setEditItem({name: cdata?.title})}}
                                        className='border0 bg-transparent'
                                    />
                                </Space>
                            ]}
                        >
                        <Flex vertical gap={5} align='center'>
                            <Flex align='center' gap={5} className='pt-3'>
                                <img src={
                                    cdata?.key === 'active' ? '/assets/icons/online.png' : '/assets/icons/offline.png'
                                } 
                                width={10} alt="" 
                                />
                                <Text strong>{cdata?.title}</Text>
                            </Flex>
                            <Text className='text-gray fs-13'>{cdata?.per}</Text>
                        </Flex>
                    </Card>  
                </Col>
                )
            }
            <Col span={24}>
                <Pagination
                    // showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    defaultCurrent={3}
                    align='center'
                    className='my-3'
                    position={'bottomCenter'}
                    total= {12}
                    // pageSize: pagination?.pageSize,
                    // defaultPageSize: pagination?.pageSize,
                    // current: pagination?.pageNo,
                    // size: "default",
                    // pageSizeOptions: ['10', '20', '50', '100'],
                    // onChange= {(pageNo, pageSize) => call(pageNo, pageSize)},
                    showTotal= {(total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>}
                />
            </Col>
        </Row>
    </div>
  )
}

export {GridViewServices}