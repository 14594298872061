import React from 'react'
import { Button, Col, Form, Row, Table } from 'antd'
import { ModuleTopHeading, MyDatepicker, MyInput, MySelect } from '../../../components';
import { filterrevenue } from '../../../shared/lookups';


const TableRevenueServices = () => {
    const [form] = Form.useForm();

    const columns = [
        {
            title: 'Service Name',
            dataIndex: 'name',
            sorter: (a,b) => a.name.localeCompare(b.name),
            width: 200
        },
        {
            title: 'Booked',
            dataIndex: 'booked',
            sorter: (a,b) => a.booked.localeCompare(b.booked),
            width: 200
        },
        {
            title: 'Revenue Amount',
            dataIndex: 'amount',
            sorter: (a,b) => a.amount.localeCompare(b.amount),
            width: 200
        },
    ];

    const data = [
        {
            key: '1',
            name:'Hair Dye',
            booked: '1200',
            amount: '100000',
        },
        {
            key: '2',
            name:'Blow Dry',
            booked: '500',
            amount: '57000',
        },
        {
            key: '3',
            name:'Hair Cut - Short',
            booked: '1000',
            amount: '23000',
        },
        {
            key: '4',
            name:'Hair Cut - Long',
            booked: '200',
            amount: '22000',
        },
      ];

    return (
        <div>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <ModuleTopHeading name='Revenue By Services'/>                        
                </Col>
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Row gutter={16} align={'middle'}>
                            <Col lg={4} md={12} sm={24} xs={24}>
                                <MyInput
                                    name='name'
                                    label='Service Name'
                                    value={form.getFieldValue("name") || ''}
                                    className='w-100 bg-transparent'
                                />
                            </Col>
                            <Col lg={5} md={12} sm={24} xs={24}>
                                <MySelect
                                    label= 'Filter'
                                    name="filter"
                                    value={form.getFieldValue("filter") || ''}
                                    options={filterrevenue}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={12}>
                                <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 1000}}
                        className='table-dark pagination'
                        rowHoverable={false}
                        showSorterTooltip={false}
                        pagination={{
                            hideOnSinglePage: true,
                            position: ['bottomCenter'],
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
        </div>
    )
}

export { TableRevenueServices }
