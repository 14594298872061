import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Flex, Switch, Pagination, Button } from 'antd'
import { ActionButton } from '../../PageComponents';

const { useToken } = theme;
const { Text } = Typography

const GridViewProduct = ({setEditItem, setDeleteItem, setVisible,setViewOnly, setHistoryDrawer}) => {
    const { token } = useToken();

    const onShowSizeChange = (current, pageSize) => {
        console.log(current, pageSize);
      };

    const data = [
        {
            img:'pro-1a.png',
            title:'Face Shining Serum',
            type:'Skin Care',
        },
        {
            img:'pro-2.png',
            title:'Vit C Serum',
            type:'Skin Care',
        },
        {
            img:'pro-3.png',
            title:'Gold Serum',
            type:'Skin Care',
        },
        {
            img:'pro-4.png',
            title:'Nail Polish Remover',
            type:'Skin Care',
        },
        {
            img:'pro-5.png',
            title:'Whitening Cream',
            type:'Skin Care',
        },
        {
            img:'pro-6.png',
            title:'Hair Serum',
            type:'Skin Care',
        },
        {
            img:'pro-7.png',
            title:'Gold Skin Cream',
            type:'Skin Care',
        },
        {
            img:'pro-6.png',
            title:'Vit A Serum',
            type:'Skin Care',
        },
    ]
  return (
    <div>
        <Row gutter={[16,16]}>
            {
                data?.map((cdata,c)=>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6} key={c}>
                        <Card style={{
                            borderRadius: token.borderCardrad,
                            width:"100%"}}
                            className='card-border'
                            actions={[
                                <Space gap={10}>
                                    <ActionButton
                                        title='View'
                                        shape="circle"
                                        icon={<Image src='/assets/icons/lighteye.png' width={18} preview={false} />}
                                        onClick={() => { setViewOnly(
                                            {name: cdata?.title})
                                        }}
                                        className='border0 bg-transparent'
                                    />
                                    <ActionButton
                                        title='Edit item'
                                        shape="circle"
                                        icon={<Image src='/assets/icons/edit.png' width={18} preview={false} />}
                                        onClick={() => {setVisible(true); setEditItem({name: cdata?.title})}}
                                        className='border0 bg-transparent'
                                    />
                                    <ActionButton
                                        title='Stock history'
                                        shape="circle"
                                        icon={<Image src='/assets/icons/history.png' width={16} preview={false} />}
                                        onClick={() => { setHistoryDrawer(true)}}
                                        className='border0 bg-transparent'
                                    />
                                    <ActionButton
                                        title='Delete item'
                                        icon={<Image src='/assets/icons/delete.png' width={20} preview={false} />}
                                        onClick={() => { setDeleteItem({name:cdata?.title}) }}
                                        className='border0 bg-transparent'
                                    />
                                </Space>
                            ]}
                        >
                        <Flex vertical gap={5} align='center'>
                            <img src={'/assets/images/'+cdata?.img} width={150} alt="" />
                            <Text strong>{cdata?.title}</Text>
                            <Text className='text-gray fs-13'>{cdata?.type}</Text>
                        </Flex>
                    </Card>  
                </Col>
                )
            }
            <Col span={24}>
                <Pagination
                    // showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    defaultCurrent={3}
                    align='center'
                    className='my-3'
                    position={'bottomCenter'}
                    total= {12}
                    // pageSize: pagination?.pageSize,
                    // defaultPageSize: pagination?.pageSize,
                    // current: pagination?.pageNo,
                    // size: "default",
                    // pageSizeOptions: ['10', '20', '50', '100'],
                    // onChange= {(pageNo, pageSize) => call(pageNo, pageSize)},
                    showTotal= {(total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>}
                />
            </Col>
        </Row>
    </div>
  )
}

export {GridViewProduct}