import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Typography, Flex, Card, Image } from "antd"
import { MyInput, MySelect } from "../../Forms"
import { ActionButton } from "../../PageComponents";
import { ViewDetailReservation } from "./ViewDetailReservation";
import { PhoneOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const ViewReservation = ({visible, onClose, edititem, setService, setEditItem, setProductState, setEditProduct, setReceipt }) => {
  
    const [form] = Form.useForm() 
    const [ textchange, setTextChange ] = useState(false)
    const [ lastview, setLastView ] = useState(false)
    const [ paidid, setPaidId ] = useState(false)

  
    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            
        }
    }, [visible, edititem])


    
  return (
    <Drawer
        title={
            <Flex justify="space-between">
                <Text strong>Reservation # 1254</Text>
                <Text className="text-gray fs-13">Wednesday, September 25, 2024</Text>
            </Flex>
        }
        onClose={onClose}
        className="drawer"
        open={visible}
        width={600}
        footer={
            <>  
                <Flex className="mb-3" justify="space-between">
                    <Text strong>TOTAL</Text>
                    <Text strong>SAR 2700 (1 hr)</Text>
                </Flex>
                <Flex className="w-100" justify="space-between" gap={10}>
                    <Button 
                        onClick={onClose}
                        className="btncancel py-2"
                        block
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        className='btnsave py-2'
                        type="primary"
                        onClick={
                            !paidid ? lastview === true ? ()=> form.submit() : ()=>setLastView(true) :
                            paidid ? ()=>setReceipt(true) : ''
                        }
                    >
                        {
                            paidid ? 'Paid' : lastview ? 'Save Unpaid' :
                            textchange ? 'Save' : 'Checkout'
                        }
                    </Button>
                </Flex>
            </>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-500">RESERVATION DETAILS</Title>
                </Col>
                {
                    lastview === false ?
                        <>
                            <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                <MyInput
                                    label='Contact Number'
                                    name='contactNo'
                                    placeholder=''                        
                                    size={'large'}
                                    required
                                    message='Please enter contact number'
                                    value={form.getFieldValue("contactNo") || ''}
                                    addonAfter={<PhoneOutlined/>}
                                />
                            </Col>
                            <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                <MyInput
                                    label='Customer Name'
                                    name='customerName'
                                    placeholder=''                        
                                    size={'large'}
                                    required
                                    message='Please enter customer name'
                                    value={form.getFieldValue("customerName") || ''}
                                />
                            </Col>
                            <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                <MySelect
                                    label= 'Status'
                                    name="status" 
                                    size={'large'}
                                    required
                                    message='Please enter status'
                                    value={form.getFieldValue("status") || ''}
                                    onChange={()=>setTextChange(!textchange)}
                                    options={[
                                        {
                                            key: 1,
                                            name: 'Completed'
                                        },
                                        {
                                            key: 2,
                                            name: 'Cancel'
                                        },
                                    ]}
                                />
                            </Col> 
                            <Col span={24}>
                                <Flex vertical gap={10} className="mb-3">
                                    <Title level={5} className="m-0 fw-500">Note</Title>
                                    <Text>
                                        lorem ipsum
                                    </Text>
                                </Flex>
                            </Col>
                            <Col span={24}>
                                <Card className='card-cs radius-12 mb-3 card-border'>
                                    <Flex justify="space-between" align="center">
                                        <Text strong>ADD SERVICES</Text>
                                        <ActionButton 
                                            shape="circle"
                                            icon={<Image src='/assets/icons/add.png' width={22} preview={false} />}
                                            onClick={() => {setService(true);onClose()}}
                                            className='border0 bg-transparent'
                                        />
                                    </Flex>
                                </Card>
                                <Card className='card-cs radius-12 mb-3 card-border'>
                                    <Flex vertical>
                                        <Flex justify="space-between" align="center">
                                            <Text className="fw-500 fs-14">Long Hair Cut</Text>
                                            <Button 
                                                className="bg-transparent border0 p-0 text-brand fs-14" 
                                                onClick={() => {setService(true);setEditItem(1);onClose()}}>
                                                    Edit
                                            </Button>
                                        </Flex>
                                        <Text className="fs-14 text-gray">11:00am - 11:30am (30 min)</Text>
                                        <Text className="fs-14 text-gray">SAR 700</Text>
                                        <Text className="fs-14 text-gray">With M.Daud</Text>
                                    </Flex>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card className='card-cs radius-12 mb-3 card-border'>
                                    <Flex justify="space-between" align="center">
                                        <Text strong>ADD PRODUCTS</Text>
                                        <ActionButton 
                                            shape="circle"
                                            icon={<Image src='/assets/icons/add.png' width={22} preview={false} />}
                                            onClick={() => {setProductState(true);onClose()}}
                                            className='border0 bg-transparent'
                                        />
                                    </Flex>
                                </Card>
                                <Card className='card-cs radius-12 mb-3 card-border'>
                                    <Flex vertical>
                                        <Flex justify="space-between" align="center">
                                            <Text className="fw-500 fs-14">Long Hair Cut</Text>
                                            <Button 
                                                className="bg-transparent border0 p-0 text-brand fs-14" 
                                                onClick={() => {setProductState(true);setEditProduct(1);onClose()}}>
                                                    Edit
                                            </Button>
                                        </Flex>
                                        <Text className="fs-14 text-gray">11:00am - 11:30am (30 min)</Text>
                                        <Text className="fs-14 text-gray">SAR 700</Text>
                                        <Text className="fs-14 text-gray">With M.Daud</Text>
                                    </Flex>
                                </Card>
                            </Col>
                        </>
                    :
                    <Col span={24}>
                        <ViewDetailReservation setPaidId={setPaidId} />
                    </Col>
                }
            </Row>
        </Form>
    </Drawer>
  )
}

export {ViewReservation}