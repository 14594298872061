import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Upload, Typography, Image, message, Flex, Select} from "antd"
import { MyDatepicker, MyInput, MySelect } from "../../Forms"
import { DeleteOutlined } from "@ant-design/icons";
import { ActionButton } from "../../PageComponents";

const { Title, Text } = Typography;
const { Dragger } = Upload;

const AddEditPayrollDrawer = ({visible, onClose, edititem,}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
    const [ hourlyfield, setHourlyField ] = useState(false)
    const [inputValue, setInputValue] = useState('');
    const [ changeText, setChangeText ] = useState({
        allowance: false,
        bonus: false,
        overtime: false,
        taxes: false,
    })
    const handleToggle = (field) => {
        setChangeText((prevState) => ({
          ...prevState,
          [field]: !prevState[field],
        }));
    };

    const getSarText = (field) => (
        <Text className="cursor text-brand" onClick={() => handleToggle(field)}>
          {changeText[field] ? 'Per' : 'Fixed'}
        </Text>
    );

    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const closeDrawer= ()=>{
    
        form.resetFields()
        onClose()
    }

    const  optionss = [
        {
            key:1,
            label:'Vit C Serum',
            emoji:'av-1.png',
        },
        {
            key:2,
            label:'Facial Pack',
            emoji:'av-2.jpg',
        },
        {
            key:3,
            label:'Charcoal Face Mask',
            emoji:'av-3.jpg',
        },
    ]

    const handleChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        setHourlyField(value.toLowerCase() === 'hourly')
    }

  return (
    <Drawer
        title={edititem?'Edit payroll':'Add payroll'}
        onClose={closeDrawer}
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {edititem? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3">PAYROLL DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <Form.Item name='employeeName' label={<Text className="fs-13 fw-500">Employee Name</Text>}
                        rules={[
                            {
                                required: true,
                                message:'Please enter employee name'
                            }
                        ]}
                    >
                        <Select
                            className="w-100 select"
                            placeholder=""
                            options={optionss.map(opt => ({
                                label: (
                                    <Flex className="w-100" align="center" gap={10}>
                                        <img 
                                            src={"/assets/images/" + opt.emoji} 
                                            width={35} 
                                            height={35} 
                                            style={{ borderRadius: 50, flexShrink: 0 }} 
                                        />
                                        <Text strong>{opt.label}</Text>
                                    </Flex>
                                ),
                                value: opt.label,
                            }))}
                            size="large"
                            labelInValue={true}
                            optionLabelProp="value"
                        />
                    </Form.Item>   
                </Col> 
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Role'
                        name='role'
                        required
                        message='Please enter role'
                        value={form.getFieldValue("role") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Pay Period'
                        name='payPeriod'
                        required
                        message='Please enter pay period'
                        value={inputValue}
                        onChange={handleChange}
                    />
                </Col>
                {
                    hourlyfield &&
                    <>
                        <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                            <MyInput
                                label='Hour Price'
                                name='hourPrice'
                                required
                                message='Please enter hour price'
                                value={form.getFieldValue("hourPrice") || ''}
                            />
                        </Col>
                        <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                            <MyInput
                                label='Number of hours'
                                name='numHours'
                                required
                                message='Please enter number of hours'
                                value={form.getFieldValue("numHours") || ''}
                            />
                        </Col>
                    </>
                }
                {
                    !hourlyfield &&
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput
                            label='Basic Salary'
                            name='basicSalary'
                            required
                            message='Please enter basic salary'
                            value={form.getFieldValue("basicSalary") || ''}
                        />
                    </Col>
                }
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Deduction (optional)'
                        name='allowance'
                        size='large'
                        addonAfter = {getSarText('allowance')} 
                        value={form.getFieldValue("basicSalary") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Bonus (optional)'
                        name='bonus'
                        size='large'
                        addonAfter={getSarText('bonus')} 
                        value={form.getFieldValue("bonus") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Overtime pay (optional)'
                        name='overtime'
                        size='large'
                        addonAfter={getSarText('overtime')} 
                        value={form.getFieldValue("overtime") || ''}
                    />
                </Col>
                {
                    !hourlyfield &&
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput
                            label='Social Security'
                            name='socialSecurity'
                            size='large'
                            addonAfter={getSarText('socialSecurity')} 
                            required
                            message='Please enter social security'
                            value={form.getFieldValue("socialSecurity") || ''}
                        />
                    </Col>
                }
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Net Salary'
                        name='netSalary'
                        required
                        message='Please enter net salary'
                        value={form.getFieldValue("netSalary") || ''}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditPayrollDrawer}