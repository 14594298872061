import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Row } from 'antd'
import { ModuleTopHeading, MyDatepicker, MyInput, MySelect, TableViewCoupon, GrideViewCoupon } from '../../components';


const Coupon = () => {
    const [ current, setCurrent ] = useState(true)
    const [form] = Form.useForm();

    return (
        <div>
            <Card className='shadow-c radius-12 border0 cardlg'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center' gap={10} wrap>
                            <ModuleTopHeading name='Coupon' />
                            <Flex gap={'small'}>
                                <Button
                                    onClick={() => setCurrent(!current)}
                                    className='bg-transparent border0 p-0'
                                >
                                    {
                                        current ?
                                        <img src="/assets/icons/list-ic.png" alt="" width={60} />
                                        :
                                        <img src="/assets/icons/grid-ic.png" alt="" width={60} />
                                    }
                                </Button>
                            </Flex>
                        </Flex>                        
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Row gutter={16} align={'middle'}>
                                <Col lg={4} md={12} sm={24} xs={24}>
                                    <MyInput
                                        name='name'
                                        label='Coupon Code'
                                        placeholder='e.g BOGO50'
                                        value={form.getFieldValue("name") || ''}
                                        className='w-100 bg-transparent'
                                    />
                                </Col>
                                <Col lg={4} md={12} sm={24} xs={24}>
                                    <MySelect
                                        label= 'Coupon Type'
                                        name="couponType"
                                        value={form.getFieldValue("couponType") || ''}
                                        options={[
                                            {
                                                key: 1,
                                                name: 'Percentage',
                                            },
                                            {
                                                key: 2,
                                                name: 'Fixed',
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col lg={4} md={12} sm={24} xs={24}>
                                    <MySelect
                                        label= 'Status'
                                        name="status"
                                        value={form.getFieldValue("status") || ''}
                                        options={[
                                            {
                                                key: 1,
                                                name: 'Active',
                                            },
                                            {
                                                key: 2,
                                                name: 'Inactive',
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MyDatepicker
                                        rangePicker
                                        label="Coupon Date"
                                        name='regDate'
                                        value={form.getFieldValue('regDate') || ''}
                                        className='border-black'
                                    />
                                </Col>
                                <Col lg={3} md={12} sm={24} xs={24}>
                                    <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        {
                            current ?
                            <TableViewCoupon/>
                            :
                            <GrideViewCoupon/>
                        }
                    </Col>
                    
                </Row>
            </Card>
        </div>
    )
}

export { Coupon }
