import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Image, Row, Space } from 'antd'
import { 
    ModuleTopHeading, 
    MyDatepicker, 
    MyInput, 
    MySelect, 
    CalendarParent, 
    AddReservation,
    ExportPdfReservation, 
} from '../../components';
import { AddService } from '../../components/ReservationComponents/ReservationDrawers/AddService';
import { AddProduct } from '../../components/ReservationComponents/ReservationDrawers/AddProduct';


const ReservationPage = () => {
    const [form] = Form.useForm();
    const [ visible, setVisible ] = useState(false)
    const [ service, setService ] = useState(false)
    const [ edititem, setEditItem ] = useState(null)
    const [ productstate, setProductState ] = useState(false)
    const [ editproduct, setEditProduct ] = useState(null)
    const [ exportdrawer, setExportDrawer ] = useState(false)
    const [ changeinput, setChangeInput ] = useState('Customer Name')

    const handleChangeFilter = (e) => {
        setChangeInput(e === 'Customer Name' ? true : false)
    }

    return (
        <Flex vertical gap={20}>
            <Card className='shadow-c radius-12 border0 cardlg'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <ModuleTopHeading name='Reservations' />                        
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Row gutter={16} align={'middle'}>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MySelect
                                        label= 'Filter'
                                        name="filter"
                                        value={form.getFieldValue("filter") || ''}
                                        onChange={handleChangeFilter}
                                        options={[
                                            {
                                                key: 1,
                                                name: 'Customer Name',
                                            },
                                            {
                                                key: 2,
                                                name: 'Reservation #',
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    {
                                        changeinput ? (
                                            <MyInput
                                                name='customerName'
                                                label='Customer Name'
                                                value={form.getFieldValue("customerName") || ''}
                                                className='w-100 bg-transparent'
                                            />
                                        ) : (
                                            <MyInput
                                                name='reservation'
                                                label='Reservation #'
                                                value={form.getFieldValue("reservation") || ''}
                                                className='w-100 bg-transparent'
                                            />
                                        ) 
                                    }
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MyDatepicker
                                        rangePicker
                                        label="Registration Date"
                                        name='regDate'
                                        value={form.getFieldValue('regDate') || ''}
                                        className='border-black'
                                    />
                                </Col>
                                <Col lg={8} md={12} sm={24} xs={12}>
                                    <Space className='w-100'>
                                        <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                                        <Button className='btnsave pad-filter bg-secondary-color text-brand border0 margin-5'
                                            onClick={()=> setExportDrawer(true)}
                                        >
                                            <Image src='/assets/icons/file.png' preview={false} width={22} />
                                            Export
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Card>
            <CalendarParent {...{visible,setVisible,setService,setEditItem,setProductState,setEditProduct}} />
            <AddReservation 
                visible={visible}
                setService={setService}
                setEditItem={setEditItem}
                setProductState={setProductState}
                setEditProduct={setEditProduct}
                onClose={()=>setVisible(false)}
            />
            <AddService 
                visible={service}
                edititem={edititem}
                setEditItem={setEditItem}
                setVisible={setVisible}
                onClose={()=>{setService(false);setEditItem(null)}}
            />
            <AddProduct 
                visible={productstate}
                editproduct={editproduct}
                setEditProduct={setEditProduct}
                setVisible={setVisible}
                onClose={()=>{setProductState(false);setEditProduct(null)}}
            />
            <ExportPdfReservation 
                visible={exportdrawer}
                onClose={()=>setExportDrawer(false)}
            />
            
        </Flex>
    )
}

export { ReservationPage }
