import React from 'react'
import { Card, Col, Row, Typography, theme, Flex, Pagination, Button } from 'antd'

const { useToken } = theme;
const { Text } = Typography
const GrideViewCoupon = () => {
    const { token } = useToken();

    const onShowSizeChange = (current, pageSize) => {
        console.log(current, pageSize);
      };
    

    const data = [
        {
            title:'TAKE15OFF (10)',
            key: 'active',
            contacts:[
                {
                    icons:'coupon-per.png',
                    title:'10%'
                },
                {
                    icons:'c-calendar.png',
                    title:'11/02/2024 - 15/10/2024'
                },
            ]
        },
        {
            title:'BUY1GET1 (10)',
            key: 'active',
            contacts:[
                {
                    icons:'coupon-per.png',
                    title:'SAR 200'
                },
                {
                    icons:'c-calendar.png',
                    title:'11/02/2024 - 15/10/2024'
                },
            ]
        },
        {
            title:'TAKE15OFF (10)',
            contacts:[
                {
                    icons:'coupon-per.png',
                    title:'10%'
                },
                {
                    icons:'c-calendar.png',
                    title:'11/02/2024 - 15/10/2024'
                },
            ]
        },
        {
            title:'TAKE15OFF (10)',
            key:'active',
            contacts:[
                {
                    icons:'coupon-per.png',
                    title:'SAR 90'
                },
                {
                    icons:'c-calendar.png',
                    title:'11/02/2024 - 15/10/2024'
                },
            ]
        },
        {
            title:'TAKE15OFF (10)',
            key:'active',
            contacts:[
                {
                    icons:'coupon-per.png',
                    title:'10%'
                },
                {
                    icons:'c-calendar.png',
                    title:'11/02/2024 - 15/10/2024'
                },
            ]
        },
        {
            title:'BUY1GET1 (10) (10)',
            key:'active',
            contacts:[
                {
                    icons:'coupon-per.png',
                    title:'10%'
                },
                {
                    icons:'c-calendar.png',
                    title:'11/02/2024 - 15/10/2024'
                },
            ]
        },
        {
            title:'TAKE15OFF (10)',
            contacts:[
                {
                    icons:'coupon-per.png',
                    title:'10%'
                },
                {
                    icons:'c-calendar.png',
                    title:'11/02/2024 - 15/10/2024'
                },
            ]
        },
        {
            title:'BUY1GET1 (10)',
            key:'active',
            contacts:[
                {
                    icons:'coupon-per.png',
                    title:'11%'
                },
                {
                    icons:'c-calendar.png',
                    title:'11/02/2024 - 15/10/2024'
                },
            ]
        },


        {
            title:'Beard Styling',
            key:'active',
            contacts:[
                {
                    icons:'coupon-per.png',
                    title:'SAR 100'
                },
                {
                    icons:'c-calendar.png',
                    title:'11/02/2024 - 15/10/2024'
                },
            ]
        },
        {
            title:'TAKE15OFF (10)',
            contacts:[
                {
                    icons:'coupon-per.png',
                    title:'SAR 50'
                },
                {
                    icons:'c-calendar.png',
                    title:'11/02/2024 - 15/10/2024'
                },
            ]
        },
        {
            title:'TAKE15OFF (10)',
            key:'active',
            contacts:[
                {
                    icons:'coupon-per.png',
                    title:'SAR 100'
                },
                {
                    icons:'c-calendar.png',
                    title:'11/02/2024 - 15/10/2024'
                },
            ]
        },
        {
            title:'BUY1GET1 (10)',
            key:'active',
            contacts:[
                {
                    icons:'coupon-per.png',
                    title:'10%'
                },
                {
                    icons:'c-calendar.png',
                    title:'11/02/2024 - 15/10/2024'
                },
            ]
        },
    ]
  return (
    <div>
        <Row gutter={[16,16]}>
            {
                data?.map((cdata,c)=>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6} key={c}>
                        <Card style={{
                            borderRadius: token.borderCardrad,
                            width:"100%"}}
                            className='card-border'
                        >
                        <Flex vertical gap={5} align='center'>
                            <Flex align='center' gap={5} className='pt-3'>
                                <img src={
                                    cdata?.key === 'active' ? '/assets/icons/online.png' : '/assets/icons/offline.png'
                                } 
                                width={10} alt="" 
                                />
                                <Text strong>{cdata?.title}</Text>
                            </Flex>
                            <Text className='text-gray fs-13'>{cdata?.per}</Text>
                            <Flex vertical align='start' gap={10}  className='my-3 w-100'>
                                {
                                    cdata?.contacts?.map((contact,index)=>
                                        <Flex gap={5} align='center' key={index}>
                                            <div className='icon bg-secondary-color'>
                                                <img src={"/assets/icons/"+contact?.icons} width={20} alt="" />
                                            </div>
                                            <Text strong>{contact?.title}</Text>
                                        </Flex>
                                    )
                                }
                            </Flex>
                        </Flex>
                    </Card>  
                </Col>
                )
            }
            <Col span={24}>
                <Pagination
                    // showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    defaultCurrent={3}
                    align='center'
                    className='my-3'
                    position={'bottomCenter'}
                    total= {12}
                    // pageSize: pagination?.pageSize,
                    // defaultPageSize: pagination?.pageSize,
                    // current: pagination?.pageNo,
                    // size: "default",
                    // pageSizeOptions: ['10', '20', '50', '100'],
                    // onChange= {(pageNo, pageSize) => call(pageNo, pageSize)},
                    showTotal= {(total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>}
                />
            </Col>
        </Row>
    </div>
  )
}

export {GrideViewCoupon}