import React from 'react'
import { Button, Card, Col, Flex, Form, Row, Typography } from 'antd'
import {  ModuleTopHeading, HalfPaymentChartDonut, HalfCashChart, HalfCreditChart, MyDatepicker, TableRevenueServices, TableRevenueProducts, MySelect } from '../../components';
import { filterrevenue } from '../../shared/lookups';

const { Title,Text } = Typography
const RevenuePage = () => {
    const [form] = Form.useForm();

    return (
        <div>
            <Card className='shadow-c radius-12 border0 cardlg'>
                <Row gutter={24} align={'middle'}>
                    <Col span={24}>
                        <ModuleTopHeading name='Revenue By Payment Methods' />                       
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                            className='pt-3'
                        >
                            <Row gutter={16} align={'middle'}>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MySelect
                                        label= 'Filter'
                                        name="filter"
                                        value={form.getFieldValue("filter") || ''}
                                        options={filterrevenue}
                                    />
                                </Col>
                                <Col lg={4} md={12} sm={24} xs={12}>
                                    <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>   
                    <Col lg={{span:6}} md={{span:12}} sm={{span: 24}} xs={{span: 24}}>
                        <Flex vertical align='center'>
                            <Title level={5}>Online</Title>
                            <HalfPaymentChartDonut />
                        </Flex>
                    </Col> 
                    <Col lg={{span:6}} md={{span:12}} sm={{span: 24}} xs={{span: 24}}>
                        <Flex vertical align='center'>
                            <Title level={5}>In the Venue</Title>
                            <HalfCashChart />
                        </Flex>
                    </Col>                        
                    <Col lg={{span:6}} md={{span:12}} sm={{span: 24}} xs={{span: 24}}>
                        <Flex vertical align='center'>
                            <Title level={5}>Credit Card</Title>
                            <HalfCreditChart />
                        </Flex>
                    </Col> 
                    <Col lg={{span:6}} md={{span:12}} sm={{span: 24}} xs={{span: 24}}>
                        <Flex vertical align='end' className='mb-3 px-5'>
                            <Title level={4} className='mb-0'>SAR 23000</Title>
                            <Text>Total Revenue</Text>
                        </Flex> 
                    </Col>
                    <Col span={24}>
                        <TableRevenueServices />
                    </Col>
                    <Col span={24}>
                        <TableRevenueProducts />
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export { RevenuePage }
