import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col, Typography, Image, Flex, Button, Upload, message, Space} from "antd"
import { MyInput, MySelect } from "../../Forms"
import { ArrowDownOutlined, DownOutlined } from "@ant-design/icons";
import { ActionButton } from "../../PageComponents";

const { Title, Text } = Typography;
const { Dragger } = Upload
const AddViewSupportDrawer = ({visible, onClose, viewonly}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && viewonly) {
            
        } else {
            form.resetFields();
        }
    }, [visible, viewonly])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

    const props = {
        name: 'image',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };

  return (
    <Drawer
        title={
            viewonly ?
            <>
                Ticket: {viewonly?.ticketnumber}
            </>
            :
            'Add ticket'
        }
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={700}
        footer={
            !viewonly &&
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    Save
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                {
                    !viewonly &&
                    <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <Form.Item name='image'>
                            <div className='width-drag'>
                                <Dragger {...props} className="dragcs">
                                    <p className="ant-upload-drag-icon mb-0">
                                        <Image src='/assets/icons/plus.png' width={'41.6px'} preview={false} />
                                    </p>
                                    <Typography.Text className="fs-12 text-gray">Attach file (optional)</Typography.Text>
                                </Dragger>
                            </div>
                        </Form.Item>
                    </Col>
                }
                <Col span={24}>
                    <Title level={5} className="mb-3 mt-0 fw-500">TICKET DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Subject'
                        name='subject'
                        placeholder='e.g Subject'
                        size={'large'}
                        value={form.getFieldValue("subject") || ''}
                        disabled={viewonly ? true : false}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Category'
                        name='category'
                        placeholder='e.g System'
                        size={'large'}
                        value={form.getFieldValue("category") || ''}
                        disabled={viewonly ? true : false}
                    />
                </Col>
                <Col lg={!viewonly ? {span: 24} : {span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Priority'
                        name="priority" 
                        size={'large'}
                        required
                        message='Please choose priority'
                        value={form.getFieldValue("priority") || ''}
                        disabled={viewonly ? true : false}
                        options={[
                            {
                                key: 1,
                                name: 'High'
                            },
                            {
                                key: 2,
                                name: 'Medium'
                            },
                            {
                                key: 3,
                                name: 'Low'
                            },
                            {
                                key: 4,
                                name: 'Urgent'
                            },
                        ]}
                    />
                </Col>
                {
                    viewonly && 
                    <>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput
                            label='Status'
                            name='status'
                            placeholder='e.g Pending'
                            size={'large'}
                            value={form.getFieldValue("status") || ''}
                            disabled
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <div className='viewDownload space-between-align mb-3'>
                            <Text className='text-white fs-12'>
                                img.jpg
                            </Text>
                            <ActionButton
                                title='Delete'
                                icon={<Image src="/assets/icons/download.png" preview={false} width={25} />}
                                // onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                                type='ghost'
                            />
                        </div>
                    </Col>
                    </>
                }
                <Col span={24}>
                    <MyInput
                        textArea
                        label='Ticket Description'
                        name='ticketDescription'
                        placeholder='Description'
                        size={'large'}
                        value={form.getFieldValue("ticketDescription") || ''}
                        disabled={viewonly ? true : false}
                    />
                </Col>
                {
                    viewonly && 
                    <>
                        <Col span={24}>
                            <Title level={5} className="mb-3 mt-0 fw-500">COMMENTS</Title>
                        </Col>
                        <Col span={24}>
                            <Flex gap={5} align="center">
                                <Image src="/assets/images/av-1.png" preview={false} width={45} height={45} style={{borderRadius: 50}} />
                                <Text>
                                    <strong>HH Salon</strong> 2 hours ago
                                </Text>
                            </Flex>
                        </Col>
                        <Col span={24}>
                            <Flex justify="space-between" className="py-2 px-2 bg-light-gray" align="center">
                                <Text className="fs-13 fw-400">
                                    The problem is not solved till yet.
                                </Text>
                                <Button className='border0 bg-transparent text-gray fs-12'>
                                    <img src="/assets/icons/reply.png" width={15} alt="" />
                                    replay
                                </Button>
                            </Flex>
                        </Col>
                    </>
                }
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddViewSupportDrawer}