import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Flex, Switch, Pagination, Button } from 'antd'
import { ActionButton } from '../../PageComponents';

const { useToken } = theme;
const { Text } = Typography
const GridViewEmployees = ({ setDeleteItem, setVisible, setEditItem, setSwitchText, setActivityLog, setActivityId, setLeaveManage }) => {
    const { token } = useToken();


    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        setDeleteItem(true)
        setSwitchText(true)
    };

    const onShowSizeChange = (current, pageSize) => {
        console.log(current, pageSize);
      };

    const data = [
        {
            title: 'Mehwish Abbas',
            img: 'av-1.png',
            active:'online.png',
            key: 'active',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
        {
            title: 'Salim Al Tajir',
            img: 'av-2.jpg',
            active:'online.png',
            key: 'active',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
        {
            title: 'Mohammed Darwish',
            img: 'av-2.jpg',
            inactive:'offline.png',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
        {
            title: 'Jihad Bakir',
            img: 'av-2.jpg',
            active:'online.png',
            key: 'active',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },

        {
            title: 'Zuhair Hafeez',
            img: 'av-2.jpg',
            inactive:'offline.png',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },

        {
            title: 'Sameh Amin',
            img: 'av-1.png',
            active:'online.png',
            key: 'active',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },

        {
            title: 'Sumiya Bakir',
            img: 'av-1.png',
            inactive:'offline.png',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },

        {
            title: 'Ziyad Abdullah',
            img: 'av-1.png',
            active:'online.png',
            key: 'active',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
    ]
  return (
    <div>
        <Row gutter={[16,16]}>
            {
                data?.map((cdata,c)=>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6} key={c}>
                        <Card style={{
                            borderRadius: token.borderCardrad,
                            width:"100%"}}
                            className='card-border'
                            actions={[
                                <Space gap={15}>
                                    <Switch size='small' defaultChecked onChange={onChange} />
                                    <ActionButton
                                        title='Edit employee'
                                        shape="circle"
                                        icon={<Image src='/assets/icons/edit.png' width={20} preview={false} />}
                                        onClick={() => { setVisible(true);setEditItem({name: cdata?.title})}}
                                        className='border0 bg-transparent'
                                    />
                                    <ActionButton
                                        title='Activity log'
                                        shape="circle"
                                        icon={<Image src='/assets/icons/activitylog.png' width={18} preview={false} />}
                                        onClick={() => { setActivityLog(true); setActivityId(cdata?.title)}}
                                        className='border0 bg-transparent'
                                    />
                                    <ActionButton
                                        title='Leave management'
                                        shape="circle"
                                        icon={<Image src='/assets/icons/leavemanage.png' width={18} preview={false} />}
                                        onClick={() => { setLeaveManage(true)}}
                                        className='border0 bg-transparent'
                                    />
                                </Space>
                            ]}
                        >
                        <Flex vertical gap={5} align='center'>
                            <Image src={'/assets/images/'+cdata?.img} width={30} height={30} style={{borderRadius: 50}} />
                            <Flex gap={5} align='center'>
                                {
                                    cdata.key === 'active' ?
                                    <Image src={'/assets/icons/'+cdata?.active} width={8}/>:
                                    <Image src={'/assets/icons/'+cdata?.inactive} width={8}/>
                                }
                                <Text strong>{cdata?.title}</Text>
                            </Flex>
                            <Flex vertical align='start' gap={10}  className='my-3 w-100'>
                                {
                                    cdata?.contacts?.map((contact,index)=>
                                        <Flex gap={8} align='center' key={index}>
                                            <div className='icon bg-secondary-color'>
                                                <img src={"/assets/icons/"+contact?.icons} width={20} alt="" />
                                            </div>
                                            <Text>
                                                {contact?.title}
                                            </Text>
                                        </Flex>
                                    )
                                }
                            </Flex>
                        </Flex>
                    </Card>  
                </Col>
                )
            }
            <Col span={24}>
                <Pagination
                    // showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    defaultCurrent={3}
                    align='center'
                    className='my-3'
                    position={'bottomCenter'}
                    total= {12}
                    // pageSize: pagination?.pageSize,
                    // defaultPageSize: pagination?.pageSize,
                    // current: pagination?.pageNo,
                    // size: "default",
                    // pageSizeOptions: ['10', '20', '50', '100'],
                    // onChange= {(pageNo, pageSize) => call(pageNo, pageSize)},
                    showTotal= {(total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>}
                />
            </Col>
        </Row>
    </div>
  )
}

export {GridViewEmployees}