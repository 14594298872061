import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Flex } from 'antd'
import { ActionButton } from '../../PageComponents';


const { Text } = Typography


const TableViewTransaction = ({ setReceipt, setViewOnly }) => {
    const [form] = Form.useForm();

    const columns = [
        {
            title: 'Transaction#',
            dataIndex: 'transaction',
            sorter: (a,b) => a.transaction - b.transaction,
            width:150
        },
        {
            title: 'Reservation',
            dataIndex: 'reservation',
            sorter: (a,b) => a.reservation.localeCompare(b.reservation),
            width: 150
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            sorter: (a,b) => a.customerName.localeCompare(b.customerName),
            width:180
        },
        {
            title: 'Services',
            dataIndex: 'service',
            sorter: (a,b) => a.service.localeCompare(b.service),
            width:150
        },
        {
            title: 'Assigned To',
            dataIndex: 'assignto',
            sorter: (a,b) => a.assignto.localeCompare(b.assignto),
            width:150
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            sorter: (a,b) => a.productName.localeCompare(b.productName),
            width:150
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            sorter: (a,b) => a.quantity.localeCompare(b.quantity),
            width:150
        },
        {
            title: 'Transaction Date',
            dataIndex: 'transactionDate',
            sorter: (a,b) => a.transactionDate.localeCompare(b.transactionDate),
            width:200
        },
        {
            title: 'Transaction Type',
            dataIndex: 'transactionType',
            sorter: (a,b) => a.transactionType.localeCompare(b.transactionType),
            width:200
        },
        {
            title: 'Sub Total',
            dataIndex: 'subtotal',
            sorter: (a,b) => a.subtotal.localeCompare(b.subtotal),
            width:180
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            sorter: (a,b) => a.discount.localeCompare(b.discount),
            width:150,
            render: (discount) => {
                return(
                    <>
                        {
                            discount < 0 ?
                            <Text className='text-inactive'>{discount}</Text>
                            :
                            <Text>{discount}</Text>
                        }
                    </>
                )
            }
        },
        {
            title: 'Commission',
            dataIndex: 'commission',
            sorter: (a,b) => a.commission.localeCompare(b.commission),
            width:200,
            render: (commission) => <Text className='text-active'>{commission}</Text>
        },
        {
            title: 'Tax',
            dataIndex: 'tax',
            sorter: (a,b) => a.tax.localeCompare(b.tax),
            width:150,
            render: (tax) => <Text className='text-active'>{tax}</Text>
        },
        {
            title: 'Final Amount',
            dataIndex: 'finalAmount',
            sorter: (a,b) => a.finalAmount.localeCompare(b.finalAmount),
            width:150
        },
        {
            title: 'Payment Status',
            dataIndex: 'status',
            sorter: (a,b) => a.status - b.status,
            render: (status) => {
                return (
                    status !== 1 ? (
                        <Space align='center'>
                            <img src="/assets/icons/online.png" width={'8px'} alt="" />
                            <Text className='text-active' style={{transform:"translateY(-2px)",display:'block'}}>Paid</Text>
                        </Space>
                    ) : (
                        <Space>
                            <img src="/assets/icons/offline.png" width={'8px'} alt="" />
                            <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>Unpaid</Text>
                        </Space>
                    )
                )
            },
            width:150,
        },
        {
            title: 'Time Stamp',
            dataIndex: 'timeStamp',
            sorter: (a,b) => a.timeStamp.localeCompare(b.timeStamp),
            width:170
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 140,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='View'
                        shape="circle"
                        icon={<Image src='/assets/icons/lighteye.png' width={18} preview={false} />}
                        onClick={() => { setViewOnly({tax: row?.transaction})}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Receipt'
                        shape="circle"
                        icon={<Image src='/assets/icons/receipt.png' width={18} preview={false} />}
                        onClick={() => { setReceipt({name: row?.reservation})}}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            transaction:'487441',
            reservation: '274',
            customerName: 'Jihad Bakir',
            service: 'Beard Trim',
            assignto: 'Sameh Amin',
            productName:'Vit C Serum',
            quantity:'1',
            transactionDate: '11:03 11/02/2024',
            transactionType: 'Apple Pay',
            subtotal: '7791',            
            discount: '-500',
            commission:'5',
            tax: '1558.2',
            finalAmount: '6690',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '2',
            transaction:'267400',
            reservation: '484',
            customerName: 'Jihad Bakir',
            service: 'Beard Trim',
            assignto: 'Sameh Amin',
            productName:'Facial Pack',
            quantity:'2',
            transactionDate: '11:03 11/02/2024',
            transactionType: 'Apple Pay',
            subtotal: '9791',
            discount: '-200',
            commission:'5',
            tax: '1000',
            finalAmount: '6690',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '3',
            transaction:'558612',
            reservation: '109',
            customerName: 'Jihad Bakir',
            service: 'Beard Trim',
            assignto: 'Sameh Amin',
            productName:'Keratin Cream',
            quantity:'3',
            transactionDate: '11:03 11/02/2024',
            transactionType: 'Apple Pay',
            subtotal: '10791',
            discount: '0',
            commission:'5',
            tax: '2000',
            finalAmount: '6690',
            timeStamp: '11:02PM 9/18/16',
        },
      ];

  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1800}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    showSorterTooltip={false}
                    pagination={{
                        hideOnSinglePage: true,
                        position: ['bottomCenter'],
                        total: 12,
                        // pageSize: pagination?.pageSize,
                        // defaultPageSize: pagination?.pageSize,
                        // current: pagination?.pageNo,
                        // size: "default",
                        // pageSizeOptions: ['10', '20', '50', '100'],
                        // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                        showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                    }}
                />
            </Col>
        </Row>
    </div>
  )
}

export {TableViewTransaction}