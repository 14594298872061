import React, { useEffect, useState } from "react"
import { Form, Modal, Table, Typography, Row, Col, Flex, Image, Divider, Button} from "antd"

const { Text } = Typography

const ReceiptReservation = ({visible, onClose,viewonly}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && viewonly) {
            
        } else {
            form.resetFields();
        }
    }, [visible,viewonly])

    const data = {
        service:[
            {
                title:'Reservation Number',
                value: '00008'
            },
            {
                title:'Transaction Date',
                value: '25-02-2023, 13:22:16'
            },
            {
                title:'Payment Method',
                value: 'Salim Ahmed'
            },
            {
                title:'Sender Name',
                value: 'Salim Ahmed'
            },
        ],
        total:[
            {
                title:'Sub Total',
                value: 'SAR 35,000'
            },
            {
                title:'Discount',
                value: 'SAR 25'
            },
            {
                title:'Commission',
                value: '- SAR 5'
            },
            {
                title:'Tax (15%)',
                value: '- SAR 100'
            },
        ]
    }
    
    
    const columns = [
        {
            title: 'Product',
            dataIndex: 'product',
            width:150
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
        },
        {
            title: 'Amount (SAR)',
            dataIndex: 'amount',
            align:'end'
        },
    ];

    const datas = [
        {
            key: '1',
            product: <Text>Keratin Cream</Text>,
            quantity: <Text>1</Text>,
            amount: <Text>10,000</Text>,
        },
        {
            key: '2',
            product: <Text>Facial Package</Text>,
            quantity: <Text>1</Text>,
            amount: <Text>10,000</Text>,
        },
        {
            key: '3',
            product: <Text>Gold Serum</Text>,
            quantity: <Text>2</Text>,
            amount: <Text>5,000</Text>,
        },
      ];

      const columnser = [
        {
            title: 'Service',
            dataIndex: 'service',
            width:150
        },
        {
            title: 'With',
            dataIndex: 'with',
        },
        {
            title: 'Price (SAR)',
            dataIndex: 'price',
            align:'end'
        },
    ];

      const dataservice = [
        {
            key: '1',
            service: <Text>Hair Dye</Text>,
            with: <Text>M. Daud</Text>,
            price: <Text>10,000</Text>,
        },
        {
            key: '2',
            service: <Text>Hair Wash</Text>,
            with: <Text>M. Daud</Text>,
            price: <Text>5,000</Text>,
        },
        {
            key: '3',
            service: <Text>Blow Dry</Text>,
            with: <Text>M. Daud</Text>,
            price: <Text>2,000</Text>,
        },
      ];


    
  return (
    <Modal
        title={null}
        open={visible}
        width={500}
        centered 
        onOk={onClose} 
        onCancel={onClose}
        footer={
            <Flex justify="center">
                <Button  
                    className='btnsave'
                    type="primary"
                    onClick={()=>{}}
                >
                    Download
                </Button>
            </Flex>
        }
    >
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Flex justify="center" gap={10} vertical align="center">
                    <div className="pt-3">
                        <Image src='/assets/images/bannerimg-2.png' preview={false} width={120} />
                    </div>
                    <Text className="text-gray ">Wed 25 Sep, 2024 11:05:23</Text >
                    <Text className="text-gray ">Blue Valley, opposite Al-Hijaz Mall, Riyadh</Text>
                </Flex>
            </Col>
            <Col span={24}>
                <div className="dash-border text-center" >
                    <Text>Tax Number: 235ABG75445DFG6</Text>
                </div>
            </Col>
            <Col span={24}>
                {
                    data?.service?.map((item,index)=>
                        <div key={index}>
                            <Flex justify="space-between" className="my-2">
                                <Text className="fs-14">
                                    {item?.title}
                                </Text>
                                <Text className="fs-13 fw-500">
                                    {item?.value}
                                </Text>
                            </Flex>
                            <Divider className="m-0" /> 
                        </div>
                    )
                }
            </Col>
            <Col span={24}>
                <div className="dash-btm-border"></div>
            </Col>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columnser} 
                    dataSource={dataservice} 
                    className='transtable pagination'
                    rowHoverable={false}
                    pagination={false}
                />
                <Flex justify="space-between" className="margin-5">
                    <Text strong>
                        Total
                    </Text>
                    <Text strong>
                        SAR 17000
                    </Text>
                </Flex>
            </Col>
            <Col span={24}>
                <div className="dash-btm-border"></div>
            </Col>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={datas} 
                    className='transtable pagination'
                    rowHoverable={false}
                    pagination={false}
                />
                <Flex justify="space-between" className="margin-5">
                    <Text strong>
                        Total
                    </Text>
                    <Text strong>
                        SAR 9000
                    </Text>
                </Flex>
            </Col>
            <Col span={24}>
                <div className="dash-btm-border"></div>
            </Col>
            <Col span={24}>
                {
                    data?.total?.map((total,index)=>
                        <div key={index}>
                            <Flex justify="space-between" className="mb-2">
                                <Text className="fs-14">
                                    {total?.title}
                                </Text>
                                <Text className={
                                    total.value < 0 + '-' ? "fs-13 fw-500 text-inactive" : "fs-13 fw-500 text-active"
                                }>
                                    {total?.value}
                                </Text>
                            </Flex>
                            {
                                index < data?.total?.length - 1 &&
                                <Divider className="m-0" /> 
                            }
                        </div>
                    )
                }
                <hr />
                <Flex justify="space-between">
                    <Text strong>
                        Total
                    </Text>
                    <Text strong>
                        SAR 39,000
                    </Text>
                </Flex>
            </Col>
        </Row>
    </Modal>
  )
}

export {ReceiptReservation}