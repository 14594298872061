import React, { useCallback, useState } from 'react';
import { Card } from 'antd';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { CalendarEvents } from '../CalendarEvents';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import '../index.css';

const DnDCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment);

const CalendarParent = ({setVisible,setService,setEditItem,setProductState,setEditProduct}) => {
  
    const [myEvents, setMyEvents] = useState([
        {
        id: 1,
        start: moment("2024-09-27T10:00:00").toDate(),
        end: moment("2024-09-27T11:00:00").toDate(),
        title: 'Hair Dye, Hair Cut',
        status: 'completed',
        time: [{ innertime: '11:00' }, { innertime: '15:00' }],
        },
        {
        id: 2,
        start: moment("2024-09-28T10:00:00").toDate(),
        end: moment("2024-09-28T11:00:00").toDate(),
        title: 'Hair Dye, Hair Cut',
        status: 'progress',
        time: [{ innertime: '11:00' }],
        },
        {
        id: 3,
        start: moment("2024-09-29T10:00:00").toDate(),
        end: moment("2024-09-29T11:00:00").toDate(),
        title: 'Hair Dye, Hair Cut',
        status: 'cancel',
        time: [{ innertime: '11:00' }, { innertime: '14:00' }],
        },
        {
        id: 4,
        start: moment("2024-09-30T10:00:00").toDate(),
        end: moment("2024-09-30T11:00:00").toDate(),
        title: 'Hair Dye, Hair Cut',
        status: 'new',
        time: [{ innertime: '11:00' }, { innertime: '15:00' }],
        },
    ]);

  
    const moveEvent = useCallback(
        ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
        const { allDay } = event;
        if (!allDay && droppedOnAllDaySlot) {
            event.allDay = true;
        }
        if (allDay && !droppedOnAllDaySlot) {
            event.allDay = false;
        }

        setMyEvents((prev) =>
            prev.map((ev) =>
            ev.id === event.id ? { ...ev, start, end, allDay: event.allDay } : ev
            )
        );
        },
        [setMyEvents]
    );

  const handleSelectSlot = () => {
    setVisible(true);
  };

  return (
    <div>
      <Card className='shadow-c radius-12 border0'>
        <DnDCalendar
          localizer={localizer}
          events={myEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          views={['month', 'week', 'day']}
          draggableAccessor={(event) => true}
          onEventDrop={moveEvent}
          selectable
          components={{
            event: (eventProps) => (
              <CalendarEvents
                event={eventProps.event}
                title={eventProps.title}
                setService={setService}
                setEditItem={setEditItem}
                setProductState={setProductState}
                setEditProduct={setEditProduct}
              />
            ),
          }}
          onSelectSlot={handleSelectSlot}
          formats={{
            monthHeaderFormat: (date) => moment(date).format('DD MMMM YYYY'),
          }}
        />
      </Card>
    </div>
  );
};

export { CalendarParent };
