import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography, Checkbox, theme, Collapse } from "antd"
import { MyInput } from "../../Forms"
import { RightOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const EditroleDrawer = ({visible, onClose, edititem}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)
    const { token } = theme.useToken();

    const getItems = (panelStyle) => [
        {
          key: '1',
          label: 'Dashboard',
          children: 
            <Space direction="vertical" className="px-3">
                <Checkbox>View Whole Salon  Analytics</Checkbox>
                <Checkbox>View Own Analytics</Checkbox>
                <Checkbox>View All Employees Reservation Graph</Checkbox>
                <Checkbox>View Own  Reservation Graph</Checkbox>
                <Checkbox>View All Employees Staff Sessions Graph</Checkbox>
                <Checkbox>View Own Sessions Graph</Checkbox>
                <Checkbox>View All Employees Sales Tracking Graph</Checkbox>
                <Checkbox>View Own Sales Tracking Graph</Checkbox>
                <Checkbox>View All Employees Expense Tracking Graph</Checkbox>
                <Checkbox>View Own Expense Tracking Graph</Checkbox>
                <Checkbox>View All Employees Revenue Tracking Graph</Checkbox>
                <Checkbox>View Own Revenue Tracking Graph</Checkbox>
                <Checkbox>View Filters</Checkbox>
            </Space>,
          style: panelStyle,
        },
        {
            key: '2',
            label: 'Role & Permissions',
            children: 
              <Space direction="vertical" className="px-3">
                  <Checkbox>Add role & assign permissions</Checkbox>
                  <Checkbox>Edit role</Checkbox>
                  <Checkbox>Delete role</Checkbox>
              </Space>,
            style: panelStyle,
        },
        {
            key: '3',
            label: 'Employees',
            children: 
              <Space direction="vertical" className="px-3">
                  <Checkbox>View employees</Checkbox>
                  <Checkbox>Add employees</Checkbox>
                  <Checkbox>Edit employees</Checkbox>
                  <Checkbox>Active/inactive employee</Checkbox>
                  <Checkbox>View activity log</Checkbox>
              </Space>,
            style: panelStyle,
        },
        {
          key: '4',
          label: 'Payroll',
          children: 
            <Space direction="vertical" className="px-3">
                <Checkbox>View payroll</Checkbox>
                <Checkbox>Change payroll status</Checkbox>
                <Checkbox>Add payroll</Checkbox>
                <Checkbox>Edit payroll</Checkbox>
                <Checkbox>Download payroll receipt</Checkbox>
                <Checkbox>View carts</Checkbox>
            </Space>,
          style: panelStyle,
        },
        {
          key: '5',
          label: 'Categories Management',
          children: 
            <Space direction="vertical" className="px-3">
                <Checkbox>View categories</Checkbox>
                <Checkbox>Add categories</Checkbox>
                <Checkbox>Edit categories</Checkbox>
                <Checkbox>Active/inactive categories</Checkbox>
            </Space>,
          style: panelStyle,
        },        
        {
            key: '6',
            label: 'Services Management',
            children: 
              <Space direction="vertical" className="px-3">
                  <Checkbox>View services</Checkbox>
                  <Checkbox>Add services</Checkbox>
                  <Checkbox>Edit services</Checkbox>
                  <Checkbox>Active/Inactive services</Checkbox>
              </Space>,
            style: panelStyle,
        },
        {
            key: '7',
            label: 'Products',
            children: 
              <Space direction="vertical" className="px-3">
                  <Checkbox>View products</Checkbox>
                  <Checkbox>Add stock</Checkbox>
                  <Checkbox>Remove stock</Checkbox>
                  <Checkbox>Add counted stock amount</Checkbox>
                  <Checkbox>Add products</Checkbox>
                  <Checkbox>Edit products</Checkbox>
                  <Checkbox>View stock history</Checkbox>
                  <Checkbox>Delete products</Checkbox>
              </Space>,
            style: panelStyle,
        },
        {
            key: '8',
            label: 'Product Category',
            children: 
              <Space direction="vertical" className="px-3">
                  <Checkbox>View product category</Checkbox>
                  <Checkbox>Add product category</Checkbox>
                  <Checkbox>Edit product category</Checkbox>
                  <Checkbox>Delete product category</Checkbox>
              </Space>,
            style: panelStyle,
        },
        {
            key: '9',
            label: 'Suppliers',
            children: 
                <Space direction="vertical" className="px-3">
                    <Checkbox>View suppliers</Checkbox>
                    <Checkbox>Add suppliers</Checkbox>
                    <Checkbox>Edit suppliers</Checkbox>
                    <Checkbox>Delete suppliers</Checkbox>
                </Space>,
            style: panelStyle,
        },

        {
            key: '10',
            label: 'Transactions',
            children: 
                    <Space direction="vertical" className="px-3">
                        <Checkbox>View transactions</Checkbox>
                        <Checkbox>Download transactions receipt</Checkbox>
                    </Space>,
            style: panelStyle,
        },
        {
            key: '11',
            label: 'Reservations',
            children: 
                <Space direction="vertical"  className="px-3">
                    <Checkbox>View all reservations</Checkbox>
                    <Checkbox>View own reservations</Checkbox>
                    <Checkbox>Add reservations</Checkbox>
                    <Checkbox>Edit reservations</Checkbox>
                    <Checkbox>Add discount</Checkbox>
                </Space>,
            style: panelStyle,
        },
        {
            key: '12',
            label: 'Expense',
            children: 
                <Space direction="vertical"  className="px-3">
                    <Checkbox>View expense</Checkbox>
                    <Checkbox>Add expense</Checkbox>
                    <Checkbox>Edit expense</Checkbox>
                    <Checkbox>Download expense receipt</Checkbox>
                    <Checkbox>Delete expense</Checkbox>
                    <Checkbox>Export expense</Checkbox>
                </Space>,
            style: panelStyle,
        },
        {
            key: '13',
            label: 'Revenue',
            children: 
                <Space direction="vertical"  className="px-3">
                    <Checkbox>View revenue by payment methods</Checkbox>
                    <Checkbox>View revenue by services</Checkbox>
                    <Checkbox>View revenue by products</Checkbox>
                </Space>,
            style: panelStyle,
        },
        {
            key: '14',
            label: 'Tax Management',
            children: 
                <Space direction="vertical"  className="px-3">
                    <Checkbox>View tax details</Checkbox>
                    <Checkbox>Export tax details</Checkbox>
                </Space>,
            style: panelStyle,
        },
        {
            key: '15',
            label: 'Profit & Loss Report',
            children: 
                <Space direction="vertical"  className="px-3">
                    <Checkbox>View profit & loss report</Checkbox>
                    <Checkbox>Export profit & loss report</Checkbox>
                </Space>,
            style: panelStyle,
        },
        {
            key: '16',
            label: 'Customers',
            children: 
                <Space direction="vertical"  className="px-3">
                    <Checkbox>View customers</Checkbox>
                    <Checkbox>Edit customers</Checkbox>
                    <Checkbox>View customer history</Checkbox>
                </Space>,
            style: panelStyle,
        },
        {
            key: '17',
            label: 'Reviews & Ratings',
            children: 
                <Space direction="vertical"  className="px-3">
                    <Checkbox>View reviews & ratings</Checkbox>
                    <Checkbox>Delete reviews & ratings</Checkbox>
                </Space>,
            style: panelStyle,
        },
        {
            key: '18',
            label: 'Support & Helpdesk',
            children: 
                <Space direction="vertical"  className="px-3">
                    <Checkbox>View all support & helpdesk</Checkbox>
                    <Checkbox>View own added support & helpdesk</Checkbox>
                    <Checkbox>Add support & helpdesk</Checkbox>
                    <Checkbox>View comments</Checkbox>
                </Space>,
            style: panelStyle,
        },
        {
            key: '19',
            label: 'Operating Hours',
            children: 
                <Space direction="vertical"  className="px-3">
                    <Checkbox>View operating hours</Checkbox>
                    <Checkbox>Add operating hours</Checkbox>
                    <Checkbox>Edit operating hours</Checkbox>
                </Space>,
            style: panelStyle,
        },
    ];

  
    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const closeDrawer= ()=>{
        form.resetFields()
        onClose()
    }

    
    const panelStyle = {
        marginBottom: 0,
        padding: 0,
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: 'none',
    };


  return (
    <Drawer
        title={'Assign role & permissions'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    Update
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-500">ROLE DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Role Name'
                        name='roleName'
                        placeholder='e.g Data Entry'
                        required
                        size={'large'}
                        message='Please enter role name'
                        value={form.getFieldValue("roleName") || ''}
                        disabled
                    />
                </Col>
                <Col span={24}>
                    <Title level={5} className="mb-3 mt-0 fw-500">PERMISSIONS</Title>
                </Col>
                <Col span={24}>
                    <Collapse
                        bordered={false}
                        defaultActiveKey={['1']}
                        className="collapse-cs"
                        expandIcon={({ isActive }) => 
                            <Space>
                                <RightOutlined rotate={isActive ? 90 : 0} />
                                <Checkbox checked={isActive ? true : false} title={getItems?.label} />
                            </Space>
                        }
                        style={{
                            background: token.colorBgContainer,
                        }}
                        items={getItems(panelStyle)}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {EditroleDrawer}