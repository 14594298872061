import React, { useState } from 'react'
import { Modal, Space, Typography, Col, Row, Form, Flex,} from 'antd'

const { Text } = Typography
const LeaveViewModal = ({visible,onClose}) => {
    
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)

    const data = [
        {
            title:'Leave Type:',
            desc:'Annual Leave'
        },
        {
            title:'Start Date:',
            desc:'12:00 12/02/2023'
        },
        {
            title:'End Date:',
            desc:'12:00 15/02/2023'
        },
        {
            title:'Note: ',
            desc:'Lorem ipsum'
        },
    ]

  return (
    <div>
        <Modal title='View  Leave Details' width={700} className='shadow-c'  open={visible} onOk={onClose} onCancel={onClose} centered footer={null}>
            {
                data?.map((data,index)=>
                    <Flex align='center' gap={5} key={index} className='mb-1'>
                        <Text>
                            {
                                data?.title
                            }
                        </Text>
                        <Text>
                            {
                                data?.desc
                            }
                        </Text>
                    </Flex>
                )
            }
        </Modal>
    </div>
  )
}

export {LeaveViewModal}