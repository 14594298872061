import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography} from "antd"
import { MyInput, MySelect } from "../../Forms"

const { Title } = Typography;




const AddEditCategoryDrawer = ({visible, onClose, edititem}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }


  return (
    <Drawer
        title={edititem?'Edit category':'Add category'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {edititem? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3">CATEGORY DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Category Name (English)'
                        name='categoryNameeng'
                        placeholder='e.g Manicure'
                        required
                        size={'large'}
                        message='Please enter category name (English)'
                        value={form.getFieldValue("categoryNameeng") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Category Name (Arabic)'
                        name='categoryNamearab'
                        placeholder='على سبيل المثال مانيكير'
                        required
                        size={'large'}
                        message='Please enter category name (Arabic)'
                        value={form.getFieldValue("categoryNamearab") || ''}
                        className='text-end'
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditCategoryDrawer}