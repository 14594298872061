import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography, Upload, message, Image} from "antd"
import { MyDatepicker, MyInput, MySelect } from "../../Forms"

const { Title, Text } = Typography;
const { Dragger } = Upload;
const AddEditProductDrawer = ({visible, onClose, edititem}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

    const props = {
        name: 'image',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };


  return (
    <Drawer
        title={edititem?'Edit product':'Add product'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {edititem? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3">PRODUCT DETAILS</Title>
                </Col>
                <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item name='image'>
                        <div className='width-drag'>
                            <Dragger {...props} className="dragcs">
                                <p className="ant-upload-drag-icon mb-0">
                                    <Image src='/assets/icons/plus.png' width={'41.6px'} preview={false} />
                                </p>
                                <Typography.Text className="fs-13">Upload Product Image</Typography.Text>
                            </Dragger>
                        </div>
                        {/* {
                            editproperty &&
                            <Space direction='vertical' size={10}>
                                {
                                    geteditdata?.medical_report?.map((data,index)=>
                                        <div className='viewDownload space-between-align' key={index}>
                                            <Typography.Text className='gray-text fs-12'>
                                                {
                                                    data?.name
                                                }
                                            </Typography.Text>
                                            <ActionButton
                                                title='Delete'
                                                icon={<DeleteOutlined className='danger-color' />}
                                                onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                                                type='ghost'
                                            />
                                        </div>
                                    )
                                }
                            </Space>
                            
                        } */}
                    </Form.Item>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Product Name'
                        name='productName'
                        placeholder='e.g Keratin Cream'
                        required
                        size={'large'}
                        message='Please enter product name'
                        value={form.getFieldValue("productName") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label='Product Category'
                        name='procategory'
                        placeholder='e.g Hair'
                        size={'large'}
                        required
                        message='Please enter product category'
                        value={form.getFieldValue("procategory") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Hair Care',
                            },
                            {
                                key: 2,
                                name: 'Skin Care',
                            },
                        ]}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Barcode'
                        name='barcode'
                        placeholder='e.g 20052463738'
                        size={'large'}
                        required
                        message='Please enter barcode'
                        value={form.getFieldValue("barcode") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Brand'
                        name='brand'
                        placeholder='e.g Keune'
                        size={'large'}
                        required
                        message='Please enter brand'
                        value={form.getFieldValue("brand") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Supplier'
                        name="supplier"
                        size={'large'}
                        required
                        message='Please choose supplier'
                        value={form.getFieldValue("supplier") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Supplier name 01',
                            },
                            {
                                key: 2,
                                name: 'Supplier name 02',
                            },
                        ]}
                    />
                </Col>
                <Col span={24}>
                    <MyInput
                        textArea
                        label='Product Description (optional)'
                        name='productDescription'
                        placeholder='e.g Lorem ipsum'
                        size={'large'}
                        value={form.getFieldValue("productDescription") || ''}
                    />
                </Col>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3">STOCK DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Primary SKU (optional)'
                        name='primarySku'
                        type='number'
                        placeholder=''
                        size={'large'}
                        value={form.getFieldValue("primarySku") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Total  Quantity'
                        name='totalquantity'
                        type='number'
                        placeholder='e.g 100'
                        size={'large'}
                        required
                        message='Please enter total quantity'
                        value={form.getFieldValue("totalquantity") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Price Per Unit'
                        name='pricePerunit'
                        placeholder=''
                        size={'large'}
                        addonAfter={<Text className="text-brand fs-13">SAR</Text>}
                        required
                        message='Please enter price per unit'
                        value={form.getFieldValue("pricePerunit") || ''}
                    />
                </Col> 
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Total Cost'
                        name='totalCost'
                        placeholder=''
                        size={'large'}
                        addonAfter={<Text className="text-brand fs-13">SAR</Text>}
                        required
                        message='Please enter total cost'
                        value={form.getFieldValue("totalCost") || ''}
                    />
                </Col>                
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditProductDrawer}