import React from 'react'
import { Button, Row, Col, Typography, Table, Space } from 'antd'

const { Text } = Typography
const TableViewCoupon = () => {

    const columns = [
        {
            title: 'Coupon ID',
            dataIndex: 'index',
            sorter: (a,b) => a.index - b.index,
            width:150
        },
        {
            title: 'Coupon Code',
            dataIndex: 'couponCode',
            sorter: (a,b) => a.couponCode.localeCompare(b.couponCode),
        },
        {
            title: 'No. of Use',
            dataIndex: 'use',
            sorter: (a,b) => a.use.localeCompare(b.use),
        },
        {
            title: 'Left',
            dataIndex: 'left',
            sorter: (a,b) => a.left.localeCompare(b.left),
        },
        {
            title: 'Coupon Type',
            dataIndex: 'couponType',
            sorter: (a,b) => a.couponType.localeCompare(b.couponType),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            sorter: (a,b) => a.amount.localeCompare(b.amount),
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            sorter: (a,b) => a.startDate.localeCompare(b.startDate),
        },
        {
            title: 'End Date',
            dataIndex: 'EndDate',
            sorter: (a,b) => a.EndDate.localeCompare(b.EndDate),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a,b) => a.status - b.status,
            render: (status) => {
                return (
                    status !== 1 ? (
                        <Space align='center'>
                            <img src="/assets/icons/online.png" width={'8px'} alt="" />
                            <Text className='text-active' style={{transform:"translateY(-2px)",display:'block'}}>Active</Text>
                        </Space>
                    ) : (
                        <Space>
                            <img src="/assets/icons/offline.png" width={'8px'} alt="" />
                            <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>Inactive</Text>
                        </Space>
                    )
                );
            },
        },
        {
            title: 'Time Stamp',
            dataIndex: 'timeStamp',
            sorter: (a,b) => a.timeStamp.localeCompare(b.timeStamp),
        },
    ];

    const data = [
        {
            key: '1',
            index:'#11',
            couponCode: 'FREESHIP75',
            use: '67',
            left: '20',
            couponType: 'Percentage',
            amount: '37',
            startDate: '11/02/2024',
            EndDate: '12/02/2024',
            timeStamp: '12:02PM 09/20/16',
        },
        {
            key: '2',
            index:'#12',
            couponCode: 'BOGO50',
            use: '76',
            left: '2',
            couponType: 'Fixed',
            amount: '87',
            startDate: '12/02/2024',
            EndDate: '13/02/2024',
            timeStamp: '01:02PM 9/22/16',
        },
        {
            key: '3',
            index:'#13',
            couponCode: 'SAVE10NOW',
            use: '56',
            left: '10',
            couponType: 'Percentage',
            amount: '67',
            startDate: '14/02/2024',
            EndDate: '15/02/2024',
            timeStamp: '04:02PM 10/22/16',
        },
        {
            key: '4',
            index:'#14',
            couponCode: 'TAKE15OFF',
            use: '86',
            left: '76',
            couponType: 'Fixed',
            amount: '77',
            startDate: '20/02/2024',
            EndDate: '21/02/2024',
            timeStamp: '06:02PM 07/22/16',
        },
      ];

  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    showSorterTooltip={false}
                    pagination={{
                        hideOnSinglePage: true,
                        position: ['bottomCenter'],
                        total: 12,
                        // pageSize: pagination?.pageSize,
                        // defaultPageSize: pagination?.pageSize,
                        // current: pagination?.pageNo,
                        // size: "default",
                        // pageSizeOptions: ['10', '20', '50', '100'],
                        // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                        showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                    }}
                />
            </Col>
        </Row>
    </div>
  )
}

export {TableViewCoupon}