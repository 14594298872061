import React, { useState } from "react";
import { Row, Col, Form, Space, Button } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import { MyInput, MySelect } from "../../Forms";
import { ModuleTopHeading } from "../../PageComponents";
import { products } from "../../../shared/lookups";

const MultipleProductField = ({ form }) => {


  return (
    <Form.List name={'products'}>
      {(fields, { add, remove }) => (
        <Space direction="vertical" className="w-100">
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <ModuleTopHeading 
                        name='Products'
                        onClick={() => add()}
                    />
                </Col>
                <Col span={24}>
                    {fields.length === 0 && add()}
                    {fields.map(({ key, name }, index) => (
                        <Row key={key} gutter={[16, 0]} align="middle" className="mb-1">
                            <Col xs={24} sm={24} md={12} lg={index === 0 ? 12 : 11}>
                                <MySelect
                                    label= 'Product Category'
                                    name={[name, 'productcate']}
                                    mode={'multiple'}
                                    size={'large'}
                                    required
                                    message='Please choose product category'
                                    options={products}
                                    value={form.getFieldValue("productcate") || ''}
                                />
                            </Col>
                            <Col xs={22} sm={22} md={11} lg={index === 0 ? 12 : 11}>
                                <MyInput
                                    label='Products Name'
                                    name={[name, 'productname']}
                                    required
                                    size={'large'}
                                    message='Please enter product name'
                                    value={form.getFieldValue("productname") || ''}
                                />
                            </Col>
                            {fields.length > 0  && index > 0 && (
                                <Col span={index === 0 ? 0 : 1}>
                                    <Button 
                                        type="primary" 
                                        shape="circle" 
                                        size='small' 
                                        className='bg-close text-white'
                                        icon={<MinusOutlined/>}
                                        onClick={() => remove(name)}
                                    />
                                </Col>
                            )}

                        </Row>
                    ))}
                </Col>
            </Row>
        </Space>
      )}
    </Form.List>
  );
};

export {MultipleProductField};
