const PropertyFor = [
    {
        name: 'Najla',
        id : 1
    },
    {
        name: 'Client',
        id : 2
    },
]

const PropertyClient = [
    {
        name: 'M.Ali',
        id:1
    },
    {
        name: 'Arbaz Khan',
        id:2
    },
    {
        name: 'Shujat Ali',
        id:3
    },
]

const PropertyCity = [
    {
        name: 'Islamabad',
        id: 1
    },
    {
        name: 'Lahore',
        id: 2
    },
]

const Propertytype = [
    {
        name: 'Room',
        id: 1
    },
    {
        name: 'Appartment',
        id: 2
    },
]

const PropertyOwnership = [
    {
        name:'Own',
        id: 1
    },
    {
        name: 'Rented',
        id: 2
    }
]

const discount = [
    {
        name:'10%',
        id: 1
    },
    {
        name: '15%',
        id: 2
    },
    {
        name: '20%',
        id: 2
    },
    {
        name: 'Custom',
        id: 2
    },
]

const products = [
    {
        name:'Hair Dyes',
        id:1,
    },
    {
        name:'Waxing',
        id:2,
    },
    {
        name:'Shampoo',
        id:3,
    },
    {
        name:'Cleanser',
        id:4,
    },
    {
        name:'Shaving Cream',
        id:5,
    },
    {
        name:'Blow Dry',
        id:6,
    },
    {
        name:'Straightener',
        id:7,
    },
    {
        name:'Keratin Cream',
        id:8,
    },
]

const filterrevenue = [
    {
        name:'Last week',
        id: 1
    },
    {
        name:'Last month',
        id: 2
    },
    {
        name:'Last quarter',
        id: 3
    },
    {
        name:'Last year',
        id: 4
    },
]

export {PropertyFor, PropertyClient, PropertyCity, Propertytype, PropertyOwnership, discount,products,filterrevenue}