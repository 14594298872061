import React from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Flex, Pagination, Button } from 'antd'
import { ActionButton } from '../../PageComponents';

const { useToken } = theme;
const { Text } = Typography
const GridViewCustomer = ({ setVisible, setEditItem, setHistory }) => {
    const { token } = useToken();

    const onShowSizeChange = (current, pageSize) => {
        console.log(current, pageSize);
      };

    const data = [
        {
            title:'Nadeem Abbas',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
        {
            title:'Salim Al Tajir',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
        {
            title:'Mohammed Darwish',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
        {
            title:'Jihad Bakir',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
        {
            title:'Zuhair Hafeez',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
        {
            title:'Sameh Amin',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
        {
            title:'Fahd Bakir',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
        {
            title:'Ziyad Abdullah',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
    ]
  return (
    <div>
        <Row gutter={[16,16]}>
            {
                data?.map((cdata,index)=>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6} key={index}>
                        <Card style={{
                            borderRadius: token.borderCardrad,
                            width:"100%"}}
                            actions={[
                                <Space align='center'>
                                    <ActionButton
                                        title='Edit customer'
                                        icon={<Image src='/assets/icons/edit.png' width={18} preview={false} />}
                                        onClick={() => { setVisible(true); setEditItem({id:cdata?.title}) }}
                                        className='border0 bg-transparent'
                                    />
                                    <ActionButton
                                        title='Customer history'
                                        icon={<Image src='/assets/icons/cust-history.png' width={22} preview={false} />}
                                        onClick={() => { setHistory({name:cdata?.title}) }}
                                        className='border0 bg-transparent'
                                    />
                                </Space>
                            ]}
                            className='card-border'
                        >
                        <Flex vertical gap={5} align='center'>
                            <Text strong>{cdata?.title}</Text>
                            <Text className='text-gray'>{cdata?.gender}</Text>
                            <Flex vertical align='start' gap={10}  className='my-3 w-100'>
                                {
                                    cdata?.contacts?.map((contact,index)=>
                                        <Flex gap={5} align='center' key={index}>
                                            <div className='icon bg-secondary-color'>
                                                <img src={"/assets/icons/"+contact?.icons} width={20} alt="" />
                                            </div>
                                            <Text strong>{contact?.title}</Text>
                                        </Flex>
                                    )
                                }
                            </Flex>
                        </Flex>
                    </Card>  
                </Col>
                )
            }
            <Col span={24}>
                <Pagination
                    // showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    defaultCurrent={3}
                    align='center'
                    className='my-3'
                    position={'bottomCenter'}
                    total= {12}
                    // pageSize: pagination?.pageSize,
                    // defaultPageSize: pagination?.pageSize,
                    // current: pagination?.pageNo,
                    // size: "default",
                    // pageSizeOptions: ['10', '20', '50', '100'],
                    // onChange= {(pageNo, pageSize) => call(pageNo, pageSize)},
                    showTotal= {(total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>}
                />
            </Col>
        </Row>
    </div>
  )
}

export {GridViewCustomer}