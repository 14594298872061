import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Flex } from 'antd'
import { ActionButton } from '../../PageComponents';
const { Text } = Typography
const TableViewPayroll = ({ setVisibleModal, setEditItem, setVisible, setPaymentStatus, setReceipt }) => {
    const [form] = Form.useForm();

    const columns = [
        {
            title: 'Employee ID',
            dataIndex: 'id',
            sorter: (a,b) => a.id - b.id,
        },
        {
            title: 'Employee Name',
            dataIndex: 'employeeName',
            sorter: (a,b) => a.employeeName.localeCompare(b.employeeName),
            width:200
        },
        {
            title: 'Basic Salary',
            dataIndex: 'basicSalary',
            sorter: (a,b) => a.basicSalary.localeCompare(b.basicSalary),
        },
        {
            title: 'Total Earning',
            dataIndex: 'totalEarn',
            sorter: (a,b) => a.totalEarn.localeCompare(b.totalEarn),
            render: (totalEarn) => <Text className='text-active'>{totalEarn}</Text>
        },
        {
            title: 'Deduction',
            dataIndex: 'deduction',
            sorter: (a,b) => a.deduction.localeCompare(b.deduction),
            render: (deduction) => {
                return(
                    <>
                        {
                            deduction < 0 ? (
                                <Text className='text-inactive'>{deduction}</Text>
                            ) : (
                                <Text>{deduction}</Text>
                            )
                        }
                    </>
                )   
            }
        },
        {
            title: 'Social Security',
            dataIndex: 'socialsecurity',
            sorter: (a,b) => a.socialsecurity.localeCompare(b.socialsecurity),
            render: (socialsecurity) => {
                return (
                    <>
                        {socialsecurity < 0 ? (
                            <Text className='text-inactive'>{socialsecurity}</Text>
                         ) : (
                            <Text>{socialsecurity}</Text>
                         )
                        }
                    </>
                )
            },
            width: 160
        },
        {
            title: 'Net Salary / Hour Price',
            dataIndex: 'netSalary',
            sorter: (a,b) => a.netSalary.localeCompare(b.netSalary),
            width: 200
        },
        {
            title: 'Pay Date',
            dataIndex: 'payDate',
            sorter: (a,b) => a.payDate.localeCompare(b.payDate),
        },
        {
            title: 'Pay Period',
            dataIndex: 'payPeriod',
            sorter: (a,b) => a.payPeriod.localeCompare(b.payPeriod),
        },
        {
            title: 'Payroll Status',
            dataIndex: 'status',
            width:160,
            sorter: (a,b) => a.status - b.status,
            render: (status) => {
                return (
                    status === 1 ? (
                        <Space align='center'>
                            <img src="/assets/icons/online.png" width={'8px'} alt="" />
                            <Text className='text-active' style={{transform:"translateY(-2px)",display:'block'}}>Paid</Text>
                        </Space>
                    ) : (
                        <Space className='cursor' onClick={()=>setPaymentStatus(true)}>
                            <img src="/assets/icons/offline.png" width={'8px'} alt="" />
                            <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>Unpaid</Text>
                        </Space>
                    )
                );
            },
        },
        {
            title: 'Time Stamp',
            dataIndex: 'timeStamp',
            sorter: (a,b) => a.timeStamp.localeCompare(b.timeStamp),
        },
        {
            title: <Text>Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 120,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='View'
                        shape="circle"
                        icon={<Image src='/assets/icons/lighteye.png' width={18} preview={false} />}
                        onClick={() => { setVisibleModal(true)}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Edit'
                        shape="circle"
                        icon={<Image src='/assets/icons/edit.png' width={18} preview={false} />}
                        onClick={() => { setVisible(true); setEditItem({name: row?.expenseName})}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Receipt'
                        icon={<Image src='/assets/icons/receipt.png' width={18} preview={false} />}
                        onClick={() => setReceipt({name:row?.id})}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            id:'9261',
            employeeName: 'Jihad Bakir',
            basicSalary: '2740',
            totalEarn: '6025',
            deduction: '-196',
            socialsecurity: '-206',
            netSalary: '9261',
            payDate: '5/19/12',
            payPeriod: 'Monthly',
            status: 'Paid',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '2',
            id:'558612',
            employeeName: 'Mohammed Darwish',
            basicSalary: '2740',
            totalEarn: '6025',
            deduction: '0',
            socialsecurity: '-189',
            netSalary: '9261',
            payDate: '5/19/12',
            payPeriod: 'Hourly',
            status: 'Paid',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '3',
            id:'487441',
            employeeName: 'Shahid Ahmad',
            basicSalary: '2740',
            totalEarn: '6025',
            deduction: '-120',
            socialsecurity: '-160',
            netSalary: '9261',
            payDate: '5/19/12',
            payPeriod: 'Monthly',
            status: 'Unpaid',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '4',
            id:'651535',
            employeeName: 'Nadeem Abbad',
            basicSalary: '2740',
            totalEarn: '6025',
            deduction: '-280',
            socialsecurity: '0',
            netSalary: '9261',
            payDate: '5/19/12',
            payPeriod: 'Hourly',
            status: 'Paid',
            timeStamp: '11:02PM 9/18/16',
        },
      ];

  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1800}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    showSorterTooltip={false}
                    pagination={{
                        hideOnSinglePage: true,
                        position: ['bottomCenter'],
                        total: 12,
                        // pageSize: pagination?.pageSize,
                        // defaultPageSize: pagination?.pageSize,
                        // current: pagination?.pageNo,
                        // size: "default",
                        // pageSizeOptions: ['10', '20', '50', '100'],
                        // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                        showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                    }}
                />
            </Col>
        </Row>
    </div>
  )
}

export {TableViewPayroll}