import React from 'react'
import { Typography, Table} from "antd"

const { Text } = Typography;

const StockTable = () => {


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Details',
            dataIndex: 'detail',
        },
    ];

    const data = [
        {
            key: '1',
            name: <Text>Primary SKU</Text>,
            detail: <Text>-</Text>,
        },
        {
            key: '2',
            name: <Text>In Stock</Text>,
            detail: <Text>20</Text>,
        },
        {
            key: '3',
            name: <Text>Price Per Unit</Text>,
            detail: <Text>200</Text>,
        },
        {
            key: '4',
            name: <Text>Total Cost</Text>,
            detail: <Text>40000</Text>,
        },

        {
            key: '5',
            name: <Text>Stock Month</Text>,
            detail: <Text>May</Text>,
        },
        
      ];

  return (
        <Table 
            size='large'
            columns={columns} 
            dataSource={data} 
            className='table-dark'
            rowHoverable={false}
            pagination={false}
        />
  )
}

export {StockTable}