import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Row } from 'antd'
import { AlertModal, ModuleTopHeading, MyDatepicker, MyInput, MySelect, GridViewCategory, TableViewCategory, AddEditCategoryDrawer } from '../../components';


const CategoryManagement = () => {
    const [ current, setCurrent ] = useState(true)
    const [form] = Form.useForm();
    const [ visible, setVisible ] = useState(false)
    const [ edititem, setEditItem ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [ switchtext, setSwitchText ] = useState(false)

    return (
        <div>
            <Card className='shadow-c radius-12 border0 cardlg'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center' gap={10} wrap>
                            <ModuleTopHeading name='Categories Management' onClick={()=> setVisible(true)} />
                            <Flex gap={'small'}>
                                <Button
                                    onClick={() => setCurrent(!current)}
                                    className='bg-transparent border0 p-0'
                                >
                                    {
                                        current ?
                                        <img src="/assets/icons/list-ic.png" alt="" width={60} />
                                        :
                                        <img src="/assets/icons/grid-ic.png" alt="" width={60} />
                                    }
                                </Button>
                            </Flex>
                        </Flex>                        
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Row gutter={16} align={'middle'}>
                                <Col lg={4} md={12} sm={24} xs={24}>
                                    <MyInput
                                        name='categoryName'
                                        label='Category Name'
                                        value={form.getFieldValue("categoryName") || ''}
                                        className='w-100 bg-transparent'
                                    />
                                </Col>
                                <Col lg={3} md={12} sm={24} xs={24}>
                                    <MySelect
                                        label= 'Status'
                                        name="status"
                                        value={form.getFieldValue("status") || ''}
                                        options={[
                                            {
                                                key: 1,
                                                name: 'Active',
                                            },
                                            {
                                                key: 2,
                                                name: 'Inactive',
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MyDatepicker
                                        rangePicker
                                        label="Registration Date"
                                        name='regDate'
                                        value={form.getFieldValue('regDate') || ''}
                                        className='border-black'
                                    />
                                </Col>
                                <Col lg={3} md={12} sm={24} xs={24}>
                                    <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        {
                            current ?
                            <TableViewCategory {...{ edititem, setEditItem, deleteitem, setDeleteItem, visible, switchtext, setSwitchText, setVisible }}/>
                            :
                            <GridViewCategory {...{ edititem, setEditItem, deleteitem, setDeleteItem, visible, switchtext, setSwitchText, setVisible }} />
                        }
                    </Col>
                    
                </Row>
            </Card>
            <AddEditCategoryDrawer 
                visible={visible}
                edititem={edititem}
                onClose={()=> {setVisible(false);setEditItem(null)}}
            />
            <AlertModal 
                visible={deleteitem}
                switchtext={switchtext}
                onClose={()=>{setDeleteItem(false);setSwitchText(false)}}
            />
        </div>
    )
}

export { CategoryManagement }
