import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography } from "antd"
import { MyInput, MySelect } from "../../Forms"
import { CloseOutlined } from "@ant-design/icons";
import { MultipleProductField } from "../MultipleProductField";

const { Title } = Typography;

const AddEditSupplierDrawer = ({visible, onClose, edititem}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

    const data = [
        {
            pilltext: 'Keratin Cream'
        },
        {
            pilltext: 'Hair Dyes'
        },
        {
            pilltext: 'Waxing'
        },
        {
            pilltext: 'Shampoo'
        },
        {
            pilltext: 'Cleanser'
        },
        {
            pilltext: 'Shaving Cream'
        },
        {
            pilltext: 'Blow Dry'
        },
        {
            pilltext: 'Straightener'
        },
    ]

  return (
    <Drawer
        title={edititem?'Edit supplier':'Add supplier'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={800}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {edititem? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-500">SUPPLIER DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Supplier Name'
                        name='supplierName'
                        placeholder=''
                        required
                        size={'large'}
                        message='Please enter supplier name'
                        value={form.getFieldValue("supplierName") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Contact Number'
                        name='contactnumber'
                        type='number'
                        required
                        size={'large'}
                        message='Please enter contact number'
                        value={form.getFieldValue("contactnumber") || ''}
                    />
                </Col>
                <Col span={24}>
                    <MultipleProductField {...{form}} />
                </Col>
                {
                    edititem && 
                    <Col span={24}>
                        <Space wrap>
                            {
                                data?.map((data,index)=>
                                    <Button className="pill-edit bg-secondary-color brand-color fw-500 fs-13" key={index}>
                                        {
                                            data?.pilltext
                                        }
                                        <CloseOutlined /> 
                                    </Button>
                                )
                            }
                        </Space>
                    </Col>
                }
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditSupplierDrawer}