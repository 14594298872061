import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography, Flex, Select } from "antd"
import { MyDatepicker, MyInput, MyInputnoLabel, MySelect } from "../../Forms"
import { ActionButton } from "../../PageComponents";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { discount } from "../../../shared/lookups";
import { QuantityModal } from "../ReservationsModals/QuantityModal";

const { Title, Text } = Typography;

const AddProduct = ({visible, onClose, editproduct, setVisible,setEditProduct }) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
    const [ quantitymodal, setQuantityModal ] = useState(false)
    const [ titlepro, setTitlePro ] = useState(null)
  

    useEffect(() => {
        if (visible && editproduct) {
            
        } else {
            form.resetFields();
        }
    }, [visible, editproduct])


    const handleChange = (option) => {
        setQuantityModal(true)
        setTitlePro(option.label)
    };



    const options = [
        {
            label:'Facial',
            value:'SAR 100',
            desc:'30 min'
        },
        {
            label:'Cleansing',
            value:'SAR 400',
            desc:'40 min'
        },
        {
            label:'Charcoal Mask',
            value:'SAR 200',
            desc:'20 min'
        },
    ]

    
    const  optionss = [
        {
            label:'Vit C Serum',
            value:'SAR 500',
            desc:'Oragnic Hub',
            emoji:'av-1.png',
        },
        {
            label:'Facial Pack',
            value:'SAR 500',
            desc:'Dermocos',
            emoji:'av-2.jpg',
        },
        {
            label:'Charcoal Face Mask',
            value:'SAR 500',
            desc:'Nivea',
            emoji:'av-3.jpg',
        },
    ]

    const  optionskin = [
        {
            label:'Vit C Serum',
            value:'SAR 500',
            desc:'Oragnic Hub',
            emoji:'av-1.png',
        },
        {
            label:'Skin Pack',
            value:'SAR 500',
            desc:'Dermocos',
            emoji:'av-2.jpg',
        },
        {
            label:'Skin Care',
            value:'SAR 500',
            desc:'Nivea',
            emoji:'av-3.jpg',
        },
    ]

    const  optionnail = [
        {
            label:'Nail Recovery',
            value:'SAR 100',
            desc:'Shop',
            emoji:'nailrecover.webp',
        },
        {
            label:'Intense Care Oil Pen',
            value:'SAR 500',
            desc:'Dermocos',
            emoji:'oilpen.webp',
        },
        {
            label:'Cuticle Trimmer',
            value:'SAR 500',
            desc:'Nivea',
            emoji:'cuticle.webp',
        },
    ]

    const  optionfoot = [
        {
            label:'Foot Care Cream',
            value:'SAR 250',
            desc:'Body Shop',
            emoji:'foot-1.jpg',
        },
        {
            label:'Foot Care Lotion',
            value:'SAR 500',
            desc:'Body Shop',
            emoji:'foot-2.jpg',
        },
        {
            label:'Wooden Foot File',
            value:'SAR 300',
            desc:'Body Shop',
            emoji:'foot-3.jpg',
        },
    ]
    
    const drawerClose = () => {
        onClose()
    }

  return (
    <>
        <Drawer
            title={
                <Flex gap={5}>
                    <ActionButton 
                        icon={<ArrowLeftOutlined />}
                        onClick={() => {setVisible(true);onClose()}}
                        className='border0 bg-transparent'
                    />
                    {
                        editproduct?'Edit products':'Add products'
                    }
                </Flex>
            }
            onClose={drawerClose}
            className="drawer"
            open={visible}
            width={600}
            closeIcon={false}
            footer={
                editproduct &&
                <Space className="w-100 right">
                    <Button 
                        onClick={onClose}
                        className="btncancel"
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        className='btnsave'
                        type="primary"
                        onClick={()=> form.submit() }
                    >
                        Save
                    </Button>
                </Space>
            }
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Title level={5} className="mt-0 mb-3 fw-500">PRODUCTS DETAILS</Title>
                    </Col>
                    {
                        !editproduct ? 
                        <>
                            <Col span={24}>
                                <MyInputnoLabel
                                    name='search'
                                    placeholder='Search by Product Name'
                                    value={form.getFieldValue('search') || ''}
                                    suffix={<img src="/assets/icons/search.png" width={13} />}
                                />               
                            </Col>
                            <Col span={24}>
                                <Form.Item  label={<Text className="fs-13 pt-3 fw-500">HAIR CARE</Text>}>
                                    <Select
                                        className="w-100"
                                        showSearch
                                        onChange={handleChange}
                                        placeholder="Search hair care product"
                                        options={optionss.map(opt => ({
                                            label: (
                                                <Flex className="w-100" align="center" gap={10}>
                                                    <img 
                                                        src={"/assets/images/" + opt.emoji} 
                                                        width={35} 
                                                        height={35} 
                                                        style={{ borderRadius: 50, flexShrink: 0 }} 
                                                    />
                                                    <Flex className="w-100" vertical gap={0}>
                                                        <Flex justify="space-between" className="w-100">
                                                            <Text strong>{opt.label}</Text>
                                                            <Text strong>{opt.value}</Text>
                                                        </Flex>
                                                        <Text className="text-gray fs-13">{opt.desc}</Text>
                                                    </Flex>
                                                </Flex>
                                            ),
                                            value: opt.label,
                                        }))}
                                        size="large"
                                        labelInValue={true}
                                        optionLabelProp="value"
                                    />
                                </Form.Item>                        
                            </Col>
                            <Col span={24}>
                                <Form.Item  label={<Text className="fs-13 fw-500">SKIN CARE</Text>}>
                                    <Select
                                        className="w-100"
                                        showSearch
                                        onChange={handleChange}
                                        placeholder="Search skin care product"
                                        options={optionskin.map(opt => ({
                                            label: (
                                                <Flex className="w-100" align="center" gap={10}>
                                                    <img 
                                                        src={"/assets/images/" + opt.emoji} 
                                                        width={35} 
                                                        height={35} 
                                                        style={{ borderRadius: 50, flexShrink: 0 }} 
                                                    />
                                                    <Flex className="w-100" vertical gap={0}>
                                                        <Flex justify="space-between" className="w-100">
                                                            <Text strong>{opt.label}</Text>
                                                            <Text strong>{opt.value}</Text>
                                                        </Flex>
                                                        <Text className="text-gray fs-13">{opt.desc}</Text>
                                                    </Flex>
                                                </Flex>
                                            ),
                                            value: opt.label,
                                        }))}
                                        size="large"
                                        labelInValue={true}
                                        optionLabelProp="value"
                                    />
                                </Form.Item>                        
                            </Col>
                            <Col span={24}>
                                <Form.Item  label={<Text className="fs-13 fw-500">NAIL CARE</Text>}>
                                    <Select
                                        className="w-100"
                                        showSearch
                                        onChange={handleChange}
                                        placeholder="Search nail care product"
                                        options={optionnail.map(opt => ({
                                            label: (
                                                <Flex className="w-100" align="center" gap={10}>
                                                    <img 
                                                        src={"/assets/images/" + opt.emoji} 
                                                        width={35} 
                                                        height={35} 
                                                        style={{ borderRadius: 50, flexShrink: 0 }} 
                                                    />
                                                    <Flex className="w-100" vertical gap={0}>
                                                        <Flex justify="space-between" className="w-100">
                                                            <Text strong>{opt.label}</Text>
                                                            <Text strong>{opt.value}</Text>
                                                        </Flex>
                                                        <Text className="text-gray fs-13">{opt.desc}</Text>
                                                    </Flex>
                                                </Flex>
                                            ),
                                            value: opt.label,
                                        }))}
                                        size="large"
                                        labelInValue={true}
                                        optionLabelProp="value"
                                    />
                                </Form.Item>                        
                            </Col>
                            <Col span={24}>
                                <Form.Item  label={<Text className="fs-13 fw-500">FOOT CARE</Text>}>
                                    <Select
                                        className="w-100"
                                        showSearch
                                        onChange={handleChange}
                                        placeholder="Search nail care product"
                                        options={optionfoot.map(opt => ({
                                            label: (
                                                <Flex className="w-100" align="center" gap={10}>
                                                    <img 
                                                        src={"/assets/images/" + opt.emoji} 
                                                        width={35} 
                                                        height={35} 
                                                        style={{ borderRadius: 50, flexShrink: 0 }} 
                                                    />
                                                    <Flex className="w-100" vertical gap={0}>
                                                        <Flex justify="space-between" className="w-100">
                                                            <Text strong>{opt.label}</Text>
                                                            <Text strong>{opt.value}</Text>
                                                        </Flex>
                                                        <Text className="text-gray fs-13">{opt.desc}</Text>
                                                    </Flex>
                                                </Flex>
                                            ),
                                            value: opt.label,
                                        }))}
                                        size="large"
                                        labelInValue={true}
                                        optionLabelProp="value"
                                    />
                                </Form.Item>                        
                            </Col>
                        </>
                        :
                        <>
                            <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                <MyInput
                                    label='Product Name'
                                    name='productName'
                                    placeholder='Keratin Cream'                        
                                    size={'large'}
                                    required
                                    message='Please enter product name'
                                    value={form.getFieldValue("productName") || ''}
                                    addonAfter={
                                        <Button className="border0 p-0 bg-transparent" onClick={()=>{setEditProduct(null)}}>
                                            <img src="/assets/icons/ser-ic.png" width={24} />
                                        </Button>
                                    }
                                />
                            </Col> 
                            <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                <MyInput
                                    label='Per Unit Price'
                                    name='perPrice'
                                    type='number'
                                    placeholder='400'                        
                                    size={'large'}
                                    required
                                    message='Please enter per unit price'
                                    value={form.getFieldValue("perPrice") || ''}
                                    addonAfter={<Text className="text-brand fs-13">SAR</Text>}
                                />
                            </Col> 
                            <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                <MyInput
                                    label='Quantity'
                                    name='quantity'
                                    type='number'
                                    placeholder='4'                        
                                    size={'large'}
                                    required
                                    message='Please enter quantity'
                                    value={form.getFieldValue("quantity") || ''}
                                />
                            </Col> 
                            <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                <MyInput
                                    label='Total Price'
                                    name='totalPrice'
                                    type='number'
                                    placeholder='400'                        
                                    size={'large'}
                                    required
                                    message='Please enter totalPrice'
                                    value={form.getFieldValue("totalPrice") || ''}
                                    addonAfter={<Text className="text-brand fs-13">SAR</Text>}
                                />
                            </Col> 
                        </>
                    }
                </Row>
            </Form>
        </Drawer>
        <QuantityModal 
            visible={quantitymodal}
            titlepro={titlepro}
            setVisible={setVisible}
            drawerClose={drawerClose}
            onClose={()=>setQuantityModal(false)}
        />
    </>
  )
}

export {AddProduct}