import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Image, Row, Space, Table, Typography } from 'antd'
import { ActionButton, AddViewSupportDrawer, ModuleTopHeading, MyDatepicker, MyInput, MySelect } from '../../components';

const { Text } = Typography
const SupportHelpdesk = () => {
    const [form] = Form.useForm();
    const [ visible, setVisible ] = useState(false)
    const [ viewonly, setViewOnly ] = useState(null)
    const [ changeinput, setChangeInput ] = useState('Ticket Number')

    const handleChangeFilter = (e) => {
        setChangeInput(e)
    }

    const columns = [
        {
            title: 'Ticket Number',
            dataIndex: 'ticketNumber',
            sorter: (a,b) => a.ticketNumber - b.ticketNumber
        },
        {
            title: 'Category',
            dataIndex: 'category',
            sorter: (a,b) => a.category.localeCompare(b.category),
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            sorter: (a,b) => a.subject.localeCompare(b.subject),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a,b) => a.status - b.status,
            render: (status) => {
                return (
                    status !== 1 ? (
                        <Text className='text-active' style={{transform:"translateY(-2px)",display:'block'}}>Closed</Text>
                    ) : status === 2 ? (
                        <Text className='text-progress' style={{transform:"translateY(-2px)",display:'block'}}>In Progress</Text>
                    ) :  status === 3 ?(
                        <Text className='text-lowin' style={{transform:"translateY(-2px)",display:'block'}}>Reopen</Text>
                    ) : (
                        <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>To Do</Text>
                    )
                );
            },
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            sorter: (a,b) => a.priority - b.priority,
            render: (priority) => {
                return (
                    priority !== 1 ? (
                        <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>High</Text>
                    ) : priority === 2 ? (
                        <Text className='pink-text' style={{transform:"translateY(-2px)",display:'block'}}>Medium</Text>
                    ) : priority === 3 ? (
                        <Text className='text-brand' style={{transform:"translateY(-2px)",display:'block'}}>Low</Text>
                    ) : (
                        <Text style={{transform:"translateY(-2px)",display:'block'}}>Urgent</Text>
                    )
                );
            },
        },
        {
            title: 'Time Stamp',
            dataIndex: 'timeStamp',
            sorter: (a,b) => a.timeStamp.localeCompare(b.timeStamp),
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 130,
            align:'center',
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='View'
                        shape="circle"
                        icon={<Image src='/assets/icons/lighteye.png' width={18} preview={false} />}
                        onClick={() => { setVisible(true); setViewOnly({ticketnumber: row?.ticketNumber})}}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            ticketNumber: '487441',
            category: 'Technical',
            subject: 'Order_017926',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '2',
            ticketNumber: '547441',
            category: 'Technical',
            subject: 'Request for proposal_09372',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '3',
            ticketNumber: '109441',
            category: 'Technical',
            subject: 'Intervention',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '4',
            ticketNumber: '187917',
            category: 'Technical',
            subject: 'Incident on the document',
            timeStamp: '11:02PM 9/18/16',
        },
      ];

    return (
        <div>
            <Card className='shadow-c radius-12 border0 cardlg'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <ModuleTopHeading name='Support & Helpdesk' onClick={()=> setVisible(true)} />                      
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Row gutter={16} align={'middle'}>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MyInput
                                        type='number'
                                        name='ticketName'
                                        label='Ticket Number'
                                        value={form.getFieldValue("ticketName") || ''}
                                        className='w-100 bg-transparent'
                                    />
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MySelect
                                        label= 'Status'
                                        name="status"
                                        value={form.getFieldValue("status") || ''}
                                        onChange={handleChangeFilter}
                                        options={[
                                            {
                                                key: 1,
                                                name: 'Todo',
                                            },
                                            {
                                                key: 2,
                                                name: 'Closed',
                                            },
                                            {
                                                key: 3,
                                                name: 'In progress',
                                            },
                                            {
                                                key: 4,
                                                name: 'Reopen',
                                            }
                                        ]}
                                    />
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MyDatepicker
                                        rangePicker
                                        label="Registration Date"
                                        name='regDate'
                                        value={form.getFieldValue('regDate') || ''}
                                        className='border-black'
                                    />
                                </Col>
                                <Col lg={3} md={12} sm={24} xs={24}>
                                    <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            rowHoverable={false}
                            showSorterTooltip={false}
                            pagination={{
                                hideOnSinglePage: true,
                                position: ['bottomCenter'],
                                total: 12,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                // size: "default",
                                // pageSizeOptions: ['10', '20', '50', '100'],
                                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
            <AddViewSupportDrawer 
                visible={visible}
                viewonly={viewonly}
                onClose={()=> {setVisible(false);setViewOnly(null)}}
            />
        </div>
    )
}

export { SupportHelpdesk }
