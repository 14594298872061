import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Flex } from 'antd'
import { ActionButton } from '../../PageComponents';
const { Text } = Typography
const TableViewExpense = ({ setDeleteItem, setEditItem, setVisible, setDownload }) => {
    const [form] = Form.useForm();

    const columns = [
        {
            title: 'Expense ID',
            dataIndex: 'index',
            sorter: (a,b) => a.index - b.index,
            width:140
        },
        {
            title: 'Expense Name',
            dataIndex: 'expenseName',
            sorter: (a,b) => a.expenseName.localeCompare(b.expenseName)
        },
        {
            title: 'Amount (SAR)',
            dataIndex: 'amount',
            sorter: (a,b) => a.amount.localeCompare(b.amount)
        },
        {
            title: 'Time Stamp',
            dataIndex: 'timeStamp',
            sorter: (a,b) => a.timeStamp.localeCompare(b.timeStamp)
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 150,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Edit'
                        shape="circle"
                        icon={<Image src='/assets/icons/edit.png' width={18} preview={false} />}
                        onClick={() => { setVisible(true); setEditItem({name: row?.expenseName})}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Download'
                        shape="circle"
                        icon={<Image src='/assets/icons/download-b.png' width={18} preview={false} />}
                        onClick={() => { setDownload({name: row?.expenseName})}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={18} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.expenseName}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            index:'#1',
            expenseName: 'Water Bill',
            amount: '200',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '2',
            index:'#2',
            expenseName: 'Gas Bill',
            amount: '500',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '3',
            index:'#3',
            expenseName: 'Internet Bill',
            amount: '200',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '4',
            index:'#4',
            expenseName: 'Fan Repair',
            amount: '100',
            timeStamp: '11:02PM 9/18/16',
        },
      ];

  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    pagination={{
                        hideOnSinglePage: true,
                        position: ['bottomCenter'],
                        total: 12,
                        // pageSize: pagination?.pageSize,
                        // defaultPageSize: pagination?.pageSize,
                        // current: pagination?.pageNo,
                        // size: "default",
                        // pageSizeOptions: ['10', '20', '50', '100'],
                        // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                        showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                    }}
                />
            </Col>
        </Row>
    </div>
  )
}

export {TableViewExpense}