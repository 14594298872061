import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Image, Row, Space, Table, Typography } from 'antd'
import { AlertModal, ModuleTopHeading, MyInput, ActionButton, EditroleDrawer } from '../../components';

const { Text } = Typography
const RoleAndPermissionPage = () => {
    const [form] = Form.useForm();
    const [ visible, setVisible ] = useState(false)
    const [ edititem, setEditItem ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState(false)

    const columns = [
        {
            title: 'Employee ID',
            dataIndex: 'id',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Employee Name',
            dataIndex: 'empName',
            sorter: (a, b) => a.empName.localeCompare(b.empName),
        },
        {
            title: 'Role',
            dataIndex: 'role',
            sorter: (a, b) => a.role.localeCompare(b.role),
        },
        {
            title: <Text>Actions</Text>,
            key: 'action',
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Edit role'
                        shape="circle"
                        icon={<Image src='/assets/icons/edit.png' width={16} preview={false} />}
                        onClick={() => { setVisible(true); setEditItem({ name: row?.role }) }}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete role'
                        icon={<Image src='/assets/icons/delete.png' width={20} preview={false} />}
                        onClick={() => { setDeleteItem({ name: row?.role }) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];
    

    const data = [
        {
            key: '1',
            id: 1,
            empName: 'M.Ali',
            role: 'Data Entry',
        },
        {
            key: '2',
            id: 2,
            empName: 'Saad',
            role: 'Data Analyst',
        },
        {
            key: '3',
            id: 3,
            empName: 'Ahmed',
            role: 'Admin',
        },
        {
            key: '4',
            id: 4,
            empName: 'Zubair',
            role: 'Admin',
        },
        {
            key: '5',
            id: 5,
            empName: 'Danish',
            role: 'Admin',
        },
        {
            key: '6',
            id: 6,
            empName: 'Bilal',
            role: 'Data Entry',
        },
        {
            key: '7',
            id: 7,
            empName: 'Zahid',
            role: 'Data Analyst',
        },
        {
            key: '8',
            id: 8,
            empName: 'Sher Ali',
            role: 'Admin',
        },
        {
            key: '9',
            id: 9,
            empName: 'Hassan',
            role: 'Data Entry',
        },
        {
            key: '10',
            id: 10,
            empName: 'Hussain',
            role: 'Admin',
        },
    ];
    

    return (
        <div>
            <Card className='shadow-c radius-12 border0 cardlg'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <ModuleTopHeading name='Roles & Permissions' />                  
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Row gutter={16} align={'middle'}>
                                <Col xl={4} lg={6} md={24} sm={24} xs={24}>
                                    <MyInput
                                        name='name'
                                        label='Rolle Name'
                                        placeholder='e.g Supervisor'
                                        value={form.getFieldValue("name") || ''} 
                                        className='w-100 bg-transparent'
                                    />
                                </Col>
                                <Col lg={3} md={12} sm={24} xs={24}>
                                    <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            className='table-dark pagination'
                            rowHoverable={false}
                            showSorterTooltip={false}
                            pagination={{
                                hideOnSinglePage: true,
                                position: ['bottomCenter'],
                                total: 12,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                // size: "default",
                                // pageSizeOptions: ['10', '20', '50', '100'],
                                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
            <EditroleDrawer
                visible={visible}
                edititem={edititem}
                onClose={()=> {setVisible(false);setEditItem(null)}}
            />
            <AlertModal 
                visible={deleteitem}
                deleteitem={deleteitem}
                onClose={()=>{setDeleteItem(false)}}
            />
        </div>
    )
}

export { RoleAndPermissionPage }
