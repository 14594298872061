import React, { useState } from 'react';
import { Card, Dropdown, Form, Flex, Button, Space, Typography } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { DownOutlined } from '@ant-design/icons';
import { ModuleTopHeading } from '../../PageComponents';
import { MyInput } from '../../Forms';

const { Title } = Typography

const ExpenseLineChart = () => {
  const [order, setOrder] = useState(0);
  const [form] = Form.useForm();
  const items = [
    { label: <a href="#">Monthly</a>, key: 0 },
    { label: <a href="#">Quarterly</a>, key: 1 },
    { label: <a href="#">Yearly</a>, key: 2 },
  ];

  const onClick = ({ key }) => {
    // key = parseInt(key) + 1;
    setOrder(key);
    // filter(key);
  };

  const chartData = {
    series: [
      {
        name: 'Data Series',
        data: [10, 14, 16, 17, 20, 22, 25, 10, 18, 14],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 200,
        toolbar:{
          show: false,
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
        ],
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      yaxis: {
        min: 0,
        max: 25,
        tickAmount: 5,
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        show: false,
      },
      colors: ['#62B2FD'],
      legend: {
        show: false,
      },
    },
  };

  return (
    <div>
      <Card className='shadow-c border0 radius-12'>
        <Flex justify='space-between' align='center' wrap gap={10}>
          <ModuleTopHeading name='Expense Tracking' />
          <Form form={form} layout="vertical">
            <Flex justify='end' gap={10}>
              <Dropdown
                menu={{ items, onClick }}
                trigger={['click']}
                className='margin-top'
              >
                <Button className='btnsave fs-13 border-brand pad-filter text-brand'>
                  <Space>
                    {
                      order == 0 ? 'Monthly' :
                      order == 1 ? 'Quarterly' :
                      order == 2 ? 'Yearly' : ''
                    }  
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Flex>
          </Form>
        </Flex>
        <div id="chart">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={200}
          />
        </div>
      </Card>
    </div>
  );
};

export { ExpenseLineChart };
