import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Avatar, Switch, Flex } from 'antd'
import { ActionButton } from '../../PageComponents';


const { Text } = Typography


const TableViewEmployees = ({ setDeleteItem, setEditItem, setVisible, setSwitchText, setActivityLog, setActivityId, setLeaveManage }) => {
    const [form] = Form.useForm();

    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        setDeleteItem(true)
        setSwitchText(true)
    };

    const columns = [
        {
            title: 'Employee ID',
            dataIndex: 'index',
            sorter: (a,b) => a.index - b.index,
            width:150
        },
        {
            title: 'Employee Name',
            dataIndex: 'employeeName',
            sorter: (a,b) => a.employeeName - b.employeeName,
        },
        {
            title: 'Contact Number',
            dataIndex: 'contactNumber',
            sorter: (a,b) => a.contactNumber.localeCompare(b.contactNumber)
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width:200,
            render: (status) => {
                return (
                    status !== 1 ? (
                        <Space align='center'>
                            <img src="/assets/icons/online.png" width={'8px'} alt="" />
                            <Text className='text-active' style={{transform:"translateY(-2px)",display:'block'}}>Active</Text>
                        </Space>
                    ) : (
                        <Space>
                            <img src="/assets/icons/offline.png" width={'8px'} alt="" />
                            <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>Inactive</Text>
                        </Space>
                    )
                );
            },
        },
        {
            title: 'Time Stamp',
            dataIndex: 'timeStamp',
            sorter: (a,b) => a.timeStamp.localeCompare(b.timeStamp)
        },
        {
            title: <Text>Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 170,
            render: (_, row) => (
                <Space>
                    <Switch size='small' defaultChecked onChange={onChange} />
                    <ActionButton
                        title='Edit employee'
                        shape="circle"
                        icon={<Image src='/assets/icons/edit.png' width={18} preview={false} />}
                        onClick={() => { setVisible(true); setEditItem({name: row?.employeeName})}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Activity log'
                        shape="circle"
                        icon={<Image src='/assets/icons/activitylog.png' width={18} preview={false} />}
                        onClick={() => { setActivityLog(true); setActivityId(row?.id)}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Leave management'
                        shape="circle"
                        icon={<Image src='/assets/icons/leavemanage.png' width={18} preview={false} />}
                        onClick={() => { setLeaveManage(true)}}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            index:'#11',
            employeeName:
                <Space>
                    <Avatar
                        size={30}
                        icon={<img src="/assets/images/pro-1.png" />}
                    />
                    Sajid Hussain
                </Space>,
            contactNumber: '+966 120 223 087',
            timeStamp: '07:02PM 9/18/16',
        },
        {
            key: '2',
            index:'#12',
            employeeName:
                <Space>
                    <Avatar
                        size={30}
                        icon={<img src="/assets/images/av-1.png" />}
                    />
                    Zuhair Hafeez
                </Space>,
            contactNumber: '+966 310 133 087',
            timeStamp: '08:02PM 9/18/16',
        },
        {
            key: '3',
            index:'#13',
            employeeName:
                <Space>
                    <Avatar
                        size={30}
                        icon={<img src="/assets/images/pro-1.png" />}
                    />
                    Sidra Amin
                </Space>,
            contactNumber: '+966 330 443 087',
            timeStamp: '09:02PM 9/18/16',
        },
        {
            key: '4',
            index:'#14',
            employeeName:
                <Space>
                    <Avatar
                        size={30}
                        icon={<img src="/assets/images/av-1.png" />}
                    />
                    Fayez Ali
                </Space>,
            contactNumber: '+966 210 789 007',
            timeStamp: '10:02PM 9/18/16',
        },
      ];

  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    showSorterTooltip={false}
                    pagination={{
                        hideOnSinglePage: true,
                        position: ['bottomCenter'],
                        total: 12,
                        // pageSize: pagination?.pageSize,
                        // defaultPageSize: pagination?.pageSize,
                        // current: pagination?.pageNo,
                        // size: "default",
                        // pageSizeOptions: ['10', '20', '50', '100'],
                        // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                        showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                    }}
                />
            </Col>
        </Row>
    </div>
  )
}

export {TableViewEmployees}