import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Typography, Table } from "antd"
import { MyInput, MySelect } from "../../Forms"

const { Title, Text } = Typography;

const ViewProductCategoryDrawer = ({visible, onClose, viewonly,}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && viewonly) {
            
        } else {
            form.resetFields();
        }
    }, [visible, viewonly])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

    const columns = [
        {
            title: 'Products',
            dataIndex: 'product',
            sorter: (a,b) => a.product - b.product,
        },
        {
            title: 'Counted By',
            dataIndex: 'countedby',
            sorter: (a,b) => a.countedby.localeCompare(b.countedby),
        },
        {
            title: 'Date & Time',
            dataIndex: 'date',
            sorter: (a,b) => a.date.localeCompare(b.date),
        },
        {
            title: 'Total Quantity',
            dataIndex: 'totalquantity',
            sorter: (a,b) => a.totalquantity.localeCompare(b.totalquantity),
        },
        {
            title: 'Recorded quantity ',
            dataIndex: 'recordedquantity',
            sorter: (a,b) => a.recordedquantity.localeCompare(b.recordedquantity),
        },
        {
            title: 'Difference',
            dataIndex: 'difference',
            sorter: (a,b) => a.difference.localeCompare(b.difference),
        },
    ];

    const data = [
        {
            key: '1',
            product: 'Dryer',
            countedby: 'Zuhair Hafeez',
            date: '12:03 11/02/2024',
            totalquantity: '10',
            recordedquantity: '5',
            difference: '-5',
        },
        {
            key: '2',
            product: 'Straightener',
            countedby: 'Fayez Ali',
            date: '11:03 12/02/2024',
            totalquantity: '20',
            recordedquantity: '10',
            difference: '-10',
        },
        {
            key: '3',
            product: 'Loreal Shampoo',
            countedby: 'Mohammed Darwish',
            date: '01:03 11/02/2024',
            totalquantity: '100',
            recordedquantity: '89',
            difference: '-11',
        },
        {
            key: '4',
            product: 'Dove Soap',
            countedby: 'Jihad Bakir',
            date: '02:03 01/02/2024',
            totalquantity: '100',
            recordedquantity: '100',
            difference: '0',
        },
        {
            key: '5',
            product: 'Dryer 01',
            countedby: 'Sajid Hussain',
            date: '11:03 11/03/2024',
            totalquantity: '10',
            recordedquantity: '5',
            difference: '-5',
        },
        {
            key: '6',
            product: 'Straightener 01',
            countedby: 'Nadeem Abbas',
            date: '11:03 11/12/2024',
            totalquantity: '20',
            recordedquantity: '10',
            difference: '-10',
        },
        {
            key: '7',
            product: 'Loreal Shampoo 01',
            countedby: 'Ziyad Abdullah',
            date: '11:03 11/12/2024',
            totalquantity: '100',
            recordedquantity: '89',
            difference: '-11',
        },
        {
            key: '8',
            product: 'Dove Soap 01',
            countedby: 'Salim Al Tajir',
            date: '11:03 11/12/2024',
            totalquantity: '100',
            recordedquantity: '100',
            difference: '0',
        },

        {
            key: '9',
            product: 'Dryer 02',
            countedby: 'Salim Al Tajir',
            date: '11:03 11/12/2024',
            totalquantity: '10',
            recordedquantity: '5',
            difference: '-5',
        },
        {
            key: '10',
            product: 'Straightener 02',
            countedby: 'Salim Al Tajir',
            date: '11:03 11/12/2024',
            totalquantity: '20',
            recordedquantity: '10',
            difference: '-10',
        },
      ];

  return (
    <Drawer
        title={<>
                {viewonly?.name}  
                {/* <Text className="pill-cs bg-light-green text-active ">{viewonly?.status}</Text> */}
            </>
        }
        onClose={closeDrawer}
        open={visible}
        width={1000}
        footer={false}
    >
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Text>This category consists of ---------</Text>
            </Col>
            <Col span={24}>
                <Title level={5} className="m-0">Product Summary</Title>
            </Col>
            <Col span={24}>
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Row gutter={16} align={'middle'}>
                        <Col lg={5} md={12} sm={24} xs={24}>
                            <MyInput
                                name='name'
                                label='Product Name'
                                value={form.getFieldValue("name") || ''}
                                className='w-100 bg-transparent'
                            />
                        </Col>
                        <Col lg={3} md={12} sm={24} xs={24}>
                            <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 700}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    showSorterTooltip={false}
                    pagination={{
                        hideOnSinglePage: true,
                        total: 12,
                        // pageSize: pagination?.pageSize,
                        // defaultPageSize: pagination?.pageSize,
                        // current: pagination?.pageNo,
                        // size: "default",
                        // pageSizeOptions: ['10', '20', '50', '100'],
                        // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                        showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                    }}
                />
            </Col>
        </Row>
    </Drawer>
  )
}

export {ViewProductCategoryDrawer}