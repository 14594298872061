import React, { useState } from "react"
import { Form, Table, Typography, Row, Col, Flex, Divider, Card, Button} from "antd"
import { MyInput } from "../../Forms"

const { Title, Text } = Typography

const ViewDetailReservation = ({setPaidId}) => {
  
    const [form] = Form.useForm()
    const [ customshow, setCustomShow ] = useState(false)  
    const [ checked, setChecked ] = useState(null)
    const [ checkedb, setCheckedB ] = useState(null)
  

    const data = {
        service:[
            {
                title:'Reservation Number',
                value: '00008'
            },
            {
                title:'Transaction Date',
                value: '25-02-2023, 13:22:16'
            },
            {
                title:'Payment Method',
                value: 'Salim Ahmed'
            },
            {
                title:'Sender Name',
                value: 'Salim Ahmed'
            },
        ],
        total:[
            {
                title:'Sub Total',
                value: 'SAR 35,000'
            },
            {
                title:'Tip',
                value: 'SAR 50'
            },
            {
                title:'Online Booking Fee',
                value: '- SAR 5'
            },
            {
                title:'Tax',
                value: '- SAR 100'
            },
        ]
    }
    
    
    const columns = [
        {
            title: 'Product',
            dataIndex: 'product',
            width:150
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
        },
        {
            title: 'Amount (SAR)',
            dataIndex: 'amount',
            align:'end'
        },
    ];

    const datas = [
        {
            key: '1',
            product: <Text>Keratin Cream</Text>,
            quantity: <Text>1</Text>,
            amount: <Text>10,000</Text>,
        },
        {
            key: '2',
            product: <Text>Facial Package</Text>,
            quantity: <Text>1</Text>,
            amount: <Text>10,000</Text>,
        },
        {
            key: '3',
            product: <Text>Gold Serum</Text>,
            quantity: <Text>2</Text>,
            amount: <Text>5,000</Text>,
        },
      ];

      const columnser = [
        {
            title: 'Service',
            dataIndex: 'service',
            width:150
        },
        {
            title: 'With',
            dataIndex: 'with',
        },
        {
            title: 'Price (SAR)',
            dataIndex: 'price',
            align:'end'
        },
    ];

      const dataservice = [
        {
            key: '1',
            service: <Text>Hair Dye</Text>,
            with: <Text>M. Daud</Text>,
            price: <Text>10,000</Text>,
        },
        {
            key: '2',
            service: <Text>Hair Wash</Text>,
            with: <Text>M. Daud</Text>,
            price: <Text>5,000</Text>,
        },
        {
            key: '3',
            service: <Text>Blow Dry</Text>,
            with: <Text>M. Daud</Text>,
            price: <Text>2,000</Text>,
        },
      ];

      const checkpoint = [
            {
                checkpointA: [
                    {
                        title:'SAR 10'
                    },
                    {
                        title:'SAR 15'
                    },
                    {
                        title:'SAR 20'
                    },
                    {
                        title:'SAR 25'
                    },
                    {
                        title:'SAR 30'
                    },
                    {
                        title:'Custom',
                        id:'custom'
                    },
                ]
            },
            {
                checkpointB: [
                    {
                        title:'Cash',
                    },
                    {
                        title:'Online',
                        id:'online',
                    },
                    {
                        title:'Credit Card',
                    },
                ]
            },
        ]

    
  return (
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Card className="border-brand card-cs bg-brand-light radius-12" >
                    <Flex vertical gap={3}>
                        <Text className="fs-14 fw-500">Mohamaad Ali</Text>
                        <Text className="fs-14 text-gray">+966 654 765 456</Text>
                    </Flex>
                </Card>
            </Col>
            <Col span={24}>
                <div className="dash-btm-border"></div>
            </Col>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columnser} 
                    dataSource={dataservice} 
                    className='transtable pagination'
                    rowHoverable={false}
                    pagination={false}
                />
                <Flex justify="space-between" className="margin-5">
                    <Text strong>
                        Total
                    </Text>
                    <Text strong>
                        SAR 17000
                    </Text>
                </Flex>
            </Col>
            <Col span={24}>
                <div className="dash-btm-border"></div>
            </Col>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={datas} 
                    className='transtable pagination'
                    rowHoverable={false}
                    pagination={false}
                />
                <Flex justify="space-between" className="margin-5">
                    <Text strong>
                        Total
                    </Text>
                    <Text strong>
                        SAR 9000
                    </Text>
                </Flex>
            </Col>
            <Col span={24}>
                <MyInput
                    label='Discount'
                    name='discount'
                    placeholder=''                        
                    size={'large'}
                    value={form.getFieldValue("discount") || ''}
                    addonAfter={<Text className="text-brand fs-13">%</Text>}
                />
            </Col>
            <Col span={24}>
                <Title level={5} className="m-0 fw-500">ADD TIP AMOUNT (optinal)</Title>
            </Col>
            <Col span={24}>
                <Flex wrap gap={10}>
                    {
                        checkpoint[0]?.checkpointA?.map((data,index)=>
                            <Button className=
                                {
                                    checked === index ?
                                    "btnsave text-white radius-30 badge-pill fs-13 fw-400 brand-bg":
                                    "btnsave text-brand radius-30 badge-pill fs-13 fw-400 bg-brand-light"
                                } 
                                key={index}
                                onClick={()=> {setChecked(index); data.id === 'custom' ? setCustomShow(true): setCustomShow(false)}}
                            >
                                {
                                    data?.title
                                }
                            </Button>
                        )
                    }
                </Flex>
            </Col>
            {
                customshow &&
                <Col span={24}>
                    <MyInput
                        label='Tip Amount'
                        name='tipAmount'
                        placeholder=''                        
                        size={'large'}
                        required
                        message='Please enter tip amount'
                        value={form.getFieldValue("tipAmount") || ''}
                        addonAfter={<Text className="text-brand fs-13">SAR</Text>}
                    />
                </Col>
            }
            <Col span={24}>
                <Title level={5} className="m-0 fw-500">PAYMENT METHOD</Title>
            </Col>
            <Col span={24}>
                <Flex wrap gap={10}>
                    {
                        checkpoint[1]?.checkpointB?.map((data,index)=>
                            <Button className=
                                {
                                    checkedb === index ?
                                    "btnsave text-white radius-30 badge-pill fs-13 fw-400 brand-bg":
                                    "btnsave text-brand radius-30 badge-pill fs-13 fw-400 bg-brand-light"
                                } 
                                key={index}
                                onClick={()=> {setCheckedB(index);setPaidId(true)}}
                            >
                                {
                                    data?.title
                                }
                            </Button>
                        )
                    }
                </Flex>
            </Col>
            <Col span={24}>
                <div className="dash-btm-border"></div>
            </Col>
            <Col span={24}>
                {
                    data?.total?.map((total,index)=>
                        <div key={index}>
                            <Flex justify="space-between" className="mb-2">
                                <Text className="fs-14">
                                    {total?.title}
                                </Text>
                                <Text className=
                                    {
                                        total.value < 0 + '-' ? "fs-13 fw-500 text-inactive" : "fs-13 fw-500 text-active"
                                    }
                                >
                                    {total?.value}
                                </Text>
                            </Flex>
                            <Divider className="m-0" /> 
                        </div>
                    )
                }
            </Col>
        </Row>
  )
}

export {ViewDetailReservation}