import React from 'react'
import { Button, Card, Col, Form, Image, Row, Space, Table } from 'antd'
import { ModuleTopHeading, MySelect } from '../../components';


const ProfilLoss = () => {
    const [form] = Form.useForm();

    const columns = [
        {
            title: 'Profit & Loss',
            dataIndex: 'profit',
            sorter: (a,b) => a.profit.localeCompare(b.profit),
            width: 200
        },
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: (a,b) => a.description.localeCompare(b.description),
            width: 200
        },
    ];

    const data = [
        {
            key: '1',
            profit:'Total Revenue',
            description: 'SAR 10,000',
        },
        {
            key: '2',
            profit:'Total Expense',
            description: 'SAR 5000',
        },
        {
            key: '3',
            profit:'Net Profit & Loss',
            description: 'SAR 5000',
        },
        {
            key: '4',
            profit:'Time Period',
            description: '10/02/2023 - 10/03/2024',
        },
      ];

    return (
        <div>
            <Card className='shadow-c radius-12 border0 cardlg'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <ModuleTopHeading name='Profit & Loss Report' />                        
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Row gutter={16} align={'middle'}>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MySelect
                                        label= 'Filter'
                                        name="filter"
                                        value={form.getFieldValue("filter") || ''}
                                        options={[
                                            {
                                                key: 1,
                                                name:'Last month'
                                            },
                                            {
                                                key: 2,
                                                name:'Last quarter'
                                            },
                                            {
                                                key: 3,
                                                name:'Last year'
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col lg={8} md={12} sm={24} xs={12}>
                                    <Space className='w-100'>
                                        <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                                        <Button className='btnsave pad-filter bg-secondary-color text-brand border0 margin-5'>
                                            <Image src='/assets/icons/file.png' preview={false} width={22} />
                                            Export
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            rowHoverable={false}
                            showSorterTooltip={false}
                            pagination={{
                                hideOnSinglePage: true,
                                position: ['bottomCenter'],
                                total: 12,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                // size: "default",
                                // pageSizeOptions: ['10', '20', '50', '100'],
                                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export { ProfilLoss }
