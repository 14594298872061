import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography, Upload } from "antd"
import { MyInput, MySelect, MyDatepicker } from "../../../Forms"

const { Title } = Typography;

const AddEditLeaveDrawer = ({visible, onClose, edititem }) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const closeDrawer= ()=>{
        form.resetFields()
        onClose()
    }

      


  return (
    <Drawer
        title={edititem?'Edit leave':'Add leave'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={800}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    onClick={()=> form.submit()}
                >
                    {
                        edititem?'Update' : 'Save'
                    }
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-500">LEAVE DETAILS</Title>
                </Col>
                <Col span={24}>
                    <MySelect
                        label= 'Leave Type'
                        name="levType" 
                        size={'large'}
                        required
                        message='Please choose leave type'
                        value={form.getFieldValue("levType") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Annual leave'
                            },
                            {
                                key: 2,
                                name: 'Short leave'
                            },
                        ]}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyDatepicker
                        datePicker
                        name="start"
                        label="Start"
                        size={'large'}
                        required
                        message='Please enter start'
                        value={form.getFieldValue("start") || ''}
                        className='border-gray'
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyDatepicker
                        datePicker
                        name="end"
                        label="End"
                        size={'large'}
                        required
                        message='Please enter end'
                        value={form.getFieldValue("end") || ''}
                        className='border-gray'
                    />
                </Col>
                <Col span={24}>
                    <MyInput
                        textarea
                        label='Note (optional)'
                        name='note'
                        size={'large'}
                        value={form.getFieldValue("note") || ''}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditLeaveDrawer}