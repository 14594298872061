import React from 'react'
import { Col, Row } from 'antd'
import { 
  CardComponent, 
  ReservationLinechart, 
  StaffSessionChart,
  SalesTrackingChart,
  ExpenseLineChart,
  RevenueLineChart,
} from '../../components'

const Dashboard = () => {
  return (
    <Row gutter={[16,16]}>
        <Col span={24}>
          <CardComponent />
        </Col>
        <Col span={24}>
          <ReservationLinechart />
        </Col>
        <Col span={24}>
          <StaffSessionChart />
        </Col>
        <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
          <ExpenseLineChart />
        </Col>
        <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
          <RevenueLineChart />
        </Col>
    </Row>
  )
}

export {Dashboard}