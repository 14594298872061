import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Upload, Typography, Image, message, Flex, Radio} from "antd"
import { MyDatepicker, MyInput, MySelect } from "../../Forms"
import { DeleteOutlined } from "@ant-design/icons";
import { ActionButton } from "../../PageComponents";

const { Title, Text } = Typography;
const { Dragger } = Upload;

const AddEditExpenseDrawer = ({visible, onClose, edititem,}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

    const props = {
        name: 'file',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };

  return (
    <Drawer
        title={edititem?'Edit expense':'Add expense'}
        onClose={closeDrawer}
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {edititem? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0">EXPENSE DETAILS</Title>
                </Col>
                <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item name='contract' >
                        <div className='width-drag'>
                            <Dragger {...props} className="dragcs">
                                <p className="ant-upload-drag-icon">
                                    <Image src='/assets/icons/plus.png' width={'41.6px'} preview={false} />
                                </p>
                                <Typography.Text className="fs-13 text-gray">Upload bill</Typography.Text>
                            </Dragger>
                        </div>
                        {
                            edititem &&
                            <Space direction='vertical' style={{width:'50%'}} size={10}>
                                {/* {
                                    geteditdata?.medical_report?.map((data,index)=>
                                        <div className='viewDownload space-between-align' key={index}>
                                            <Typography.Text className='gray-text fs-12'>
                                                {
                                                    data?.name
                                                }
                                            </Typography.Text>
                                            <ActionButton
                                                title='Delete'
                                                icon={<DeleteOutlined className='danger-color' />}
                                                onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                                                type='ghost'
                                            />
                                        </div>
                                    )
                                } */}
                                <div className='viewDownload space-between-align'>
                                    <Typography.Text className='fs-12'>
                                        Fan repair bill.jpg
                                    </Typography.Text>
                                    <ActionButton
                                        title='Delete'
                                        icon={<DeleteOutlined className='danger-color' />}
                                        // onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                                        type='ghost'
                                    />
                                </div>
                            </Space>
                            
                        }
                    </Form.Item>
                    
                </Col>
                <Col span={24}>
                    <Flex vertical gap={10}>
                        <Text className='fs-13'>Expense</Text>
                        <Radio.Group >
                            <Space className="mb-3" direction="vertical">
                                <Radio value={1} className="fs-13">Static</Radio>
                                <Radio value={2} className="fs-13">Dynamic</Radio>
                            </Space>
                        </Radio.Group>
                    </Flex>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Expense Name'
                        name='expenseName'
                        placeholder='e.g Fan repair'
                        size='large'
                        required
                        message='Please enter expense name'
                        value={form.getFieldValue("expenseName") || ''}
                    />
                </Col> 
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Expense Amount'
                        type='number'
                        name='expenseAmount'
                        placeholder='e.g 10000'
                        size='large'
                        addonAfter={<Text className="text-brand fs-13">SAR</Text>}
                        required
                        message='Please enter expense amount'
                        value={form.getFieldValue("expenseAmount") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyDatepicker
                        datePicker
                        label='Expense Due-Date'
                        name='expenseDate'
                        size='large'
                        required
                        message='Please enter expense date'
                        value={form.getFieldValue("expenseDate") || ''}
                        className='border-gray'
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Expense Type'
                        name="expenseType"
                        size='large'
                        value={form.getFieldValue("expenseType") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Monthly',
                            },
                            {
                                key: 2,
                                name: 'Quarterly',
                            },
                            {
                                key: 2,
                                name: 'Semi-annually',
                            },
                            {
                                key: 2,
                                name: 'Annually',
                            },
                        ]}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditExpenseDrawer}