import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Skeleton, Tooltip, Flex } from 'antd'

const { useToken } = theme;
const { Title, Text } = Typography
const PayrollCards = () => {
    const { token } = useToken();
    const [ skeletonactive, setSkeletonActive] = useState(false)

    // setTimeout(() => {
    //     setSkeletonActive(false)
    // }, 2000);

    const data = [
        {
            title:'SAR 344250',
            subtitle:'Monthly Payroll',
            desc:'No. of Employees: 150',
            img:'mpayroll.png',
            key:'blue'
        },
        {
            title:'SAR 344250',
            subtitle:'Monthly Paid Payroll',
            desc:'No. of Employees: 140',
            img:'mpaidpayroll.png',
            key:'green'
        },
        {
            title:'- SAR 344250',
            subtitle:'Monthly Unpaid Payroll',
            desc:'No. of Employees: 10',
            img:'munpaid.png',
            key: 'pink'
        },
    ]
  return (
    <div>
        <Row gutter={[16,16]}>
            {
                data?.map((cdata,c)=>
                    <Col xs={24} sm={24} md={12} lg={8} key={c}>
                        {
                            skeletonactive?
                                <Skeleton.Button
                                    active
                                    size='large' 
                                    shape='square'
                                    block
                                    style={{width:'100%', height:'114px',borderRadius:20}}
                                />
                                
                                :
                                <Card style={{
                                    borderColor: token.borderCardcolor,
                                    width:"100%"}}
                                    className={
                                        
                                        cdata?.key === 'blue' ? 'card-cs border-btm-blue shadow-c height-100' :
                                        cdata?.key === 'green' ? 'card-cs border-btm-green shadow-c height-100' :
                                        cdata?.key === 'pink' ? 'card-cs border-btm-pink shadow-c height-100':
                                        'card-cs border0 shadow-c height-100'
                                    }
                                >
                                    <Row justify='space-between' align='middle'>
                                        <Col lg={14} md={14} xs={18} sm={18}>
                                            <div className='my-2'>  
                                                <img src={'/assets/icons/'+cdata?.img} width={'50px'} 
                                                    className=
                                                    {
                                                        cdata?.key === 'blue' ? 'bg-blue img-bg' :
                                                        cdata?.key === 'green' ? 'bg-light-green img-bg' : 
                                                        cdata?.key === 'pink' ? 'bg-pink img-bg' : 'img-bg'
                                                    }

                                                />                              
                                                <Title className='m-0 fs-14 fw-500' level={5}>{cdata?.subtitle}</Title>
                                            </div>
                                        </Col>
                                        <Col lg={10} md={10} xs={6} sm={6}>
                                            <Flex vertical justify='end' align='end' gap={4}>
                                                <Text className='text-gray fs-13'>No. of Employees: <strong>150</strong></Text>
                                                <Title level={4} className='my-0'>
                                                    {cdata?.title}
                                                </Title> 
                                            </Flex>  
                                        </Col>
                                    </Row>
                                </Card> 
                                }  
                    </Col>
                )
            }
        </Row>
    </div>
  )
}

export {PayrollCards}