import React, { useState } from 'react'
import { Modal, Space, Typography, Image } from 'antd'
const { Text } = Typography
const ViewReviewModal = ({visible,onClose,viewonly}) => {

    
  return (
    <div>
        <Modal width={500} className='shadow-c'  open={visible} onOk={onClose} onCancel={onClose} centered footer={null}>
            <Space className='w-100 py-2 text-center' align='center' size={10} direction='vertical'>
                <Image src='/assets/icons/reviews-ic.png' width={50} preview={false} />
                <Text strong className='text-center'>
                    {
                        viewonly?.name
                    }
                </Text>
                <Text className='text-center'>
                    “The services are amazing, truly recommended”
                </Text>
            </Space>
        </Modal>
    </div>
  )
}

export {ViewReviewModal}