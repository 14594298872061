import React from 'react';
import ReactApexChart from 'react-apexcharts';
const HalfCreditChart = () => {

    const value = 70
    const chartData = {
        series: [value, 100 - value],
        options: {
          chart: {
            type: 'donut',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          
          plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 90,
              offsetY: 10,
              donut: {
                size: '65%',
                labels: {
                  show: true,
                  name: {
                    show: true,
                  },
                  value: {
                    show: false,
                  },
                  total: {
                    show: true,
                    label: 'SAR 10,000',
                    fontSize: '15px',
                    fontWeight: 600,
                    color: '#674292',
                  }
                }
              }
            }
          },
          fill: {
            opacity: 1,
          },
          grid: {
            show: false,
          },
          colors: ['#F99BAB','#393B63'],
          legend: {
            show: false,
          },
        },
      };
    
  

  return (
    <div>
        <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="donut"
            width={'100%'}
            height={350}
        />
    </div>
  );
};

export { HalfCreditChart };
