import React, { useState } from 'react'
import { Modal, Space, Typography, Button, Image, Col, Row, Form, Flex } from 'antd'
import { apiCalls } from '../../../shared/Apis';
import { InputNolabel, MyInput, MySelect } from '../../Forms';
const { Title } = Typography
const RecordedQuantityModal = ({visible,onClose}) => {
    
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)


  return (
    <div>
        <Modal width={500} className='shadow-c'  open={visible} onOk={onClose} onCancel={onClose} centered footer={null}>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Flex justify='center' className='pb-1'>
                            <Image src='/assets/icons/recorded-ic.png' width={50} preview={false} />
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <Flex justify='center' className='pb-1'>
                            <Title level={5} className='my-0'>Recorded Stock Quantity</Title>
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <MyInput
                            label='Total Recorded Quantity'
                            name='totalrecorded'
                            placeholder='e.g Lorem ipsum'
                            size={'large'}
                            required
                            message='Please enter total recorded quantity'
                            value={form.getFieldValue("totalrecorded") || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MySelect
                            label= 'Counted By'
                            name="counted" 
                            size={'large'}
                            required
                            message='Please choose counted by'
                            value={form.getFieldValue("counted") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Employee name'
                                },
                            ]}
                        />
                    </Col>
                    <Col span={24}>
                        <Space style={{justifyContent:"center",marginTop:'15px'}} className="w-100">
                            <Button
                                onClick={onClose}
                                className='btncancel'
                            >
                                Cancel
                            </Button>
                            <Button  
                                block
                                type="primary"
                                className='btnsave brand-bg text-white'
                                loading={loading}
                                onClick={()=>{
                                    // DeleteProductApi();
                                }}
                                
                            >
                                Save
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {RecordedQuantityModal}