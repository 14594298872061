import { useState } from "react"
import { Form, Image, Button, Space, Row, Col, Typography, Checkbox, Flex } from "antd"
import {  MyInputw } from '../../components'
import { useDispatch, useSelector } from "react-redux"
import { actionsApi } from "../../shared"
import "./index.css"

const {Title, Text}= Typography

const Login = () => {

    const appDispatcher = useDispatch()
    const [form] = Form.useForm()
    const { loading } = useSelector(state => state?.login)
    const [forgotPassword, setForgotPassword]= useState(false)

    
    
    // const login = () => {
    //     if(forgotPassword)
    //         appDispatcher(actionsApi?.forgotPassword(form.getFieldsValue(['email'])))
    //     else
    //         appDispatcher(actionsApi?.login(form.getFieldsValue(['email', 'password'])))

    // }

    


    return (
        <div className='login-form-cover '>
            <Flex justify="center">
                <div className="login-form">
                    <Row gutter={16}>
                        <Col md={15} sm={0} xs={0}>
                            <div className="w-100 img">
                                <Image 
                                    src="/assets/images/logo.png" 
                                    className="w-100 radius-12" 
                                    alt="salon-logo"
                                    preview={false}
                                />
                            </div>
                        </Col>
                        <Col 
                            md={9} sm={24} xs={24} 
                            className="bg-white login-form-right-side"
                        >
                            <Space 
                                direction='vertical' 
                                className='height-100 justify-center'
                                size={15}
                                >
                                    <Image src="/assets/images/logo-cr.png" width={'60px'} />
                                <div>
                                    <Title 
                                        level={4} 
                                        className="text-white mt-0"
                                    >
                                        Welcome to Shafrah Salon System!
                                    </Title>
                                    <Text className="text-white">
                                        Enter the credentials to login to the system.
                                    </Text>
                                </div>
                                <Form
                                    form={form}
                                    layout="vertical"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    // onFinish={login}
                                    requiredMark={false}
                                >
                                    <Row>
                                        <Col span={24}>
                                            <MyInputw
                                                autoFocus
                                                name="email"
                                                label="Email Address / Phone Number"
                                                required
                                                size={'large'}
                                                message='Enter email address / phone number'
                                                placeholder='Enter email address / phone number'
                                                value={form.getFieldValue("email") || ''}
                                                className='bg-transparent text-white'
                                            />
                                        </Col>
                                        <Col span={24}>
                                            <MyInputw
                                                name="password"
                                                type='password'
                                                label="Password"
                                                required
                                                size={'large'}
                                                message='Enter password'
                                                placeholder='Enter password'
                                                value={form.getFieldValue("password") || ''}
                                                className='bg-transparent text-white loginpass'
                                            />
                                        </Col>
                                        <Col span={24} className="center">
                                            <Button 
                                                type="primary"
                                                htmlType="submit" 
                                                size="large"
                                                block
                                                loading={loading}
                                                className="signbtn"
                                                >
                                                Signin
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Space>
                        </Col>
                    </Row>
                    {/* <Text strong className="pt-3 light-grey-text">
                        Version <span className="brand-color">0.2</span> Beta Release
                    </Text> */}
                </div>
            </Flex>
        </div>
    )
}
export {Login}