import React, { useState } from 'react';
import { Card, Dropdown, Form, Flex, Button, Space, Typography } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { DownOutlined } from '@ant-design/icons';
import { ModuleTopHeading } from '../../PageComponents';
import { MyInput } from '../../Forms';

const { Title } = Typography

const RevenueLineChart = () => {
  const [order, setOrder] = useState(0);
  const [form] = Form.useForm();
  const items = [
    { label: <a href="#">Monthly</a>, key: 0 },
    { label: <a href="#">Quarterly</a>, key: 1 },
    { label: <a href="#">Yearly</a>, key: 2 },
  ];

  const onClick = ({ key }) => {
    // key = parseInt(key) + 1;
    setOrder(key);
    // filter(key);
  };

  const chartData = {
    series: [{
      name: 'Online Payment',
      data: [44, 55, 41, 67, 22, 43, 21]
    }, {
      name: 'In the Venue',
      data: [13, 23, 20, 8, 13, 27, 33]
    }, {
      name: 'Credit Card Payment',
      data: [11, 17, 15, 15, 21, 14, 15]
    }],
    options: {
      chart: {
        type: 'bar',
        stacked: true,
        stackType: '100%',
        toolbar:{
          show:false,
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        categories: [
          '10/11/2024',
          '11/11/2024',
          '12/11/2024',
          '13/11/2024',
          '14/11/2024',
          '15/11/2024',
          '16/11/2024',
        ],
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      yaxis: {
        min: 0,
        max: 25,
        tickAmount: 5,
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        show: false,
      },
      responsive: [{
        breakpoint: 480
      }],
      colors: ['#62B2FD','#9BDFC4','#F9DF9B'], 
      legend:{
        markers:{
          shape: "circle"
        }
      }     
    },
  };

  return (
    <div>
      <Card className='shadow-c border0 radius-12'>
        <Flex justify='space-between' align='center' wrap gap={10}>
          <ModuleTopHeading name='Revenue Tracking' />
          <Form form={form} layout="vertical" >
            <Flex justify='end' gap={10}>
              <Dropdown
                menu={{ items, onClick }}
                trigger={['click']}
                className='margin-top'
              >
                <Button className='btnsave fs-13 border-brand pad-filter text-brand'>
                  <Space>
                    {
                      order == 0 ? 'Monthly' :
                      order == 1 ? 'Quarterly' :
                      order == 2 ? 'Yearly' : ''
                    }  
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Flex>
          </Form>
        </Flex>
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={200}
          />
      </Card>
    </div>
  );
};

export { RevenueLineChart };
