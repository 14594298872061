import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Row } from 'antd'
import { ModuleTopHeading, MyInput, PayrollCards, MySelect, TableViewPayroll, GridViewPayroll, ViewPayrollModal, AddEditPayrollDrawer, PaymentStatusModal, DownloadConfirmModal } from '../../components';


const PayrollPage = () => {
    const [ current, setCurrent ] = useState(true)
    const [form] = Form.useForm();
    const [ visible, setVisible ] = useState(false)
    const [ edititem, setEditItem ] = useState(null)
    const [ visibleModal, setVisibleModal ] = useState(false)
    const [ paymentstatus, setPaymentStatus ] = useState(false)
    const [ receipt, setReceipt ] = useState(false)

    return (
        <div>
            <Row gutter={[16,16]}>
                <Col span={24}>
                    <PayrollCards />
                </Col>
                <Col span={24}>
                    <Card className='shadow-c radius-12 border0 cardlg'>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <Flex justify='space-between' align='center' gap={10} wrap>
                                    <ModuleTopHeading name='Payroll' onClick={()=>setVisible(true)} />
                                    <Flex gap={'small'}>
                                        <Button
                                            onClick={() => setCurrent(!current)}
                                            className='bg-transparent border0 p-0'
                                        >
                                            {
                                                current ?
                                                <img src="/assets/icons/list-ic.png" alt="" width={60} />
                                                :
                                                <img src="/assets/icons/grid-ic.png" alt="" width={60} />
                                            }
                                        </Button>
                                    </Flex>
                                </Flex>                        
                            </Col>
                            <Col span={24}>
                                <Form
                                    form={form}
                                    layout="vertical"
                                >
                                    <Row gutter={16} align={'middle'}>
                                        <Col lg={4} md={12} sm={24} xs={24}>
                                            {/* <MySelect
                                                label= 'Employee Name'
                                                name="employeeName"
                                                value={form.getFieldValue("employeeName") || ''}
                                                options={[
                                                    {
                                                        key: 1,
                                                        name: 'Daud',
                                                    },
                                                    {
                                                        key: 2,
                                                        name: 'Sher Ali',
                                                    },
                                                ]}
                                            /> */}
                                            <MyInput
                                                name='name'
                                                label='Employee Name'
                                                placeholder=''
                                                value={form.getFieldValue("name") || ''} 
                                                className='w-100 bg-transparent'
                                            />
                                        </Col>
                                        <Col lg={4} md={12} sm={24} xs={24}>
                                            <MySelect
                                                label= 'Status'
                                                name="status"
                                                value={form.getFieldValue("status") || ''}
                                                options={[
                                                    {
                                                        key: 1,
                                                        name: 'Paid',
                                                    },
                                                    {
                                                        key: 2,
                                                        name: 'Unpaid',
                                                    },
                                                ]}
                                            />
                                        </Col>
                                        <Col lg={4} md={12} sm={24} xs={24}>
                                            <MySelect
                                                label= 'Month'
                                                name="month"
                                                value={form.getFieldValue("month") || ''}
                                                options={[
                                                    {
                                                        key: 1,
                                                        name: 'Jan',
                                                    },
                                                    {
                                                        key: 2,
                                                        name: 'Feb',
                                                    },
                                                    {
                                                        key: 3,
                                                        name: 'Mar',
                                                    },
                                                ]}
                                            />
                                        </Col>
                                        <Col lg={3} md={12} sm={24} xs={12}>
                                            <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col span={24}>
                                {
                                    current ?
                                    <TableViewPayroll {...{ receipt, setReceipt, paymentstatus, setPaymentStatus, edititem , setEditItem , visible, setVisible, visibleModal, setVisibleModal}}/>
                                    :
                                    <GridViewPayroll {...{ receipt, setReceipt, edititem , setEditItem , visible, setVisible, visibleModal, setVisibleModal}} />
                                }
                            </Col>
                            
                        </Row>
                    </Card>
                </Col>
            </Row>
            
            <AddEditPayrollDrawer
                visible={visible}
                edititem={edititem}
                onClose={()=> {setVisible(false);setEditItem(null)}}
            />
            <ViewPayrollModal 
                visible={visibleModal}
                onClose={()=> setVisibleModal(false)}
            />
            <PaymentStatusModal 
                visible={paymentstatus}
                onClose={()=>setPaymentStatus(false)}
            />
            <DownloadConfirmModal 
                visible={receipt}
                receipt={receipt}
                onClose={()=>setReceipt(false)}
            />
        </div>
    )
}

export { PayrollPage }
