import { Row, Col, Form, Space, Switch, Flex, Typography, Button } from "antd";
import { MinusCircleOutlined, MinusOutlined, PlusCircleOutlined, PlusOutlined, StopOutlined } from "@ant-design/icons";
import { useState } from "react";
import { MyDatepicker, MyTimePicker } from "../../Forms";

const TimeForm = ({ form, dayKey, title }) => {
  const [datetimeadd, setDateTimeAdd] = useState([]);
  const [isSwitchOn, setIsSwitchOn] = useState(dayKey !== 'Sunday');

  const handleDateTimeChange = (field, value, name) => {
    setDateTimeAdd((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        [field]: value,
      },
    }));
  };

  const handleSwitchChange = (checked) => {
    setIsSwitchOn(checked);
  };

  return (
    <Form.List name={dayKey}>
      {(fields, { add, remove }) => (
        <Space direction="vertical" className="w-100 mb-3">
          <Row gutter={[16, 0]} className="mb-3">
            <Col span={6}>
                <Flex align="center" gap={10}>
                    <Switch size="small" checked={isSwitchOn} disabled={dayKey === "sunday"} onChange={handleSwitchChange} />
                    <Typography.Text className="fw-500">{title}</Typography.Text>
                </Flex>
            </Col>
            <Col span={18}>
                {fields.length === 0 && add()}
                {fields.map(({ key, name }, index) => (
                    <Row key={key} gutter={[16, 0]} align="middle" className="mb-1">
                        <Col xs={24} sm={24} md={12} lg={11}>
                            <MyTimePicker
                                name={[name, "fromTime"]}
                                placeholder='From Time'
                                required
                                message="Please enter from time"
                                value={datetimeadd[name]?.fromTime}
                                onChange={(value) =>
                                handleDateTimeChange("fromTime", value, name)
                                }
                                disabled={isSwitchOn === true ? false : true} 
                            />
                        </Col>
                        <Col xs={22} sm={22} md={11} lg={11}>
                            <MyTimePicker
                                name={[name, "toTime"]}
                                placeholder='To Time'
                                required
                                message="Please enter to time"
                                value={datetimeadd[name]?.toTime}
                                onChange={(value) => handleDateTimeChange("toTime", value, name)}
                                disabled={isSwitchOn === true ? false : true}
                            />
                        </Col>

                        {fields.length > 0  && index > 0 && (
                            <Col span={1}>
                                <Button 
                                    type="primary" 
                                    shape="circle" 
                                    size='small' 
                                    className='bg-close text-white'
                                    icon={<MinusOutlined/>}
                                    onClick={() => remove(name)}
                                />
                            </Col>
                        )}

                        {index < 1 && (
                            <Col span={1}>
                                {
                                  isSwitchOn === true ? 
                                  <Button 
                                    type="primary" 
                                    shape="circle" 
                                    size='small' 
                                    className='brand-bg text-white'
                                    icon={<PlusOutlined/>}
                                    onClick={() => add()}
                                />
                                  :
                                  <StopOutlined className='danger' />
                                }
                            </Col>
                        )}
                    </Row>
                ))}
            </Col>
          </Row>
        </Space>
      )}
    </Form.List>
  );
};

export default TimeForm;
