import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography} from "antd"
import { MyInput, MySelect } from "../../Forms"

const { Title, Text } = Typography;

const AddEditServiceDrawer = ({visible, onClose, edititem}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
    const [ changetext, setChangeText] = useState(true)
    
    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }


  return (
    <Drawer
        title={edititem?'Edit service':'Add service'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {edititem? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3">SERVICE DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Service Name (English)'
                        name='serviceNameeng'
                        placeholder='e.g Short Hair Trim'
                        required
                        size={'large'}
                        message='Please enter service name (English)'
                        value={form.getFieldValue("serviceNameeng") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Service Name (Arabic)'
                        name='serviceNamearab'
                        placeholder='على سبيل المثال، قص الشعر القصير'
                        required
                        size={'large'}
                        message='Please enter service name (Arabic)'
                        value={form.getFieldValue("serviceNamearab") || ''}
                        className='text-end'
                    />
                </Col>
                <Col lg={{span: 24}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Category Name'
                        name="categoryName" 
                        size={'large'}
                        required
                        message='Please choose category name'
                        value={form.getFieldValue("categoryName") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Hair Dye'
                            },
                            {
                                key: 2,
                                name: 'Shave'
                            },
                            {
                                key: 3,
                                name: 'Hair Cut'
                            },
                        ]}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Service Price'
                        name='servicePrice'
                        placeholder=''
                        size={'large'}
                        addonAfter={<Text className="text-brand fs-13">SAR</Text>}
                        required
                        message='Please enter service price'
                        value={form.getFieldValue("servicePrice") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Service Duration (min)'
                        name='serviceDuration'
                        placeholder=''
                        size={'large'}
                        addonAfter={
                            <Text className="text-brand cursor fs-13" onClick={()=>setChangeText(!changetext)}>
                                {
                                    changetext === true ? 'Min' : 'Hour'
                                }
                            </Text>
                        }
                        required
                        message='Please enter service duration'
                        value={form.getFieldValue("serviceDuration") || ''}
                    />
                </Col>
                <Col lg={{span: 24}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        textArea
                        label='Service Description (optional)'
                        name='description'
                        placeholder=''
                        size={'large'}
                        required
                        message='Please enter service description'
                        value={form.getFieldValue("description") || ''}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditServiceDrawer}