import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Skeleton, Tooltip, Flex, Rate } from 'antd'

const { useToken } = theme;
const { Title, Text } = Typography
const CardComponent = () => {
    const { token } = useToken();
    const [ skeletonactive, setSkeletonActive] = useState(false)

    // setTimeout(() => {
    //     setSkeletonActive(false)
    // }, 2000);

    const data = [
        {
            title:'4250',
            subtitle:'Total Customers',
            img:'totalclient-pink.png',
            key:'pink'
        },
        {
            title:'2250',
            subtitle:'Expense',
            img:'total-expense-blue.png',
            key:'blue'
        },
        {
            title:'425K',
            subtitle:'Revenue',
            img:'total-rev-green.png',
            key:'green'
        },
        {
            title:'4250',
            subtitle:'Total Reviews',
            img:'total-review-orange.png',
            key:'orange',
        },
        
        
    ]
  return (
    <div>
        <Row gutter={[16,16]}>
            {
                data?.map((cdata,c)=>
                    <Col xs={24} sm={24} md={12} lg={6} key={c}>
                        {
                            skeletonactive?
                                <Skeleton.Button
                                    active
                                    size='large' 
                                    shape='square'
                                    block
                                    style={{width:'100%', height:'114px',borderRadius:20}}
                                />
                                
                                :
                                <Card style={{
                                    borderColor: token.borderCardcolor,
                                    width:"100%"}}
                                    className={
                                        cdata?.key === 'pink' ? 'card-cs border-btm-pink shadow-c height-100':
                                        cdata?.key === 'brown' ? 'card-cs border-btm-brown  shadow-c height-100' :
                                        cdata?.key === 'blue' ? 'card-cs border-btm-blue shadow-c height-100' :
                                        cdata?.key === 'green' ? 'card-cs border-btm-green shadow-c height-100' :
                                        cdata?.key === 'orange' ? 'card-cs border-btm-orange  shadow-c height-100' :
                                        'card-cs border0 shadow-c height-100'
                                    }
                                >
                                    <Row justify='space-between' align='middle'>
                                        <Col lg={10} md={12} xs={12} sm={12}>
                                            <div className='my-2'>
                                                <Title level={cdata?.key === 'orange' ? 5 : 4} className='my-0'>
                                                    {
                                                        cdata?.key === 'orange' ? '3.5/5' + ' ' : ''
                                                    } 
                                                    {cdata?.title}
                                                </Title>                         
                                                <Title className='my-2 fs-14 fw-500' level={5}>{cdata?.subtitle}</Title>
                                            </div>
                                        </Col>
                                        <Col lg={14} md={12} xs={12} sm={12}>
                                            <Flex justify='end' align='end' vertical>
                                                {
                                                    cdata?.key === 'orange' ?
                                                    <Flex className='bg-orange img-bg' align='center' gap={2} wrap={false}>
                                                        <Rate style={{fontSize:13}} className='rate' allowHalf defaultValue={3.5} />
                                                    </Flex>
                                                    :
                                                    <img src={'/assets/icons/'+cdata?.img} width={'50px'} 
                                                        className=
                                                        {
                                                            cdata?.key === 'pink' ? 'bg-pink img-bg' :
                                                            cdata?.key === 'brown' ? 'bg-brown img-bg' :
                                                            cdata?.key === 'blue' ? 'bg-blue img-bg' :
                                                            cdata?.key === 'green' ? 'bg-light-green img-bg' :
                                                            cdata?.key === 'orange' ? 'bg-orange img-bg' : 'img-bg'
                                                        }
                                                    /> 
                                                }
                                            </Flex>  
                                        </Col>
                                    </Row>
                                </Card> 
                                }  
                    </Col>
                )
            }
        </Row>
    </div>
  )
}

export {CardComponent}