import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Avatar, Switch, Flex } from 'antd'
import { ActionButton } from '../../PageComponents';


const { Text } = Typography


const TableViewSupplier = ({ setDeleteItem, setEditItem, setVisible, setViewOnly }) => {
    const [form] = Form.useForm();

    const columns = [
        {
            title: 'Supplier Name',
            dataIndex: 'supplierName',
            sorter: (a,b) => a.contact.localeCompare(b.contact)
        },
        {
            title: 'Contact Number',
            dataIndex: 'contact',
            sorter: (a,b) => a.contact.localeCompare(b.contact)
        },
        {
            title: <Text>Actions</Text>,
            key: 'action',
            fixed: 'right',
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='View'
                        shape="circle"
                        icon={<Image src='/assets/icons/lighteye.png' width={18} preview={false} />}
                        onClick={() => { setViewOnly(
                            {name: row?.supplierName})
                        }}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Edit employee'
                        shape="circle"
                        icon={<Image src='/assets/icons/edit.png' width={18} preview={false} />}
                        onClick={() => { setVisible(true); setEditItem({name: row?.supplierName})}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={20} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.supplierName}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            supplierName: 'Loreal',
            contact: '+966 142567886',
        },
        {
            key: '2',
            supplierName: 'Keune',
            contact: '+966 56896488',
        },
      ];

  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    showSorterTooltip={false}
                    pagination={{
                        hideOnSinglePage: true,
                        position: ['bottomCenter'],
                        total: 12,
                        // pageSize: pagination?.pageSize,
                        // defaultPageSize: pagination?.pageSize,
                        // current: pagination?.pageNo,
                        // size: "default",
                        // pageSizeOptions: ['10', '20', '50', '100'],
                        // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                        showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                    }}
                />
            </Col>
        </Row>
    </div>
  )
}

export {TableViewSupplier}