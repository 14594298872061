import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Avatar, Switch, Flex } from 'antd'
import { ActionButton } from '../../PageComponents';


const { Text } = Typography


const TableViewProduct = ({ setDeleteItem, setEditItem, setVisible, setViewOnly, setHistoryDrawer }) => {
    const [form] = Form.useForm();

    const columns = [
        {
            title: 'Product Name ',
            dataIndex: 'productName',
            sorter: (a,b) => a.productName - b.productName,
            width: 200
        },
        {
            title: 'Category',
            dataIndex: 'category',
            sorter: (a,b) => a.category.localeCompare(b.category),
            width:130
        },
        {
            title: 'Supplier',
            dataIndex: 'supplier',
            sorter: (a,b) => a.supplier.localeCompare(b.supplier),
            width:200
        },
        {
            title: 'Price Per Unit',
            dataIndex: 'priceperunit',
            sorter: (a,b) => a.priceperunit.localeCompare(b.priceperunit),
            width:150
        },
        {
            title: 'Total Quantity',
            dataIndex: 'totalQuantity',
            sorter: (a,b) => a.totalQuantity.localeCompare(b.totalQuantity),
            width:150
        },
        {
            title:'Time Stamp',
            dataIndex: 'timeStamp',
            sorter: (a,b) => a.timeStamp.localeCompare(b.timeStamp),
            width:150
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 140,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='View'
                        shape="circle"
                        icon={<Image src='/assets/icons/lighteye.png' width={18} preview={false} />}
                        onClick={() => { setViewOnly(
                            {name: row?.supplier,quantity: row?.totalQuantity})
                        }}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Edit employee'
                        shape="circle"
                        icon={<Image src='/assets/icons/edit.png' width={18} preview={false} />}
                        onClick={() => { setVisible(true); setEditItem({name: row?.supplier})}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Stock history'
                        shape="circle"
                        icon={<Image src='/assets/icons/history.png' width={16} preview={false} />}
                        onClick={() => { setHistoryDrawer(true)}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={18} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.supplier}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            productName: 
                <Space>
                    <Avatar
                        size={30}
                        icon={<img src="/assets/images/pro-1.png" />}
                    />
                    Dryer
                </Space>,
            category: 'Hair Care',
            supplier: 'Remilington',
            priceperunit: '200',
            totalQuantity: '100',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '2',
            productName: 
                <Space>
                    <Avatar
                        size={30}
                        icon={<img src="/assets/images/bannerimg-1.png" />}
                    />
                    Skin Care
                </Space>,
            category: 'Skin Care',
            supplier: 'Loreal',
            priceperunit: '100',
            totalQuantity: '200',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '3',
            productName: 
                <Space>
                    <Avatar
                        size={30}
                        icon={<img src="/assets/images/bannerimg-3.png" />}
                    />
                    Keratin Shampoo
                </Space>,
            category: 'Hair Care',
            supplier: 'Keune',
            priceperunit: '234',
            totalQuantity: '234',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '4',
            productName: 
                <Space>
                    <Avatar
                        size={30}
                        icon={<img src="/assets/images/bannerimg-2.png" />}
                    />
                    Dermo Facial
                </Space>,
            category: 'Skin Care',
            supplier: 'Natura',
            priceperunit: '453',
            totalQuantity: '344',
            timeStamp: '11:02PM 9/18/16',
        },
      ];

  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    showSorterTooltip={false}
                    pagination={{
                        hideOnSinglePage: true,
                        position: ['bottomCenter'],
                        total: 12,
                        // pageSize: pagination?.pageSize,
                        // defaultPageSize: pagination?.pageSize,
                        // current: pagination?.pageNo,
                        // size: "default",
                        // pageSizeOptions: ['10', '20', '50', '100'],
                        // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                        showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                    }}
                />
            </Col>
        </Row>
    </div>
  )
}

export {TableViewProduct}