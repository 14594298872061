import React, { useEffect, useState } from "react"
import { Form, Modal, Table, Typography, Button, Row, Col, Flex, Space, Image, Divider} from "antd"

const { Title, Text } = Typography

const ViewPayrollModal = ({visible, onClose}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible) {
            
        } else {
            form.resetFields();
        }
    }, [visible])

    const data = {
        conDetails: [
            {
                title:'Payment From',
                name:'Divine Salon',
                contactno:'+966 56787652',
                desc:'236/B-Block, Riyadh, Saudi Arabia'
            },
            {
                title:'Payment To',
                name:'Muhammad Ali',
                contactno:'+966 56787652',
                desc:'Pay Method: Monthly'
            },
        ],
        tablecontent:[
            {
                title:'Basic Salary',
                value: '10,000'
            },
            {
                title:'Total Earning (Overtime & Bonus)',
                value: '1100'
            },
            {
                title:'Deduction',
                value: '500'
            },
            {
                title:'Tax',
                value: '1000'
            },

        ]
    }

    
  return (
    <Modal
        title={null}
        open={visible}
        width={600}
        footer={false}
        centered 
        closeIcon={false}
        onOk={onClose} 
        onCancel={onClose}
    >
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Flex justify="space-between" align="center">
                    <Space direction="vertical" size={0}>
                        <Title level={4} className="m-0">Payslip</Title>
                        <Text className="text-gray fw-500 fs-13">Pay Date: 10/02/2024</Text>
                    </Space>
                    <Image src='/assets/images/bannerimg-2.png' preview={false} width={130} />
                </Flex>
            </Col>
            {
                data?.conDetails?.map((data,index)=>
                    <Col lg={9} md={8} xs={25} sm={24} key={index}>
                        <Flex vertical gap={0}>
                            <Title level={5} className="mb-1 mt-0">
                                {
                                    data?.title
                                }
                            </Title>
                            <Text>{data?.name}</Text>
                            <Text>{data?.contactno}</Text>
                            <Text>{data?.desc}</Text>
                        </Flex>
                    </Col>
                )
            }
            <Col lg={6} md={8} xs={25} sm={24}>
                <Flex vertical gap={0}>
                    <Title level={5} className="mb-1 mt-0">
                        Payment Mode
                    </Title>
                    <Text>Bank Transfer</Text>
                </Flex>
                <Flex vertical gap={0}>
                    <Title level={5} className="mb-1 mt-0">
                        Account Details
                    </Title>
                    <Text>SA ***************098</Text>
                </Flex>
            </Col>
            <Col span={24}>
                <Flex justify="space-between">
                    <Title level={5} className="mb-1 mt-0">
                        Details
                    </Title>
                    <Title level={5} className="mb-1 mt-0">
                        Amount (SAR)
                    </Title>
                </Flex>
                <hr />
                {
                    data?.tablecontent?.map((item,index)=>
                        <div key={index}>
                            <Flex justify="space-between" className="my-2">
                                <Text >
                                    {item?.title}
                                </Text>
                                <Text >
                                    {item?.title}
                                </Text>
                            </Flex>
                            {
                                index < data.tablecontent.length - 1 &&
                                <Divider className="m-0" /> 
                            }
                        </div>
                    )
                }
                <hr />
                <Flex justify="space-between">
                    <Title level={5} className="mb-1 mt-0">
                        Net Salary
                    </Title>
                    <Title level={5} className="mb-1 mt-0">
                        SAR 9600
                    </Title>
                </Flex>
            </Col>
        </Row>
    </Modal>
  )
}

export {ViewPayrollModal}