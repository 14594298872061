import React, { useState } from 'react'
import { Modal, Space, Typography, Button, Image, Col, Row, Form, Flex } from 'antd'
import { apiCalls } from '../../../shared/Apis';
import { InputNolabel, MyInput, MySelect } from '../../Forms';
const { Title, Text } = Typography
const IncrementModal = ({visible,onClose,viewonly}) => {
    
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)
    const [ increament, setIncreament ] = useState(1)

    const IncreamentBtn = () => {
        setIncreament(increament + 1)
    }

    const DecreamentBtn = () => {
        if(increament > 1){
            setIncreament(increament - 1)
        }
    }

    const handleInputChange = (e) => {
        const value = Number(e.target.value);
        if (!isNaN(value) && value >= 1) {
            setIncreament(value);
        }
    };

  return (
    <div>
        <Modal width={500} className='shadow-c'  open={visible} onOk={onClose} onCancel={onClose} centered footer={null}>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Flex justify='center' className='pb-1'>
                            <Image src='/assets/icons/incrementstatus.png' width={50} preview={false} />
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <Flex justify='center' className='pb-1'>
                            <Title level={5} className='my-0'>Add Stock</Title>
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <Flex justify='center' className='mb-2'>
                            <Title level={5} className='my-0'>{viewonly?.name}</Title>
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <Flex gap={10} justify='center'  align='center'>
                                                       
                            <Button 
                                className='p-0 bg-transparent border0'
                                size="small" 
                                onClick={DecreamentBtn} 
                                shape="circle" 
                                icon={<img src='/assets/icons/minus-cr.png' width={24} />} 
                                disabled={increament === 0 ? true : false}
                            />
                            <InputNolabel
                                value={increament}
                                style={{width: 40,height: '20px !important' ,}}
                                className='text-center'
                                onChange={handleInputChange}
                            />
                            <Button
                                className='p-0 bg-transparent border0'
                                size="small" 
                                onClick={IncreamentBtn} 
                                shape="circle" 
                                icon={<img src='/assets/icons/plus-cr.png' width={24} />} 
                            />
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <MySelect
                            label= 'Supplier'
                            name="supplier" 
                            size={'large'}
                            required
                            message='Please choose supplier'
                            value={form.getFieldValue("supplier") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Supplier 01'
                                },
                            ]}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput
                            label='Price Per Unit'
                            name='priceUnit'
                            placeholder=''
                            size={'large'}
                            required
                            message='Please enter price per unit'
                            value={form.getFieldValue("priceUnit") || ''}
                            addonAfter = {<Text className='text-brand fs-13'>SAR</Text>}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput
                            label='Total Cost'
                            name='totalcost'
                            placeholder=''
                            size={'large'}
                            required
                            message='Please enter total cost'
                            value={form.getFieldValue("totalcost") || ''}
                            addonAfter = {<Text className='text-brand fs-13'>SAR</Text>}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput
                            textArea
                            label='Note (optional)'
                            name='note'
                            placeholder='e.g Lorem ipsum'
                            size={'large'}
                            value={form.getFieldValue("note") || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <Space style={{justifyContent:"center",marginTop:'15px'}} className="w-100">
                            <Button
                                onClick={onClose}
                                className='btncancel'
                            >
                                Cancel
                            </Button>
                            <Button  
                                block
                                type="primary"
                                className='btnsave brand-bg text-white'
                                loading={loading}
                                onClick={()=>{
                                    // DeleteProductApi();
                                }}
                                
                            >
                                Save
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {IncrementModal}