import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Typography, Flex, Table} from "antd"

const { Title, Text } = Typography;

const ActivityProductDrawer = ({visible, onClose, activityId}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && activityId) {
            
        } else {
            form.resetFields();
        }
    }, [visible, activityId])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

    const columns = [
        {
            title: 'Date',
            dataIndex: 'timeStamp',
            sorter: (a,b) => a.timeStamp - b.timeStamp,
        },
        {
            title: 'Activity',
            dataIndex: 'activity',
            sorter: (a,b) => a.activity.localeCompare(b.activity),
        },
    ];

    const data = [
        {
            key: '1',
            timeStamp: '11/02/2024',
            activity: '23 items supplied from supplier A. Note: Lorem ipsum',
        },
        {
            key: '2',
            timeStamp: '1/02/2024',
            activity: '50 items supplied from supplier B. Note: Lorem ipsum',
        },
        {
            key: '3',
            timeStamp: '11/02/2024',
            activity: '15 items deleted Note: Lorem ipsum',
        },
      ];

  return (
    <Drawer
        title='Stock history'
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={
            <Flex justify="center" className="w-100" >
                <Button 
                    onClick={closeDrawer}
                    className="border0 text-brand fw-500"
                >
                    Clear Log
                </Button>
            </Flex>
        }
    >
        <Row gutter={16}>
            <Col span={24}>
                <Title level={5} className="mt-0 mb-3 fw-500">STOCK DETAILS</Title>
            </Col>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    className='table-dark pagination'
                    rowHoverable={false}
                    showSorterTooltip={false}
                    pagination={false}
                />
            </Col>
        </Row>
    </Drawer>
  )
}

export {ActivityProductDrawer}