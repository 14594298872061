import React from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Flex, Pagination, Button } from 'antd'
import { ActionButton } from '../../PageComponents';

const { useToken } = theme;
const { Text } = Typography
const GridViewExpense = ({setEditItem, setDeleteItem, setVisible, setDownload}) => {
    const { token } = useToken();

    
    const onShowSizeChange = (current, pageSize) => {
        console.log(current, pageSize);
      };

    const data = [
        {
            title:'Electricity Bill',
            amount:'SAR 200',
        },
        {
            title:'Fan Repair',
            amount:'SAR 1000',
        },
        {
            title:'Keratin Treatment Cream',
            amount:'SAR 1000',
        },
        {
            title:'Lorem ipsum',
            amount:'SAR 1000',
        },
        {
            title:'Lorem ipsum',
            amount:'SAR 1000',
        },
        {
            title:'Lorem ipsum',
            amount:'SAR 1000',
        },
        {
            title:'Lorem ipsum',
            amount:'SAR 1000',
        },
        {
            title:'Lorem ipsum',
            amount:'SAR 1000',
        },
        {
            title:'Lorem ipsum',
            amount:'SAR 1000',
        },
        {
            title:'Lorem ipsum',
            amount:'SAR 1000',
        },
        {
            title:'Lorem ipsum',
            amount:'SAR 1000',
        },
        {
            title:'Lorem ipsum',
            amount:'SAR 1000',
        },
    ]
  return (
    <div>
        <Row gutter={[16,16]}>
            {
                data?.map((cdata,c)=>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6} key={c}>
                        <Card style={{
                            borderRadius: token.borderCardrad,
                            width:"100%"}}
                            className='card-border'
                            actions={[
                                <Space gap={10}>
                                    <ActionButton
                                        title='Edit item'
                                        shape="circle"
                                        icon={<Image src='/assets/icons/edit.png' width={20} preview={false} />}
                                        onClick={() => {setVisible(true); setEditItem({name: cdata?.title})}}
                                        className='border0 bg-transparent'
                                    />
                                    <ActionButton
                                        title='Download'
                                        shape="circle"
                                        icon={<Image src='/assets/icons/download-b.png' width={18} preview={false} />}
                                        onClick={() => { setDownload({name: cdata?.title})}}
                                        className='border0 bg-transparent'
                                    />
                                    <ActionButton
                                        title='Delete item'
                                        icon={<Image src='/assets/icons/delete.png' width={22} preview={false} />}
                                        onClick={() => { setDeleteItem({name: cdata?.title}) }}
                                        className='border0 bg-transparent'
                                    />
                                </Space>
                            ]}
                        >
                        <Flex vertical gap={5} align='center'>
                            <Text strong>{cdata?.title}</Text>
                            <Text className='text-gray fs-13'>{cdata?.amount}</Text>
                        </Flex>
                    </Card>  
                </Col>
                )
            }
            <Col span={24}>
                <Pagination
                    // showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    defaultCurrent={3}
                    align='center'
                    className='my-3'
                    position={'bottomCenter'}
                    total= {12}
                    // pageSize: pagination?.pageSize,
                    // defaultPageSize: pagination?.pageSize,
                    // current: pagination?.pageNo,
                    // size: "default",
                    // pageSizeOptions: ['10', '20', '50', '100'],
                    // onChange= {(pageNo, pageSize) => call(pageNo, pageSize)},
                    showTotal= {(total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>}
                />
            </Col>
        </Row>
    </div>
  )
}

export {GridViewExpense}