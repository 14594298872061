import React from 'react'
import { Button, Card, Col, Form, Row, Space } from 'antd'
import { ModuleTopHeading } from '../../components';
import TimeForm from '../../components/EmployeesComponents/TimeForm';


const OperatinhHours = () => {
    const [form] = Form.useForm();

    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    return (
        <div>
            <Card className='shadow-c radius-12 border0 cardlg'
                actions={[
                    <Space className="w-100 right px-3">
                        <Button 
                            onClick={()=>{}}
                            className="btncancel"
                        >
                            Cancel
                        </Button>
                        <Button  
                            block
                            className='btnsave'
                            type="primary"
                            htmlType="submit"
                            // loading={loading}
                            onClick={()=> form.submit()}
                        >
                            Save
                        </Button>
                    </Space>
                ]}  
            >
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <ModuleTopHeading name='Operating Hours' />                  
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            {daysOfWeek.map(day => (
                                <TimeForm {...{form}} dayKey={day} title={day} />
                            ))}
                        </Form>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export { OperatinhHours }
