import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Row } from 'antd'
import { ModuleTopHeading, MyDatepicker, MyInput, MySelect, TableViewCustomer, GridViewCustomer, ViewHistoryCustomerDrawer, EditCustomerDrawer } from '../../components';


const CustomerPage = () => {
    const [ current, setCurrent ] = useState(true)
    const [form] = Form.useForm();
    const [ visible, setVisible ] = useState(false)
    const [ edititem, setEditItem ] = useState(null)
    const [ history, setHistory ] = useState(false)
    const [ changeinput, setChangeInput ] = useState('Customer Name')

    const handleChangeFilter = (e) => {
        setChangeInput(e === 'Customer Name' ? true : false)
    }

    return (
        <div>
            <Card className='shadow-c radius-12 border0 cardlg'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center' gap={10} wrap>
                            <ModuleTopHeading name='Customers'/>
                            <Flex gap={'small'}>
                                <Button
                                    onClick={() => setCurrent(!current)}
                                    className='bg-transparent border0 p-0'
                                >
                                    {
                                        current ?
                                        <img src="/assets/icons/list-ic.png" alt="" width={60} />
                                        :
                                        <img src="/assets/icons/grid-ic.png" alt="" width={60} />
                                    }
                                </Button>
                            </Flex>
                        </Flex>                        
                    </Col>
                    <Col span={24}>
                    <Form
                            form={form}
                            layout="vertical"
                        >
                            <Row gutter={16} align={'middle'}>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MySelect
                                        label= 'Filter'
                                        name="filter"
                                        value={form.getFieldValue("filter") || ''}
                                        onChange={handleChangeFilter}
                                        options={[
                                            {
                                                key: 1,
                                                name: 'Customer Name',
                                            },
                                            {
                                                key: 2,
                                                name: 'Contact Number',
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    {
                                        changeinput ? (
                                            <MyInput
                                                name='name'
                                                label='Customer Name'
                                                value={form.getFieldValue("name") || ''}
                                                className='w-100 bg-transparent'
                                            />
                                        ) : (
                                            <MyInput
                                                name='contactno'
                                                label='Contact Number'
                                                value={form.getFieldValue("contactno") || ''}
                                                className='w-100 bg-transparent'
                                            />
                                        )
                                    }
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MyDatepicker
                                        rangePicker
                                        label="Registration Date"
                                        name='regDate'
                                        value={form.getFieldValue('regDate') || ''}
                                        className='border-black'
                                    />
                                </Col>
                                <Col lg={3} md={12} sm={24} xs={24}>
                                    <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        {
                            current ?
                            <TableViewCustomer {...{ history, setHistory, visible, setVisible, edititem, setEditItem }}/>
                            :
                            <GridViewCustomer {...{ history, setHistory, visible, setVisible, edititem, setEditItem }} />
                        }
                    </Col>
                    
                </Row>
            </Card>
            <ViewHistoryCustomerDrawer 
                visible={history}
                onClose={()=> setHistory(false)}
            />
            <EditCustomerDrawer 
                visible={visible}
                edititem={edititem}
                onClose={()=>{setVisible(false);setEditItem(null)}}
            />
        </div>
    )
}

export { CustomerPage }
