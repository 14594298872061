import React from 'react'
import { Button, Row, Col, Typography, Table, Image, Space } from 'antd'
import { ActionButton } from '../../PageComponents';


const { Text } = Typography
const TableViewCustomer = ({ setVisible, setEditItem, setHistory }) => {
    
    const columns = [
        {
            title: 'Customer ID',
            dataIndex: 'id',
            sorter: (a,b) => a.id - b.id,
            width:150
        },
        {
            title:'Customer Name',
            dataIndex: 'customerName',
            sorter: (a,b) => a.customerName.localeCompare(b.customerName)
        },
        {
            title:'Contact Number',
            dataIndex: 'contactNumber',
            sorter: (a,b) => a.contactNumber.localeCompare(b.contactNumber)
        },
        {
            title:'Total Reservations',
            dataIndex: 'totalReserve',
            sorter: (a,b) => a.totalReserve.localeCompare(b.totalReserve)
        },
        {
            title: <Text>Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 150,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Edit customer'
                        icon={<Image src='/assets/icons/edit.png' width={18} preview={false} />}
                        onClick={() => { setVisible(true); setEditItem({id:row?.id}) }}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Customer history'
                        icon={<Image src='/assets/icons/cust-history.png' width={18} preview={false} />}
                        onClick={() => { setHistory({id:row?.id}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            id:'#1',
            customerName: 'Sajid Hussain',
            contactNumber: '+966 080 113 098',
            totalReserve: '300',
            timeStamp: '08:02PM 9/18/16',
        },
        {
            key: '2',
            id:'#2',
            customerName: 'Sameh Amin',
            contactNumber: '+966 210 910 100',
            totalReserve: '400',
            timeStamp: '09:02PM 9/18/16',
        },
        {
            key: '3',
            id:'#3',
            customerName: 'Ahmad Abbas',
            contactNumber: '+966 320 300 451',
            totalReserve: '700',
            timeStamp: '10:02PM 9/18/16',
        },
        {
            key: '4',
            id:'#4',
            customerName: 'Noor Eman',
            contactNumber: '+966 580 663 007',
            totalReserve: '1200',
            timeStamp: '11:02PM 9/18/16',
        },
      ];

  return (
    <div>
            <Row gutter={[24,24]}>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 1000}}
                        className='table-dark pagination'
                        rowHoverable={false}
                        showSorterTooltip={false}
                        pagination={{
                            hideOnSinglePage: true,
                            position: ['bottomCenter'],
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
    </div>
  )
}

export {TableViewCustomer}