import React, { useState } from 'react';
import { Button, Card, Col, Flex, Form, Image, message, Row, Space, Typography, Upload } from 'antd';
import { InputNolabel, LocationMap, ModuleTopHeading, MyInput, MyInputnoLabel, MySelect } from '../../components';
import { DeleteOutlined } from '@ant-design/icons';

const { Title, Text } = Typography
const { Dragger } = Upload

const ProfileSettingPage = () => {
    const [form] = Form.useForm();
    
    const props = {
        name: 'image',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };

    return (
        <div>
            <Card className="shadow-c radius-12 border0" 
            
                actions={[
                    <Space className="w-100 right px-3">
                        <Button 
                            className="btncancel"
                        >
                            Cancel
                        </Button>
                        <Button  
                            block
                            className='btnsave'
                            type="primary"
                            htmlType="submit"
                            onClick={()=> form.submit()}
                        >
                            Save
                        </Button>
                    </Space>
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <div>
                                <ModuleTopHeading name="Profile Settings" />
                            </div>
                        </Col>
                        <Col span={24}>
                            <Flex justify='center' className='my-4'>
                                <Image src='/assets/images/bannerimg-1.png' width={180} height={110} style={{objectFit:'cover'}} className='radius-12'/>
                            </Flex>
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <MyInput
                                name="name"
                                label="Salon Name"
                                size='large'
                                placeholder="HH Salon"
                                value={form.getFieldValue("name") || ''}
                                className="w-100 bg-transparent"
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <MyInput
                                name="email"
                                label="Email Address"
                                size='large'
                                placeholder="shafrah@gmail.com"
                                value={form.getFieldValue("email") || ''}
                                className="w-100 bg-transparent"
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <MyInput
                                name="contactNo"
                                label="Contact Number"
                                size='large'
                                placeholder="+966509736323"
                                value={form.getFieldValue("contactNo") || ''}
                                className="w-100 bg-transparent"
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <MySelect
                                label= 'Gender'
                                name="gender" 
                                size='large'
                                value={form.getFieldValue("gender") || ''}
                                options={[
                                    {
                                        key: 1,
                                        name: 'Male'
                                    },
                                    {
                                        key: 2,
                                        name: 'Female'
                                    },
                                    {
                                        key: 3,
                                        name: 'Other'
                                    },
                                ]}
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <MyInput
                                name="sloganEng"
                                label="Slogan (English)"
                                size='large'
                                placeholder=""
                                value={form.getFieldValue("sloganEng") || ''}
                                className="w-100 bg-transparent"
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <MyInput
                                name="sloganArab"
                                label="Slogan (Arabic)"
                                size='large'
                                placeholder=""
                                value={form.getFieldValue("sloganArab") || ''}
                                className="w-100 bg-transparent"
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <MyInput
                                name="taxNumber"
                                label="Tax Number"
                                size='large'
                                placeholder="AB453HJ"
                                value={form.getFieldValue("taxNumber") || ''}
                                className="w-100 bg-transparent"
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <MyInput
                                name="tax"
                                label="Tax"
                                size='large'
                                placeholder="10%"
                                value={form.getFieldValue("tax") || ''}
                                className="w-100 bg-transparent"
                            />
                        </Col>
                        <Col span={24}>
                            <Title level={5} className="mb-3 mt-0 fw-500">LOCATION  DETAILS</Title>
                        </Col>
                        <Col span={24}>
                            <LocationMap />
                        </Col>
                        <Col span={24}>
                            <Title level={5} className="my-3 mt-0 fw-500">SALON GALLERY (Up to 10 images)</Title>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='image' >
                                <div className='width-drag'>
                                    <Dragger {...props} className="dragcs">
                                        <p className="ant-upload-drag-icon">
                                            <Image src='/assets/icons/upload-ic.png' width={'50px'} preview={false} />
                                        </p>
                                        <Flex vertical>
                                            <Text className="fs-14 fw-500">Drag & drop files or Browse</Text>
                                            <Text className="fs-13 text-gray">Supported formats: JPEG, PNG</Text>
                                        </Flex>
                                    </Dragger>
                                </div>
                                {/* {
                                    editproperty &&
                                    <Space direction='vertical' size={10}>
                                        {
                                            geteditdata?.medical_report?.map((data,index)=>
                                                <div className='viewDownload space-between-align' key={index}>
                                                    <Typography.Text className='gray-text fs-12'>
                                                        {
                                                            data?.name
                                                        }
                                                    </Typography.Text>
                                                    <ActionButton
                                                        title='Delete'
                                                        icon={<DeleteOutlined className='danger-color' />}
                                                        onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                                                        type='ghost'
                                                    />
                                                </div>
                                            )
                                        }
                                    </Space>
                                    
                                } */}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Flex className='w-100' gap={5} align='center'>
                                <img src='/assets/images/av-1.png' width={60} height={60} className='radius-12' style={{flexShrink:0}} />
                                <Flex vertical gap={5} className='w-100'>
                                    <Text strong>Img1.jpg</Text>
                                    <MyInputnoLabel 
                                        name='description'
                                        placeholder='Description of image'
                                        addonAfter={<DeleteOutlined className='danger-color' />} 
                                        size='large'
                                    />
                                </Flex>
                            </Flex>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );
};

export { ProfileSettingPage };
