import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Row } from 'antd'
import { AlertModal, ModuleTopHeading, MyInput, TableViewSupplier, ViewSupplierDrawer, AddEditSupplierDrawer, GridViewSupplier } from '../../components';


const SupplierPage = () => {
    const [form] = Form.useForm();
    const [ current, setCurrent ] = useState(true)
    const [ visible, setVisible ] = useState(false)
    const [ edititem, setEditItem ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [ viewonly, setViewOnly ] = useState(false)

    return (
        <div>
            <Card className='shadow-c radius-12 border0 cardlg'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center' gap={10} wrap>
                            <ModuleTopHeading name='Suppliers' onClick={()=> setVisible(true)} /> 
                            <Flex gap={'small'}>
                                <Button
                                    onClick={() => setCurrent(!current)}
                                    className='bg-transparent border0 p-0'
                                >
                                    {
                                        current ?
                                        <img src="/assets/icons/list-ic.png" alt="" width={60} />
                                        :
                                        <img src="/assets/icons/grid-ic.png" alt="" width={60} />
                                    }
                                </Button>
                            </Flex>
                        </Flex>                        
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                            requiredMark={false}
                        >
                            <Row gutter={16} align={'middle'}>
                                <Col lg={4} md={12} sm={24} xs={24}>
                                    <MyInput
                                        name='name'
                                        label='Suppliers Name'
                                        placeholder='e.g Supervisor'
                                        value={form.getFieldValue("name") || ''}
                                        className='w-100 bg-transparent'
                                    />
                                </Col>
                                <Col lg={3} md={12} sm={24} xs={24}>
                                    <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        {
                            current ?
                            <TableViewSupplier {...{ viewonly, setViewOnly, edititem, setEditItem, deleteitem, setDeleteItem, visible, setVisible }}/>
                            :
                            <GridViewSupplier {...{ viewonly, setViewOnly, edititem, setEditItem, deleteitem, setDeleteItem, visible, setVisible }}/>
                        }
                    </Col>
                </Row>
            </Card>
            <AddEditSupplierDrawer 
                visible={visible}
                edititem={edititem}
                onClose={()=> {setVisible(false);setEditItem(null)}}
            />
            <AlertModal 
                visible={deleteitem}
                deleteitem={deleteitem}
                onClose={()=>{setDeleteItem(false)}}
            />
            <ViewSupplierDrawer 
                visible={viewonly}
                viewonly={viewonly}
                onClose={()=>setViewOnly(false)}
            />
        </div>
    )
}

export { SupplierPage }
