import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography, Upload, Flex, Card, Image, Select, Collapse, Divider } from "antd"
import { MyDatepicker, MyInput, MyInputnoLabel, MySelect } from "../../Forms"
import { ActionButton } from "../../PageComponents";
import { ArrowLeftOutlined, HeartOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { discount } from "../../../shared/lookups";

const { Title, Text } = Typography;
const { Dragger } = Upload;

const AddService = ({visible, onClose, edititem, setVisible, setEditItem }) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
    const [ customstate, setCustomState ] = useState(false)
  
    const handleView = (e) => {
        setCustomState(e==='Custom' ? true : false)
    }

    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const handleChange = () => {
        setVisible(true)
        onClose()
    };



    const getItems = () => [
        {
          key: '1',
          label: <Text className="fw-500 fs-13">HAIR CARE</Text>,
          children: 
            <Space className="w-100" direction="vertical" size={5} onClick={handleChange}>
                <Flex vertical gap={5}>
                    <Flex justify="space-between" className="w-100">
                        <Text strong>Facial</Text>
                        <Text strong>SAR 100</Text>
                    </Flex>
                    <Text className="text-gray fs-13">30 min</Text>
                </Flex>
                <Divider className="my-1" />
                <Flex vertical gap={5}>
                    <Flex justify="space-between" className="w-100">
                        <Text strong>Facial</Text>
                        <Text strong>SAR 100</Text>
                    </Flex>
                    <Text className="text-gray fs-13">30 min</Text>
                </Flex>
            </Space>,
        },
        {
            key: '2',
            label: <Text className="fw-500 fs-13">SKIN CARE</Text>,
            children: 
                <Space className="w-100" direction="vertical" size={5} onClick={handleChange}>
                    <Flex justify="space-between" className="w-100">
                        <Text strong>Skin Cream</Text>
                        <Text strong>SAR 100</Text>
                    </Flex>
                    <Text className="text-gray fs-13">30 min</Text>
                </Space>,
        },
        {
            key: '3',
            label: <Text className="fw-500 fs-13">NAIL CARE</Text>,
            children: 
                <Space className="w-100" direction="vertical" size={5} onClick={handleChange}>
                    <Flex justify="space-between" className="w-100">
                        <Text strong>Nail Care Polish</Text>
                        <Text strong>SAR 100</Text>
                    </Flex>
                    <Text className="text-gray fs-13">30 min</Text>
                </Space>,
        },
        {
            key: '4',
            label: <Text className="fw-500 fs-13">FOOT CARE</Text>,
            children: 
                <Space className="w-100" direction="vertical" size={5} onClick={handleChange}>
                    <Flex justify="space-between" className="w-100">
                        <Text strong>Foot Care Cream</Text>
                        <Text strong>SAR 100</Text>
                    </Flex>
                    <Text className="text-gray fs-13">30 min</Text>
                </Space>,
        },

    ];

  return (
    <Drawer
        title={
            <Flex gap={5}>
                <ActionButton 
                    icon={<ArrowLeftOutlined />}
                    onClick={() => {setVisible(true);onClose()}}
                    className='border0 bg-transparent'
                />
                {
                    edititem?'Edit service':'Add service'
                }
            </Flex>
        }
        onClose={onClose}
        className="drawer"
        open={visible}
        width={600}
        closeIcon={false}
        footer={
            edititem &&
            <Space className="w-100 right">
                <Button 
                    onClick={onClose}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    onClick={()=> form.submit() }
                >
                    Save
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-500">SERVICE DETAILS</Title>
                </Col>
                {
                    !edititem ? 
                    <>
                        <Col span={24}>
                            <MyInputnoLabel
                                name='search'
                                placeholder='Search by Service Name'
                                value={form.getFieldValue('search') || ''}
                                suffix={<img src="/assets/icons/search.png" width={13} />}
                            />
                        </Col>
                        <Col span={24}>
                            <Collapse
                                bordered={false}
                                defaultActiveKey={['']}
                                className="extra-cs w-100 pt-3"
                                expandIcon={({ isActive }) => 
                                    <Flex justify="space-between" className="w-100">
                                        <Text className="fw-500 fs-13">{getItems?.label}</Text>
                                        <RightOutlined rotate={isActive ? 90 : 0} />
                                    </Flex>
                                }
                                items={getItems()}
                            />
                        </Col>
                    </>
                    :
                    <>
                        <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                            <MyInput
                                label='Service Name'
                                name='servicename'
                                placeholder=''                        
                                size={'large'}
                                required
                                message='Please enter service name'
                                value={form.getFieldValue("servicename") || ''}
                                addonAfter={
                                    <Button className="border0 p-0 bg-transparent" onClick={()=>{setEditItem(null)}}>
                                        <img src="/assets/icons/ser-ic.png" width={24} />
                                    </Button>
                                }
                            />
                        </Col> 
                        <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                            <MySelect
                                label= 'Discount'
                                name="discount" 
                                size={'large'}
                                required
                                message='Please enter discount'
                                value={form.getFieldValue("discount") || ''}
                                options={discount}
                                onChange={handleView}
                            />
                        </Col>
                        {
                            customstate &&
                            <Col span={24}>
                                <MyInput
                                    label='Custom Discount'
                                    name='customDiscount'
                                    placeholder=''                        
                                    size={'large'}
                                    required
                                    message='Please enter custom discount'
                                    value={form.getFieldValue("customDiscount") || ''}
                                    addonAfter={<Text strong>%</Text>}
                                />
                            </Col> 
                        }
                        <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                            <MyDatepicker
                                label='Start Time'
                                name='startTime'
                                placeholder=''                        
                                size={'large'}
                                required
                                message='Please enter start time'
                                value={form.getFieldValue("startTime") || ''}
                                className='border-gray'
                            />
                        </Col>
                        <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                            <MyInput
                                label='Duration'
                                name='duration'
                                placeholder=''                        
                                size={'large'}
                                required
                                message='Please enter duration'
                                value={form.getFieldValue("duration") || ''}
                            />
                        </Col> 
                        <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                            <MyInput
                                label='Price'
                                name='price'
                                type='number'
                                placeholder=''                        
                                size={'large'}
                                required
                                message='Please enter price'
                                value={form.getFieldValue("price") || ''}
                                addonAfter={<Text className="text-brand fs-13">SAR</Text>}
                            />
                        </Col> 
                        <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                            <MySelect
                                label= 'Assigned To'
                                name="assign" 
                                size={'large'}
                                required
                                message='Please enter assigned to'
                                value={form.getFieldValue("assign") || ''}
                                options={[
                                    {
                                        key: 1,
                                        name: 'M.Daud'
                                    },
                                    {
                                        key: 2,
                                        name: 'M.Haroon'
                                    }
                                ]}
                            />
                        </Col> 
                    </>
                }
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddService}