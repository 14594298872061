import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Avatar, Switch, Flex } from 'antd'
import { ActionButton } from '../../PageComponents';


const { Text } = Typography


const TableViewProCategory = ({ setDeleteItem, setEditItem, setVisible, setViewOnly }) => {
    const [form] = Form.useForm();

    const columns = [
        {
            title: 'Product Category',
            dataIndex: 'name',
            sorter: (a, b) => a.name - b.name,
            width: 180,
        },
        {
            title:'Time Stamp',
            dataIndex: 'timeStamp',
            sorter: (a,b)=> a.timeStamp.localeCompare(b.timeStamp),
            width: 150,
        },
        {
            title: <Text>Action</Text>,
            key: 'action',
            width:100,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='View'
                        shape="circle"
                        icon={<Image src='/assets/icons/lighteye.png' width={18} preview={false} />}
                        onClick={() => { setViewOnly(
                            {name: row?.name})
                        }}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Edit employee'
                        shape="circle"
                        icon={<Image src='/assets/icons/edit.png' width={18} preview={false} />}
                        onClick={() => { setVisible(true); setEditItem({name: row?.name})}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={20} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.name}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];
    

    const data = [
        {
            key: '1',
            name: 'Hair Care Stock',
            timeStamp: '13:02PM 14/18/16',
        },
        {
            key: '2',
            name: 'Facial Products',
            timeStamp: '14:02PM 15/18/16',
        },
        {
            key: '3',
            name: 'New Year Products',
            timeStamp: '12:02PM 16/18/16',
        },
        {
            key: '4',
            name: 'Discount Gifts',
            timeStamp: '10:02PM 17/18/16',
        },
        {
            key: '5',
            name: 'Hair Care Stock',
            timeStamp: '08:02PM 18/18/16',
        },
      ];

  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    showSorterTooltip={false}
                    pagination={{
                        hideOnSinglePage: true,
                        position: ['bottomCenter'],
                        total: 12,
                        // pageSize: pagination?.pageSize,
                        // defaultPageSize: pagination?.pageSize,
                        // current: pagination?.pageNo,
                        // size: "default",
                        // pageSizeOptions: ['10', '20', '50', '100'],
                        // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                        showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                    }}
                />
            </Col>
        </Row>
    </div>
  )
}

export {TableViewProCategory}