import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Typography, Flex, Table, Space, List} from "antd"
import { MyInput } from "../../Forms";

const { Title, Text } = Typography;

const ViewHistoryCustomerDrawer = ({visible, onClose, activityId}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && activityId) {
            
        } else {
            form.resetFields();
        }
    }, [visible, activityId])

    const closeDrawer= ()=>{
        form.resetFields()
        onClose()
    }

    const columns = [
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Time Stamp
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'timeStamp',
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Reservation
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'reservation',
        },
    ];

    const data = [
        {
            key: '1',
            timeStamp: <Text>11/02/2024</Text>,
            reservation: <Text>Client booked Reservation #1234 <br /> Status: Pending</Text>,
        },
        {
            key: '2',
            timeStamp: <Text>1/02/2024</Text>,
            reservation: <Text>Client booked Reservation #1287 <br />Status: Cancelled</Text>,
        },
        {
            key: '3',
            timeStamp: <Text>11/02/2024</Text>,
            reservation: <Text>Client booked Reservation #1678 <br />Status: Completed</Text>,
        },
        {
            key: '4',
            timeStamp: <Text>11/02/2024</Text>,
            reservation: <Text>Client booked Reservation #2434 <br />Status: Completed</Text>,
        },
      ];

      const listitem = [
        {
            list: 'M. Saad',
            img:'av-2.jpg',
        },
        {
            list: 'M. Daud',
            img:'av-3.jpg',
        },
    ]


  return (
    <Drawer
        title='Customer History'
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={false}
    >
        <Row gutter={16}>
            <Col span={24}>
                <Title level={5} className="mt-0 mb-3 fw-500">Favorite Staff</Title>
            </Col>
            <Col span={24}>
                <List
                    className="mb-3"
                    dataSource={listitem}
                    renderItem={(item) => (
                        <List.Item style={{paddingBottom:3,paddingTop:3}}>
                            <Space align="center">
                                <img src={"/assets/images/"+item?.img} width={40} height={40} style={{borderRadius:30}} />
                                <Text> {item?.list}</Text>
                            </Space>
                        </List.Item>
                    )}
                />
            </Col>
            <Col span={24}>
                <Title level={5} className="mt-0 mb-3 fw-500">RESERVATIONS HISTORY</Title>
            </Col>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    className='table-dark pagination'
                    rowHoverable={false}
                    pagination={false}
                />
            </Col>
        </Row>
    </Drawer>
  )
}

export {ViewHistoryCustomerDrawer}