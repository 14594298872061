import React, { useState } from 'react';
import { Card, Flex, Popover, Space, Typography } from 'antd';
// import { ViewPatientDetailModal } from '../ViewPatientDetailModal';
import '../index.css';
import { ReceiptReservation, ViewReservation } from '../ReservationDrawers';

const { Text } = Typography;

const CalendarEvents = ({ event, setService,setEditItem,setProductState,setEditProduct }) => {
    const [visiblemodal, setVisibleModal] = useState(false);
    const [receipt, setReceipt] = useState(false);
    // const [ eventid, setEventId ] = useState(null)

    const content = (
        <div>
            <Flex align='center' gap={10}>
                <img src='/assets/icons/bs-d.png' width={14} />
                <Text className='fs-13'>Mohammad Daud</Text>
            </Flex>
            <Flex align='center' gap={10}>
                <img src='/assets/icons/phone-1.png' width={14} />
                <Text className='fs-13'>+966 564 568 765</Text>
            </Flex>
            <Flex align='center' gap={10}>
                <img src='/assets/icons/type.png' width={14} />
                <Text className='fs-13'>Hair Dye</Text>
            </Flex>
            <Flex align='center' gap={10}>
                <img src='/assets/icons/product.png' width={14} />
                <Text className='fs-13'>Vit C Serum (1) , Facial Pack (2)</Text>
            </Flex>
            <Flex gap={20}>
                <Flex align='center' gap={10}>
                    <img src='/assets/icons/calendar-h.png' width={14} />
                    <Text className='fs-13'>September 25, 2024</Text>
                </Flex>
                <Flex vertical gap={3}>
                    <Flex align='center' gap={5}>
                        <img src='/assets/icons/clock-h.png' width={14} />
                        <Text className='fs-13'>10:00 am - 10:30am</Text>
                    </Flex>
                    <Flex align='center' gap={5}>
                        <img src='/assets/icons/clock-h.png' width={14} />
                        <Text className='fs-13'>10:00 am - 10:30am</Text>
                    </Flex>
                </Flex>
            </Flex>
            <Flex align='center' gap={10}>
                <img src='/assets/icons/user-h.png' width={14} />
                <Flex wrap gap={3}>
                    <img src="/assets/images/av-1.png" width={20} height={20} style={{borderRadius:5}} alt="" />
                    <img src="/assets/images/av-1.png" width={20} height={20} style={{borderRadius:5}} alt="" />
                </Flex>
            </Flex>
            <Flex align='center' gap={10}>
                <img src='/assets/icons/riyal.png' width={14} />
                <Text className='fs-13'>SAR 5000</Text>
            </Flex>
        </div>
      );
    return (
        <>
        <Popover content={content} title={null}>
            <Card  className={
                event.status === 'progress' ? 'block-event status-progress':
                event.status === 'completed' ? 'block-event status-completed':
                event.status === 'cancel' ? 'block-event status-cancel':
                event.status === 'new' ? 'block-event status-new':
                'block-event'
            }
            onClick={()=>setVisibleModal(true)}
            actions={[
                <Flex wrap gap={3}>
                    <img src="/assets/images/av-1.png" width={24} height={24} style={{borderRadius:5}} alt="" />
                    <img src="/assets/images/av-1.png" width={24} height={24} style={{borderRadius:5}} alt="" />
                </Flex>
            ]}
            >
                <Flex vertical gap={10}>
                    <Flex wrap gap={3}>
                        {
                            event?.time?.map(data=>(
                                <p className={
                                    event?.status === 'completed' ? 'pill-sm bg-green m-0 fs-12 text-white':
                                    event?.status === 'progress' ? 'pill-sm bg-inporgress m-0 fs-12 text-white':
                                    event?.status === 'new' ? 'pill-sm bg-pink-dark m-0 fs-12 text-white':
                                    event?.status === 'cancel' ? 'pill-sm bg-cancel m-0 fs-12 text-white':
                                    'pill-sm m-0 fs-12 text-white'
                                }>
                                    
                                    {data?.innertime}
                                </p>
                            ))
                        }
                    </Flex>
                    <Text className='fs-12'>
                        {
                            event.title
                        }
                    </Text>
                    
                    {/* <ActionButton 
                        title='View details'
                        onClick={() => {setVisibleModal(true)}}
                        icon={<EyeOutlined className='text-white' />}
                        type='ghost'
                    /> */}
                </Flex>
            </Card>
        </Popover>
        <ViewReservation 
            visible={visiblemodal}
            setReceipt={setReceipt}
            setService = {setService} 
            setEditItem = {setEditItem}
            setProductState = {setProductState}
            setEditProduct = {setEditProduct}
            onClose={()=>{setVisibleModal(false)}}
        />
        <ReceiptReservation 
            visible={receipt}
            onClose={()=>setReceipt(false)}
        />
    </>
    );
};

export { CalendarEvents };
