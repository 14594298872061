import React, { useState, useEffect } from 'react';
import { useNavigate, Route, Routes, useLocation } from 'react-router-dom';
import './index.css';
import { Layout, Menu, Image, Space, Typography, Flex } from 'antd';
import { Dashboard, Login, Reviews, ProfileSettingPage, PasswordSettingPage, ReservationPage, RoleAndPermissionPage, EmployeesPage, SupportHelpdesk, ServicesManagement, CategoryManagement, OperatinhHours, ExpensePage, Transaction, PayrollPage, RevenuePage, Product, ProductCategoryPage, SupplierPage, CustomerPage, Coupon, ProfilLoss, TaxManagement } from '../pages';
import { Notifications, UserProfile } from '../components/Header';

const { Header, Sider, Content } = Layout;
const { Text } = Typography;

const RouteF = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState([]);
  const [currentTab, setCurrentTab] = useState('1');
  const [openKeys, setOpenKeys] = useState([]);
  function getItem(label, key, icon, onClick, children, type, isActive) {
    return { key, icon, onClick, children, label, type, isActive }
  }


  useEffect(()=>{
    let tab= location?.pathname?.split("/")[1]
    tab= tab==='' ? '1':
        tab==='rolepermission' ? '2':
        tab==='employees' ? '3':
        tab==='payroll' ? '4':
        tab==='categorymanage' ? '5':
        tab==='servicemanage' ? '6':
        tab==='product' ? '7':
        tab==='productcategory' ? '8':
        tab==='supplier' ? '9':
        tab==='reservation' ? '10':
        tab==='expense' ? '11':
        tab==='transaction' ? '12':
        tab==='revenue' ? '13':
        tab==='profitloss' ? '14' :
        tab==='taxmanagement' ? '15' :
        tab==='customer' ? '16':
        tab==='reviews'? '17':
        tab==='coupon' ? '18':
        tab==='support' ? '19':
        tab==='operatinghour' ? '20':
        tab==='profilesetting' ? '21':
        tab==='passsetting' ? '22':
        '1'
    setCurrentTab(tab)
  }, [location])


  useEffect(() => {
    let itemsArr = []
      itemsArr.push(getItem('Dashboard', '1', 
      currentTab === '1'?
          <img src="/assets/icons/sidebar/dash-w.png" width={'18px'} preview={false} alt=""/>:
          <img src="/assets/icons/sidebar/dash-b.png" width={'18px'} preview={false} alt="" />, 
          () => { navigate("/", { replace: true }) },
          null,
          null,
          currentTab === '1'
      ))
      itemsArr.push(getItem('Role & Permissions', '2', 
        currentTab === '2'?
            <img src="/assets/icons/sidebar/roleper-w.png" width={'20px'} preview={false} alt=""/>:
            <img src="/assets/icons/sidebar/roleper-b.png" width={'20px'} preview={false} alt="" /> , 
            () => { navigate("/rolepermission", { replace: true }) },
            null,
            null,
            currentTab === '2'
      ))

      itemsArr.push(
        {
          key: 'empmanage',
            icon: openKeys.includes('empmanage') ?
            <img src="/assets/icons/sidebar/emp-manage-blue.png" width={'20px'} preview={false} alt="" /> :
            <img src="/assets/icons/sidebar/emp-manage.png" width={'20px'} preview={false} alt="" />,
            label: 'Employees Management',
          children: [
            getItem(
              'Employees', '3',
              currentTab === '3' ?
              <img src="/assets/icons/sidebar/emp-w.png" width={'20px'} preview={false} alt=""/>:
              <img src="/assets/icons/sidebar/emp-b.png" width={'20px'} preview={false} alt="" />,
              () => { navigate("/employees", { replace: true }); },
              null,
              null,
              currentTab === '3'
            ),
            getItem(
              'Payroll', '4',
              currentTab === '4' ?
                <img src="/assets/icons/sidebar/payroll-w.png" width={'20px'} preview={false} alt="" /> :
                <img src="/assets/icons/sidebar/payroll-b.png" width={'20px'} preview={false} alt="" />,
              () => { navigate("/payroll", { replace: true }); },
              null,
              null,
              currentTab === '4'
            ),
          ]
        }
      );

      itemsArr.push(
        {
          key: 'proservices',
            icon: openKeys.includes('proservices') ? 
            <img src="/assets/icons/sidebar/proservice-blue.png" width={'20px'} preview={false} alt="" /> :
            <img src="/assets/icons/sidebar/proservice-b.png" width={'20px'} preview={false} alt="" />,
          label: 'Categories & Services',
          children: [
            getItem(
              'Categories Management', '5',
              currentTab === '5' ?
              <img src="/assets/icons/sidebar/category-w.png" width={'20px'} preview={false} alt=""/>:
              <img src="/assets/icons/sidebar/category-b.png" width={'20px'} preview={false} alt="" />,
              () => { navigate("/categorymanage", { replace: true }); },
              null,
              null,
              currentTab === '5'
            ),
            getItem(
              'Services Management', '6',
              currentTab === '6' ?
                <img src="/assets/icons/sidebar/serveicem-w.png" width={'20px'} preview={false} alt="" /> :
                <img src="/assets/icons/sidebar/serveicem-b.png" width={'20px'} preview={false} alt="" />,
              () => { navigate("/servicemanage", { replace: true }); },
              null,
              null,
              currentTab === '6'
            ),
          ]
        }
      );

      itemsArr.push(
        {
          key: 'inventorymanag',
            icon: openKeys.includes('inventorymanag') ?
            <img src="/assets/icons/sidebar/inventory-blue.png" width={'20px'} preview={false} alt="" />:
            <img src="/assets/icons/sidebar/inventory-b.png" width={'20px'} preview={false} alt="" />,
          label: 'Inventory Management',
          children: [
            getItem(
              'Product', '7',
              currentTab === '7' ?
              <img src="/assets/icons/sidebar/product-w.png" width={'20px'} preview={false} alt=""/>:
              <img src="/assets/icons/sidebar/product-b.png" width={'20px'} preview={false} alt="" />,
              () => { navigate("/product", { replace: true }); },
              null,
              null,
              currentTab === '7'
            ),
            getItem(
              'Product Category', '8',
              currentTab === '8' ?
                <img src="/assets/icons/sidebar/category-pro-w.png" width={'20px'} preview={false} alt="" /> :
                <img src="/assets/icons/sidebar/category-pro-b.png" width={'20px'} preview={false} alt="" />,
              () => { navigate("/productcategory", { replace: true }); },
              null,
              null,
              currentTab === '8'
            ),
            getItem(
              'Suppliers', '9',
              currentTab === '9' ?
                <img src="/assets/icons/sidebar/supplier-w.png" width={'20px'} preview={false} alt="" /> :
                <img src="/assets/icons/sidebar/supplier-b.png" width={'20px'} preview={false} alt="" />,
              () => { navigate("/supplier", { replace: true }); },
              null,
              null,
              currentTab === '9'
            ),
          ]
        }
      );


      itemsArr.push(
        {
          key: 'salesreservation',
            icon: openKeys.includes('salesreservation') ? 
            <img src="/assets/icons/sidebar/sales-blue.png" width={'20px'} preview={false} alt="" /> : 
            <img src="/assets/icons/sidebar/sales-b.png" width={'20px'} preview={false} alt="" />,
          label: 'Reservation Management',
          children: [
            getItem(
              'Reservations', '10',
              currentTab === '10' ?
                <img src="/assets/icons/sidebar/reserve-w.png" width={'20px'} preview={false} alt=""/>:
                <img src="/assets/icons/sidebar/reserve-b.png" width={'20px'} preview={false} alt="" /> , 
              () => { navigate("/reservation", { replace: true }) },
              null,
              null,
              currentTab === '10'
            ),
          ]
        }
      );

      itemsArr.push(
        {
          key: 'financialmanage',
            icon: openKeys.includes('financialmanage') ? 
            <img src="/assets/icons/sidebar/financial-blue.png" width={'20px'} preview={false} alt="" /> :
            <img src="/assets/icons/sidebar/financial-b.png" width={'20px'} preview={false} alt="" />,
          label: 'Financial Management',
          children: [
            getItem(
              'Expense Tracking', '11',
              currentTab === '11' ?
              <img src="/assets/icons/sidebar/expense-w.png" width={'20px'} preview={false} alt=""/>:
              <img src="/assets/icons/sidebar/expense-b.png" width={'20px'} preview={false} alt="" />,
              () => { navigate("/expense", { replace: true }); },
              null,
              null,
              currentTab === '11'
            ),
            getItem(
              'Transaction History', '12',
              currentTab === '12' ?
              <img src="/assets/icons/sidebar/transaction-w.png" width={'20px'} preview={false} alt=""/>:
              <img src="/assets/icons/sidebar/transaction-b.png" width={'20px'} preview={false} alt="" />,
              () => { navigate("/transaction", { replace: true }); },
              null,
              null,
              currentTab === '12'
            ),
            getItem(
              'Revenue Tracking', '13',
              currentTab === '13' ?
                <img src="/assets/icons/sidebar/revenue-w.png" width={'20px'} preview={false} alt=""/>:
                <img src="/assets/icons/sidebar/revenue-b.png" width={'20px'} preview={false} alt="" /> , 
              () => { navigate("/revenue", { replace: true }) },
              null,
              null,
              currentTab === '13'
            ),
            getItem(
              'Profit & Loss Report', '14',
              currentTab === '14' ?
                <img src="/assets/icons/sidebar/profit-w.png" width={'20px'} preview={false} alt=""/>:
                <img src="/assets/icons/sidebar/profit-b.png" width={'20px'} preview={false} alt="" /> , 
              () => { navigate("/profitloss", { replace: true }) },
              null,
              null,
              currentTab === '14'
            ),
            getItem(
              'Tax Management', '15',
              currentTab === '15' ?
                <img src="/assets/icons/sidebar/tax-w.png" width={'20px'} preview={false} alt=""/>:
                <img src="/assets/icons/sidebar/tax-b.png" width={'20px'} preview={false} alt="" /> , 
              () => { navigate("/taxmanagement", { replace: true }) },
              null,
              null,
              currentTab === '15'
            ),
          ]
        }
      );

      itemsArr.push(
        {
          key: 'customermanage',
            icon: openKeys.includes('customermanage') ? 
            <img src="/assets/icons/sidebar/customerm-blue.png" width={'20px'} preview={false} alt="" /> :
            <img src="/assets/icons/sidebar/customerm-b.png" width={'20px'} preview={false} alt="" />,
          label: 'Customer Management',
          children: [
            getItem(
              'Customers', '16',
              currentTab === '16' ?
              <img src="/assets/icons/sidebar/customerh-w.png" width={'20px'} preview={false} alt=""/>:
              <img src="/assets/icons/sidebar/customerh-b.png" width={'20px'} preview={false} alt="" />,
              () => { navigate("/customer", { replace: true }); },
              null,
              null,
              currentTab === '16'
            ),
            getItem(
              'Reviews & Ratings', '17',
              currentTab === '17' ?
                <img src="/assets/icons/sidebar/review-w.png" width={'20px'} preview={false} alt=""/>:
                <img src="/assets/icons/sidebar/review-b.png" width={'20px'} preview={false} alt="" /> , 
              () => { navigate("/reviews", { replace: true }) },
              null,
              null,
              currentTab === '17'
            ),
            getItem(
              'Coupons', '18',
              currentTab === '18' ?
                <img src="/assets/icons/sidebar/coupon-w.png" width={'20px'} preview={false} alt=""/>:
                <img src="/assets/icons/sidebar/coupon-b.png" width={'20px'} preview={false} alt="" /> , 
              () => { navigate("/coupon", { replace: true }) },
              null,
              null,
              currentTab === '18'
            ),
          ]
        }
      );

      itemsArr.push(
        {
          key: 'settings',
            icon:  openKeys.includes('settings') ?
            <img src="/assets/icons/sidebar/g-set-blue.png" width={'20px'} preview={false} alt="" />:
            <img src="/assets/icons/sidebar/g-set-b.png" width={'20px'} preview={false} alt="" />,
          label: 'Settings',
          children: [
            getItem(
              'Support & Helpdesk', '19',
              currentTab === '19' ?
              <img src="/assets/icons/sidebar/support-w.png" width={'20px'} preview={false} alt=""/>:
              <img src="/assets/icons/sidebar/support-b.png" width={'20px'} preview={false} alt="" />,
              () => { navigate("/support", { replace: true }); },
              null,
              null,
              currentTab === '19'
            ),
            getItem(
              'Operating Hours', '20',
              currentTab === '20' ?
                <img src="/assets/icons/sidebar/operateh-w.png" width={'20px'} preview={false} alt=""/>:
                <img src="/assets/icons/sidebar/operateh-b.png" width={'20px'} preview={false} alt="" /> , 
              () => { navigate("/operatinghour", { replace: true }) },
              null,
              null,
              currentTab === '20'
            ),
            getItem(
              'Profile Settings', '21',
              currentTab === '21' ?
                <img src="/assets/icons/sidebar/p-set-w.png" width={'20px'} preview={false} alt="" />:
                <img src="/assets/icons/sidebar/p-set-b.png" width={'20px'} preview={false} alt="" /> , 
              () => { navigate("/profilesetting", { replace: true }) },
              null,
              null,
              currentTab === '21'
            ),
            getItem(
              'Password Settings', '22',
              currentTab === '22' ?
                <img src="/assets/icons/sidebar/pass-w.png" width={'20px'} preview={false} alt=""/>:
                <img src="/assets/icons/sidebar/pass-b.png" width={'20px'} preview={false} alt="" /> , 
              () => { navigate("/passsetting", { replace: true }) },
              null,
              null,
              currentTab === '22'
            ),
          ]
        }
      )

      setItems([...itemsArr])
    }, [currentTab, navigate, openKeys])

 
  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={collapsed} className={collapsed ? 'addclass' :''} style={{ height: '100vh', overflowY: 'auto' }}>
        <div className="logo" style={{ display: 'flex', justifyContent: 'center' }}>
          <Image
            style={{ width: collapsed ? "100%" : '200px' }}
            height={'auto'}
            src="./assets/images/logo-s.png"
            alt='Salon Owner Panel'
            preview={false}
          />
        </div>
        <Menu
          defaultSelectedKeys={['1']}
          selectedKeys={[currentTab]}
          mode="inline"
          theme="dark"
          onOpenChange={onOpenChange}
          openKeys={openKeys}
          items={items}
          className='listitem'
        />
      </Sider>
      <Layout className="site-layout bg-light-gray">
        <Header
          className="site-layout-background header-mbl-cs shadow-c"
          style={{
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            // marginLeft:25,
            marginTop:10,
          }}
        >
          <div style={{ width: '98%', display: 'flex', justifyContent: 'space-between' }}>
            <Space align='center' size={5}>
              <div className='trigger border-0-btn' onClick={() => setCollapsed(!collapsed)}>
                <Image src='/assets/icons/collapse-ic.png' width={'25px'} preview={false}
                  style={{transform: collapsed ? 'rotate(180deg)': 'rotate(0deg)'}}
                />
              </div>
              <Flex vertical gap={0}>
                <Text strong>HH Salon!</Text>
                <Text className='text-gray fs-13'>Where style meets creativity</Text>
              </Flex>
            </Space>
            <Space size={15}>
              <Image src='/assets/icons/language.png' width={24} preview={false} />
              <Notifications />
              <UserProfile />
            </Space>
          </div>
        </Header>
        <Content
          className="scroll-bar width-fix"
          style={{
            margin: '15px auto',
            padding: '0px 15px 25px 10px',
            minHeight: 280,
            overflowY: 'auto',
            overflowX:'hidden'
          }}
        >
          <Routes>
            <Route path="/" element={<Dashboard />} />            
            <Route path='/rolepermission' element={<RoleAndPermissionPage />} />
            <Route path='/employees' element={<EmployeesPage />} />
            <Route path='/categorymanage' element={<CategoryManagement />} />
            <Route path='/servicemanage' element={<ServicesManagement />} />
            <Route path='/product' element={<Product />} />
            <Route path='/productcategory' element={<ProductCategoryPage />} />
            <Route path='/supplier' element={<SupplierPage />} />
            <Route path='/operatinghour' element={<OperatinhHours />} />
            <Route path='/expense' element={<ExpensePage />} />
            <Route path='/transaction' element={<Transaction />} />
            <Route path='/reservation' element={<ReservationPage />} /> 
            <Route path='/payroll' element={<PayrollPage />} />  
            <Route path='/revenue' element={<RevenuePage />} />
            <Route path='/profitloss' element={<ProfilLoss />} />
            <Route path='/taxmanagement' element={<TaxManagement />} />
            <Route path='/customer' element={<CustomerPage />} />           
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/coupon" element={<Coupon />} />
            <Route path='/support' element={<SupportHelpdesk />} />
            <Route path="/profilesetting" element={<ProfileSettingPage />} />
            <Route path='/passsetting' element={<PasswordSettingPage />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default RouteF;
